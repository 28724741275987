import { Link, useParams } from "react-router-dom";
import CandidateCard from "../../../../components/candidateCard";
import AuthFooter from "../../components/footer";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { URLS } from "../../../../services/urls";
import useSWR from "swr";
import { DEFAULT_PAGE_COUNT } from "../../../../components/utils/consts";
import ApplicationTable from "./components/ApplicationsTable";
import { useForm } from "react-hook-form";
import { API } from "../../../../services/axios";
import Select from "react-select";
import { styles } from "../../../../components/utils/reactSelect";
import CvTable from "./components/CvTables";
import { downloadExcel } from "react-export-table-to-excel";
import { toast } from "react-toastify";

const Candidates = (props) => {
  const [search, setSearch] = useState("");
  const [jobLoading, setJobLoading] = useState(false);
  const [job, setJob] = useState();
  const [page, setPage] = useState(1);
  const [maxAge, setMaxAge] = useState("");
  const [minAge, setMinAge] = useState("");
  const [subscriptionDate, setSubscriptionDate] = useState("");
  const [applicationDate, setApplicationDate] = useState("");
  const [status, setstatus] = useState("");
  const [profession, setProfession] = useState("");
  const [level, setLevel] = useState("");
  const [searchLevel, setSearchLevel] = useState("");
  const [experienceCategory, setExperienceCategory] = useState("");
  const [industry, setIndustry] = useState("");
  const [toSearch, setToSearch] = useState("");
  const [displayAll, setDisplayAll] = useState(false);

  const { data: diploms, isLoading: loadingDiploms } = useSWR(
    URLS.CONFIGS.PROFILE.DIPLOMA.list(searchLevel, 1)
  );

  const { register, handleSubmit, getValues, reset } = useForm({});

  const onSubmit = (data) => {
    // console.log(data);
  };

  const applicationsRef = useRef();

  // Extra query parameters
  const [extra, setExtra] = useState("");

  const { jobId } = useParams();
  // If jobId in not null the view is a list of candidates for specific job else its a standard cv-theque view

  const { data, isLoading } = useSWR(
    jobId
      ? URLS.JOBS.APPLICATIONS.applications(toSearch, page, jobId, extra)
      : URLS.USERS.PROFILE.list(toSearch, page, extra)
  );
  const { data:archived, isLoading:loadingArchived } = useSWR(
    jobId
      ? URLS.JOBS.APPLICATIONS.archived_applications(jobId)
      : URLS.USERS.PROFILE.list(toSearch, page, extra)
  );
  const { data: industries, isLoading: loadingIndustries } = useSWR(
    URLS.CONFIGS.PROFILE.SECTOR.list("")
  );
  const { data: professionList, isLoading: loadingProfessionList } = useSWR(
    URLS.CONFIGS.PROFILE.PROFESSION.list("")
  );

  const { t } = useTranslation();

  const tableHeader = [
    "Email",
    "Noms",
    "Contact",
    "Genre",
    "Age",
    "Ville de residence",
    "Langue principale",
    "Groupe competence",
    "Lien CV",
    "Annee d'obtention dernier diplome",
    "Dernier diplome",
    "Experience professionnelle",
  ];

  const handleSearch = () => {
    setToSearch(search);
    let xtraParam = "&status="+job?.status;

    if (jobId) {
      // It's job applications list
      if (minAge) {
        xtraParam = xtraParam + "&user__user__age__gte=" + minAge;
      }
      if (maxAge) {
        xtraParam = xtraParam + "&user__user__age__lte=" + maxAge;
      }
      if (subscriptionDate) {
        let date= new Date(applicationDate)
        xtraParam = xtraParam + "&user__user__date_joined__year=" + date.getFullYear();
        xtraParam = xtraParam + "&user__user__date_joined__month=" + date.getMonth();
        xtraParam = xtraParam + "&user__user__date_joined__day=" + date.getDate();
      }
      if (applicationDate) {
        let date= new Date(applicationDate)
        // console.log(date.getDate())
        xtraParam = xtraParam + "&created_at__year=" + date.getFullYear();
        xtraParam = xtraParam + "&created_at__month=" + date.getMonth();
        xtraParam = xtraParam + "&created_at__day=" + date.getDate();
      }
      if (industry) {
        xtraParam = xtraParam + "&user__experience__industry__id=" + industry;
      }
      if (level) {
        xtraParam = xtraParam + "&user__education__degree__id=" + level;
      }
      if (status) {
        xtraParam =
          xtraParam +
          "&user__experience__current_job=" +
          (status === "1" ? "true" : "false");
      }
      if (experienceCategory) {
        xtraParam =
          xtraParam + "&user__experience__level=" + experienceCategory;
      }
      if (profession) {
        xtraParam =
          xtraParam + "&user__profession__id=" + profession;
      }
      // console.log(`\n\nSeqrching values ${xtraParam}\n\n`);
    } else {
      // It's cv theque
      if (minAge) {
        xtraParam = xtraParam + "&user__age__gte=" + minAge;
      }
      if (maxAge) {
        xtraParam = xtraParam + "&user__age__lte=" + maxAge;
      }
      if (subscriptionDate) {
        xtraParam = xtraParam + "&user__date_joined=" + subscriptionDate;
      }
      if (industry) {
        xtraParam = xtraParam + "&experience__industry__id=" + industry;
      }
      if (level && level !== "") {
        xtraParam = xtraParam + "&education__degree__id=" + level;
      }
      if (status) {
        xtraParam =
          xtraParam +
          "&experience__current_job=" +
          (status === "1" ? "true" : "false");
      }
      if (profession) {
        xtraParam =
          xtraParam + "&profession__id=" + profession;
      }
    }
    if (experienceCategory) {
      xtraParam =
        xtraParam + "&user__experience__level=" + experienceCategory;
    }

    // Get custom form data
    const customFormValues = getValues();
    console.log(xtraParam)
    // console.log("customFormValues", customFormValues);

    for (let key in customFormValues) {
      if (customFormValues[key]) {
        console.log(key, customFormValues[key]);
        xtraParam =
          xtraParam +
          "&offer_question_answer__question__contains=" +
          job?.questions?.filter((i) => i.id === key)[0]?.question;
        xtraParam =
          xtraParam +
          "&offer_question_answer__response__contains=" +
          customFormValues[key];
      }
    }

    // console.log(xtraParam);
    if (xtraParam !== "&") {
      setExtra(xtraParam);
    }

    applicationsRef?.current?.scrollIntoView();
  };

  const handleChangePage = (event, pg) => {
    event.preventDefault();
    setPage(pg);
  };

  const handleResetFilters = () => {
    setExtra(`&status=${job?.status}`);
    setToSearch("");

    setPage(1);
    setMinAge("");
    setSearch("");
    setMaxAge("");
    setIndustry("");
    setApplicationDate("");
    setSubscriptionDate("");
    setExperienceCategory("");
    setLevel("");
    setstatus("");
    setProfession("")
    reset();
  };

  // Downnload all the results of the current search
  const handleDownloadResult = (fileName, sheet) => {
    const id = toast.loading(t("textPreparingDonwload"));
    let toExport = [];

    API.get(
      jobId
        ? URLS.JOBS.APPLICATIONS.applications(
            toSearch,
            page,
            jobId,
            extra,
            10000
          )
        : URLS.USERS.PROFILE.list(toSearch, page, extra, 10000)
    )
      .then((resp) => {
        const { results } = resp.data;
        // console.log(resp.data)
        results.forEach(async (element) => {
          // console.log(element)
          const profile = await API.get(jobId
            ?URLS.USERS.PROFILE.details(element?.user?.id):URLS.USERS.PROFILE.details(element?.id))
            .then((resp) => resp.data)
            .catch((err) => undefined);
          if (profile) {
            toExport = [
              ...toExport,
              [
                profile?.user?.email,
                profile?.user?.first_name + " " + profile?.user?.last_name,
                profile?.user?.phone_number ?? "",
                profile?.user?.gender,
                profile?.user?.age,
                "",
                profile?.user?.language,
                profile?.primary_role,
                profile?.cv,
                profile?.education[0]?.end_date ?? "",
                profile?.education[0]?.degree?.name,
                profile?.experience[0]?.level,
              ],
            ];
            if (toExport.length === results.length) {
              // console.log(toExport);

              downloadExcel({
                fileName: results[0]?.offer?.title ?? t("textcvTheque"),
                sheet: results[0]?.offer?.title ?? t("textProfiles"),
                tablePayload: {
                  header: tableHeader,
                  // accept two different data structures
                  body: toExport,
                },
              });

              toast.update(id, {
                render: t("textDownloadSuccess"),
                type: "success",
                isLoading: false,
                autoClose: true,
              });
            }
          }
        });
      })
      .catch((error) => {
        toast.update(id, {
          render:
            error?.response?.data?.detail ??
            error?.response?.data?.message ??
            t("textDownloadFailed"),
          type: "error",
          isLoading: false,
          autoClose: true,
        });
      });
  };

  const handleDisplayAll = (value) => {
    setDisplayAll(value);
    if (value) {
      // console.log("Displaying all");
      setExtra(`&status=${job?.status}`);
      handleSearch();
    } else {
      setExtra(`&status=`);
    }
  };

  useEffect(() => {
    if (jobId) {
      setJobLoading(true);
      API.get(URLS.JOBS.details(jobId))
        .then((resp) => {
          setJob(resp.data);
          // setToSearch(resp.data.status)
          setExtra(`&status=${resp.data?.status}`);
          setJobLoading(false);
        })
        .catch((error) => {
          setJobLoading(false);
          console.log("Here is the error")
        });
    }
  }, []);

  return (
    <div className="box-content" ref={applicationsRef}>
      <div className="box-heading">
        <div className="box-title">
          <h3 className="mb-35">{jobId ? "Candidates" : "CV theque"}</h3>
        </div>
        <div className="box-breadcrumb">
          <div className="breadcrumbs">
            {jobId ? (
              <ul>
                <li>
                  {" "}
                  <Link className="icon-home" to="/">
                    {t("textDashboard")}
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link className="" to="/jobs">
                    {t("textJobs")}
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link className="" to={`/jobs/${jobId}`}>
                    <span>{t("textJobDetails")}</span>
                  </Link>
                </li>
                <li>
                  <span>{t("textJobApplications")}</span>
                </li>
              </ul>
            ) : (
              <ul>
                <li>
                  {" "}
                  <Link className="icon-home" to="/">
                    {t("textDashboard")}
                  </Link>
                </li>
                <li>
                  <span>{t("textCvTheque")}</span>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-9 col-md-9 col-12">
          <div className="section-box">
            <div className="container">
              <div className="panel-white mb-30">
                <div className="box-padding">
                  <div className="row">
                    {(isLoading || jobLoading) && (
                      <div className="col-12 mb-11 d-flex">
                        <span
                          className="spinner-border m-auto"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </div>
                    )}
                    {
                      // jobId ? data?.results?.map((profile) => (
                      //   <div key={profile?.id} className="col-xl-4 col-lg-4 col-md-6">
                      //     <CandidateCard profile={profile} />
                      //   </div>
                      // ))
                      jobId ? (
                        (!jobLoading) && (
                          <ApplicationTable
                            applications={data?.results ?? []}
                            archived = {false}
                            handleDownloadResult={handleDownloadResult}
                            handleDisplayAll={handleDisplayAll}
                          />
                        )
                      ) : (
                        <CvTable
                          profiles={data?.results ?? []}
                          handleDownloadResult={handleDownloadResult}
                        />
                      )
                    }

                    {!isLoading && data?.results?.length === 0 && (
                      <p className="text-center">{t("textNoItemFound")} </p>
                    )}
                  </div>
                  {!isLoading && data?.results?.length > 0 && (
                    <div className="paginations mb-35">
                      <ul className="pager">
                        <li>
                          <Link
                            tabIndex={-1}
                            onClick={(event) =>
                              handleChangePage(event, page - 1)
                            }
                            className={`pager-prev ${
                              page === 1 ? "disabled" : ""
                            }`}
                            to="#"
                          ></Link>
                        </li>
                        {data &&
                          new Array(Math.ceil(data.count / DEFAULT_PAGE_COUNT))
                            .fill(3).map((pag, index) => (
                              <li
                                key={"page" + index}
                                className={` ${
                                  page === index + 1 ? "active" : ""
                                }`}
                                aria-current="page"
                              >
                                <Link
                                  className={`pager-number ${
                                    page * DEFAULT_PAGE_COUNT ===
                                    Math.ceil(data.count)
                                      ? "disabled"
                                      : ""
                                  } ${page === index + 1 ? "active" : ""}`}
                                  to="#"
                                  tabIndex={-1}
                                  onClick={(event) =>
                                    handleChangePage(event, index + 1)
                                  }
                                >
                                  {index + 1}
                                </Link>
                              </li>
                            ))}
                        <li>
                          <Link
                            tabIndex={-1}
                            className={`pager-next ${
                              page === 1 ? "disabled" : ""
                            }`}
                            to="#"
                          ></Link>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
                  {(jobId && !loadingArchived && archived?.length>0) && 
                    <div className="panel-white">
                    <div className="box-padding">
                      <div className="row">
                      <h4 className="mb-10">{t("textArchived")}</h4>
                      <ApplicationTable
                      applications={archived?? []}
                      archived = {true}
                      handleDownloadResult={handleDownloadResult}
                      handleDisplayAll={handleDisplayAll}
                    />
                    </div>
                    </div>
                  </div>
                  }
            </div>
          </div>
        </div>
        {
          <div className="col-lg-3 col-md-3 col-12">
            <div className="section-box">
              <div className="panel-white p-16">
                <h4 className="mb-15">{t("textFilters")}</h4>
                <div className="">
                  <div className="form-floating">
                    <input
                      id="search"
                      className="form-control"
                      onChange={(e) => setSearch(e.target.value)}
                      type="text"
                      value={search}
                      placeholder={t("textSearch")}
                    />
                    <label htmlFor="search">{t("textSearch")}</label>
                  </div>
                </div>
                <div className="row pt-3 pb-3">
                  <div className="col-6">
                    <div className="form-floating">
                      <input
                        id="minAge"
                        className="form-control"
                        onChange={(e) => setMinAge(e.target.value)}
                        type="text"
                        value={minAge}
                        placeholder={t("textMinAge")}
                      />
                      <label htmlFor="minAge">{t("textMinAge")}</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-floating">
                      <input
                        id="maxAge"
                        className="form-control"
                        value={maxAge}
                        onChange={(e) => setMaxAge(e.target.value)}
                        type="text"
                        placeholder={t("textMaxAge")}
                      />
                      <label htmlFor="maxAge">{t("textMaxAge")}</label>
                    </div>
                  </div>
                </div>
                {jobId && (<div>
                  <div className="form-floating mb-3">
                    <input
                      type="date"
                      value={applicationDate}
                      onChange={(e) => setApplicationDate(e.target.value)}
                      id="filterDate"
                      className="form-control"
                    />
                    <label htmlFor="floatingSelectGrid">
                      {t("textAppDate")}
                    </label>
                  </div>
                </div>)}
                <div className="form-floating mb-3">
                    <select
                      className="form-select" 
                      id="floatingSelectGrid"
                      aria-label="Floating label select example"
                      onChange={(e) => setProfession(e.target.value)}
                      value={profession}
                    >
                      <option value="" disabled>
                        {t("textProfessionChoose")}
                      </option>
                      {professionList?.results?.map((prof)=>(
                        <option value={prof.id}>{prof.name}</option>
                      ))}
                    </select>
                    <label htmlFor="floatingSelectGrid">
                      {t("profession")}
                    </label>
                  </div>
                {/* <div>
                  <div className="form-floating mb-3">
                    <input
                      type="date"
                      value={subscriptionDate}
                      onChange={(e) => setSubscriptionDate(e.target.value)}
                      id="filterDate"
                      className="form-control"
                    />
                    <label htmlFor="floatingSelectGrid">
                      {t("profession")}
                    </label>
                  </div>
                </div> */}
                <div>
                  <div className="form-floating mb-3">
                    <select
                      className="form-select"
                      id="floatingSelectGrid"
                      aria-label="Floating label select example"
                      onChange={(e) => setstatus(e.target.value)}
                      value={status}
                    >
                      <option value="" disabled>
                        {t("textStatusChoose")}
                      </option>
                      <option value="1">{t("textFilterWorking")}</option>
                      <option value="2">{t("textNotWorking")}</option>
                    </select>
                    <label htmlFor="floatingSelectGrid">
                      {t("textStatus")}
                    </label>
                  </div>
                  {/* <label htmlFor="textTrainingLevelType">
                    {t("textTrainingLevelType")}
                  </label> */}
                  <div className="form-floating mb-3">
                    <select
                    className="form-select"
                    id="edLevel"
                    aria-label={t("textTrainingLevelType")}
                    onChange={(e)=>setLevel(e.target.value)}
                    value={level}>
                    <option value="" disabled>
                      {t("textDegreeChoose")}
                    </option>
                      {diploms?.results?.map((i)=>(<option value={i.id}>{i.name}</option>))}
                    </select>
                    <label htmlFor="floatingSelectGrid">
                      {t("textTrainingLevelType")}
                    </label>
                  </div>
                  <div className="form-floating mb-3">
                    <select
                      className="form-select"
                      id="xpCategory"
                      aria-label={t("textxpCategory")}
                      onChange={(e) => setExperienceCategory(e.target.value)}
                      value={experienceCategory}
                    >
                      <option value="" disabled>
                        {t("textChoose")}
                      </option>
                      <option value="entry">{t("textEntry")}</option>
                      <option value="intermediated">
                        {t("textIntermediate")}
                      </option>
                      <option value="senior">{t("textSenior")}</option>
                      <option value="expert">{t("textExpert")}</option>
                    </select>
                    <label htmlFor="xpCategory">{t("textxpCategory")}</label>
                  </div>
                </div>
                {!jobId && (
                  <div className="form-floating mb-3">
                    <select
                      className="form-select"
                      id="jobIndustry"
                      aria-label={t("jobIndustry")}
                      onChange={(e) => setIndustry(e.target.value)}
                    >
                      <option value="" disabled>
                        {t("textChoose")}
                      </option>
                      {industries?.results?.map((i) => (
                        <option value={i.id}>{i.name}</option>
                      ))}
                    </select>
                    <label htmlFor="jobIndustry">{t("jobIndustry")}</label>
                  </div>
                )}
                {jobId && (
                  <>
                    <hr />
                    <h4 className="mb-15">{t("textCustomFilters")}</h4>
                    <form className=" mt-20" onSubmit={handleSubmit(onSubmit)}>
                      <hr />
                      <div className="row">
                        {job?.questions?.map((question, index) => {
                          return question.response_type === "yes/no" ? (
                            <div key={question.id} className="col-12 mb-3">
                              <div className="form-">
                                <label className="form-label text-left w-100">
                                  {question.question}{" "}
                                </label>
                                {/* [ yes/no, true/false, number, characters ] */}
                                <div className="row">
                                  <div className="col-6">
                                    <div className="form-check w-fit">
                                      <input
                                        className="form-check-input"
                                        id={`input-${index}`}
                                        type="radio"
                                        value={"yes"}
                                        {...register(question.id)}
                                      />
                                      <label
                                        className="form-check-label w-fit"
                                        htmlFor={`input-${index}`}
                                      >
                                        {t("textYes")}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="form-check w-fit">
                                      <input
                                        className="form-check-input"
                                        id={`input-${index + "option_2"}`}
                                        type="radio"
                                        value={"no"}
                                        {...register(question.id)}
                                      />
                                      <label
                                        className="form-check-label w-fit"
                                        htmlFor={`input-${index + "option_2"}`}
                                      >
                                        {t("textNo")}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : question.response_type === "true/false" ? (
                            <div key={question.id} className="col-12 mb-3">
                              <div className="form-">
                                <label className="form-label text-left w-100">
                                  {question.question}
                                </label>
                                {/* [ yes/no, true/false, number, characters ] */}
                                <div className="row">
                                  <div className="col-6">
                                    <div className="form-check w-fit">
                                      <input
                                        className="form-check-input"
                                        id={`input-${index}`}
                                        type="radio"
                                        value={"true"}
                                        {...register(question.id)}
                                      />
                                      <label
                                        className="form-check-label w-fit"
                                        htmlFor={`input-${index}`}
                                      >
                                        {t("textTrue")}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="form-check w-fit">
                                      <input
                                        className="form-check-input"
                                        id={`input-${index + "option_2"}`}
                                        type="radio"
                                        value={"false"}
                                        {...register(question.id)}
                                      />
                                      <label
                                        className="form-check-label w-fit"
                                        htmlFor={`input-${index + "option_2"}`}
                                      >
                                        {t("textFalse")}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : question.response_type === "number" ? (
                            <div key={question.id} className="col-12 mb-3">
                              <div className="form-group">
                                <label className="form-label text-left w-100">
                                  {question.question}
                                </label>
                                <input
                                  className="form-control"
                                  id={`input-${index}`}
                                  type="number"
                                  {...register(question.id)}
                                  placeholder={question.question}
                                />
                              </div>
                            </div>
                          ) : question.response_type === "characters" ? (
                            <div key={question.id} className=" mb-3 col-12">
                              <div className="form-group">
                                <label className="form-label text-left w-100">
                                  {question.question}
                                </label>
                                <input
                                  className="form-control"
                                  id={`input-${index}`}
                                  type="text"
                                  {...register(question.id)}
                                  placeholder={question.question}
                                />
                              </div>
                            </div>
                          ) : (
                            <div />
                          );
                        })}
                      </div>
                      <button
                        type="submit"
                        className="hidden"
                        id="submitExtradata"
                      ></button>
                    </form>
                  </>
                )}
                <div className="d-flex mb-3">
                  <button
                    onClick={handleSearch}
                    className="btn btn-default w-100"
                  >
                    {t("textSearch")}
                  </button>
                </div>
                <div className="d-flex">
                  <button
                    onClick={handleResetFilters}
                    className="btn btn-secondary w-100"
                  >
                    {t("textResetForm")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      {/* <div className="mt-10">
        <div className="section-box">
          <div className="container">
            <div className="panel-white pt-30 pb-30 pl-15 pr-15">
              <Clients removePadding />
            </div>
          </div>
        </div>
      </div> */}
      <AuthFooter />
    </div>
  );
};

export default Candidates;
