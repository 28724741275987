import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls";
import { toast } from "react-toastify";
import { setUserDetails } from "../../components/utils/functions";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../store/slices/auth";



const RecoverPassword = () => {
  const [loading, setLoading] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const onSubmit = (data) => {
    setLoading(true);
    API.post(URLS.AUTHS.PASSWORD.reset, data)
      .then((resp) => {
        setLoading(false);
        toast.success(resp?.data?.detail ?? t("textRecoverPwEmailSend"));
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.detail ?? error?.response?.data?.message ?? t("textEmailNotFound"));
      });
  };
  return (
    <div className="box-content">
      <div className="box-heading"></div>
      <div className="row">
        <div className="col-lg-12">
          <div className="section-box">
            <div className="container">
              <div className="panel-white mb-30 mt-100">
                <div className="box-padding">
                  <div className="login-register">
                    <div className="row login-register-cover pb-3">
                      <div className="col-lg-4 col-md-6 col-sm-12 mx-auto">
                        <div className="form-login-cover">
                          <div className="text-center">
                            <p className="font-sm text-brand-2">
                              {t("forgotPassword")}{" "}
                            </p>
                          </div>
                          <form
                            className="login-register text-start mt-20"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <div className="form-group">
                              <label className="form-label" htmlFor="input-1">
                                {t("email")} *
                              </label>
                              <input
                                className="form-control"
                                id="input-1"
                                type="email"
                                required=""
                                {...register("email", {
                                  required: true,
                                  validate: {
                                    maxLength: (v) =>
                                      v.length <= 50 ||
                                      t("textAtMost"),
                                    matchPattern: (v) =>
                                      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                                        v
                                      ) ||
                                      t("textValidEmail"),
                                  },
                                })}
                                placeholder="stevenjob@gmail.com"
                              />
                              <span className="text-primary">
                                {errors.email?.message && (
                                  <small>{errors.email.message}</small>
                                )}
                              </span>
                            </div>
                            
                            <div className="form-group">
                              <button
                                className="btn btn-brand-1 hover-up w-100"
                                type="submit"
                                name="login"
                                disabled={loading}
                              >
                                {t("textResetPw")}
                                {loading && (
                                  <div
                                    className="spinner-border ml-10 spinner-border-sm"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                )}
                              </button>
                            </div>
                          </form>
                        </div>
                        <div className="img-2">
                          <img
                            src="/assets/imgs/page/login-register/img-3.svg"
                            alt="Cible rh emploie"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mt-10"> 
          <div className="section-box">
            <div className="container"> 
              <div className="panel-white pt-30 pb-30 pl-15 pr-15">
                <Clients />
              </div>
            </div>
          </div>
        </div> */}
    </div>
  );
};

export default RecoverPassword;
