import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { API } from "../../../../../../services/axios";
import { URLS } from "../../../../../../services/urls";

const ManageProfessionForm = ({toEdit, onSuccess}) => {
    const { register, reset, handleSubmit } = useForm();
    const { t } = useTranslation();
    const { auth } = useSelector((state) => state.auth)

    const [loading, setLoading] = useState(false);


    const onSubmit = (data) => {
        let formData = new FormData();
        formData.append("name", data.name);
        formData.append("description", data.description);
        setLoading(true);
        if(toEdit){
            API.put(URLS.CONFIGS.PROFILE.PROFESSION.update(toEdit.id), formData).then((resp) => {
                toast.success(t("textConfigEditionSuccess"));
                setLoading(false);
                onSuccess();
                reset();
                // window.$("#manageIndustryForm").modal('hide');
            }).catch((error) => {
                toast.error(error?.response?.data?.detail ?? error?.response?.data?.message ?? t("textConfigEditionError"));
                setLoading(false);
            })
        } else {
          const splittedProfessions = String(data.name).split(',');
          splittedProfessions.forEach((skill) => {
            const skillData = {
              "name": skill,
              "description": data.description
            }

            API.post(URLS.CONFIGS.PROFILE.PROFESSION.create, skillData).then((resp) => {
                toast.success(t("textConfigCreatedSuccess"));
                setLoading(false);
                onSuccess()
                reset();
            }).catch((error) => {
                toast.error(error?.response?.data?.detail ?? error?.response?.data?.message ?? t("textConfigCreatedError"));
                setLoading(false);
            })
          })
        }
    }
    return (
        <div className="modal fade" id="manageProfessionForm" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-lg">
          <div className="modal-content apply-job-form">
            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="modal-body pl-30 pr-30 pt-50">
              <div className="text-center">
                <p className="font-sm text-brand-2">{toEdit ? t("textEdit") : t("textAddNew")}</p>
              </div>
              <form className="login-register text-start mt-20 pb-30" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label className="form-label" htmlFor="input-1">{t("textName")} *</label>
                  <input className="form-control" id="input-1" defaultValue={toEdit?.name}type="text" {...register("name", {required: true})} placeholder={toEdit ? t("textName") : t("textSeperateProfessions")}/>
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="des">{t("textDescription")}</label>
                  <textarea className="form-control" rows={5} defaultValue={toEdit?.description} {...register("description")} placeholder={t("textDescription")}/>
                </div>
                <div className="row">
                </div>
                <div className="form-group">
                  <button className="btn btn-default hover-up w-100" type="submit" name="login">
                      {t("textAddNew")}
                      {loading && <div
                                  className="spinner-border ml-10 spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>}
                      </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        </div>
    )
}

export default ManageProfessionForm; 