import { Link } from "react-router-dom";
import AuthFooter from "../../components/footer";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../../../services/axios";
import { URLS } from "../../../../services/urls";
import {
  getUserDetails,
  setUserDetails,
} from "../../../../components/utils/functions";
import { toast } from "react-toastify";
import { updateUserData } from "../../../../store/slices/auth";
import i18n from "../../../../translations/translations";
import { DEFAULT_PROFILE } from "../../../../components/utils/consts";

const MyProfile = (props) => {
  const [picture, setPicture] = useState();
  const [loading, setLoading] = useState();
  const [loadingUser, setLoadingUser] = useState(true);
  const [preview, setPreview] = useState("");
  const { auth } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm();
  const {
    register: registerSocial,
    handleSubmit: handleSubmitSocial,
    reset: resetSocial,
  } = useForm();

  const handleChangeProfile = (event) => {
    setPicture(event?.target?.files[0]);
    setPreview(URL.createObjectURL(event?.target?.files[0]));
  };

  const onSubmitSocials = (data) => {
    const info = {
      linkedin: data.linkedin,
      github: data.github,
      website: data.website,
      twitter: data.twitter,
    };
    API.patch(URLS.USERS.update(auth?.user?.id), info)
      .then((resp) => {
        const user = getUserDetails();
        const { data } = resp;
        setUserDetails({
          ...user,
          linkedin: data.linkedin,
          github: data.github,
          // email: data.email,
          website: data.website,
          twitter: data.twitter ?? "en",
        });
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.detail);
        setLoading(false);
      });
  };

  const onSubmit = (data) => {
    setLoading(true);
    let data1 = data;

    let formData = new FormData();
    formData.append("first_name", data?.firstName);
    formData.append("last_name", data?.lastName);
    formData.append("phone_number", data?.phoneNumber);
    formData.append("language", data?.language);
    formData.append("age", data?.age);
    formData.append("gender", data?.gender);

    if (picture) {
      formData.append("picture", picture);
    }

    console.log(auth?.user);

    API.patch(URLS.USERS.update(auth?.user?.id), formData)
      .then((resp) => {
        const user = getUserDetails();
        const { data } = resp;
        setUserDetails({
          ...user,
          first_name: data.first_name,
          last_name: data.last_name,
          // email: data.email,
          phone_number: data.phone_number,
          language: data.language ?? "en",
          age: data.age ?? "",
          gender: data.gender ?? "",
          picture: data.picture ?? user?.picture,
        });
        setLoading(false);
        toast.success(t("textProfileEditedSuccess"));
        dispatch(updateUserData({ ...data, groups: user.groups }));
        // change language in local
        console.log("data1?.language ===> ", data1?.language);
        i18n.changeLanguage("fr");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.detail);
        setLoading(false);
      });
  };

  useEffect(() => {
    API.get(URLS.AUTHS.me)
      .then((resp) => {
        const { data } = resp;
        reset({
          firstName: data?.first_name ?? "",
          lastName: data?.last_name ?? "",
          email: data?.email ?? "",
          phoneNumber: data?.phone_number ?? "",
          language: data?.language ?? "en",
          age: data?.age ?? "",
          gender: data?.gender ?? "",
        });

        // Reset social form
        resetSocial({
          linkedin: data.linkedin ?? "",
          github: data.github ?? "",
          website: data.website ?? "",
          twitter: data.twitter ?? "",
        });
        if (data?.picture) {
          if (data?.picture?.includes("https://")) {
            setPreview(data?.picture);
          } else {
            setPreview(URLS.BASE_URL + data?.picture);
          }
        } else {
          setPreview("/assets/imgs/page/profile/img-profile.png");
        }
        setLoadingUser(false);
      })
      .catch((error) => {
        if (auth) {
          const { user } = auth;
          reset({
            firstName: user?.first_name ?? "",
            lastName: user?.last_name ?? "",
            email: user?.email ?? "",
            phoneNumber: user?.phone_number ?? "",
            language: user?.language ?? "en",
            age: user?.age ?? "",
            gender: user?.gender ?? "",
          });
          if (user?.picture) {
            if (user?.picture?.includes("https://")) {
              setPreview(user?.picture);
            } else {
              setPreview(URLS.BASE_URL + user?.picture);
            }
          } else {
            setPreview("/assets/imgs/page/profile/img-profile.png");
          }
        }
        setLoadingUser(false);
      });
  }, [auth]);

  return (
    <div className="box-content">
      <div className="box-heading">
        <div className="box-title">
          <h3 className="mb-35">{t("textMyProfile")}</h3>
        </div>
        <div className="box-breadcrumb">
          <div className="breadcrumbs">
            <ul>
              <li>
                {" "}
                <Link className="icon-home" to="/">
                  {t("textDashboard")}
                </Link>
              </li>
              <li>
                <span>{t("textMyProfile")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {loadingUser && (
        <div className="d-flex">
          <div
            className="spinner-border m-auto spinner-border-md"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {!loadingUser && (
        <div className="row">
          <div className="col-xxl-9 col-xl-8 col-lg-8">
            <div className="section-box">
              <div className="container">
                <div className="panel-white mb-30">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="box-padding">
                      <h6 className="color-text-paragraph-2">
                        {t("textUpdateProfile")}
                      </h6>
                      <div className="box-profile-image">
                        <div className="img-profile">
                          {" "}
                          <img src={preview} alt={auth?.user?.first_name} />
                        </div>
                        <div className="info-profile">
                          {" "}
                          <input
                            type="file"
                            hidden
                            id="inputAvatar"
                            onChange={handleChangeProfile}
                          />{" "}
                          <label
                            className="btn btn-default"
                            htmlFor="inputAvatar"
                          >
                            {t("textUploadAvatar")}
                          </label>
                          {/* <Link className="btn btn-link">{t('textDelete')}</Link> */}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group mb-30">
                            <label className="font-sm color-text-mutted mb-10">
                              {t("textFirstName")} *
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              {...register("firstName")}
                              placeholder="Steven"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group mb-30">
                            <label className="font-sm color-text-mutted mb-10">
                              {t("textLastName")} *
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              {...register("lastName")}
                              placeholder="Job"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group mb-30">
                            <label className="font-sm color-text-mutted mb-10">
                              {t("textEmail")} *
                            </label>
                            <input
                              disabled
                              className="form-control"
                              type="text"
                              {...register("email")}
                              placeholder="stevenjob@gmail.com"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group mb-30">
                            <label className="font-sm color-text-mutted mb-10">
                              {t("textPhoneNumber")}
                            </label>
                            <input
                              className="form-control"
                              type="tel"
                              {...register("phoneNumber")}
                              placeholder="01 - 234 567 89"
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group mb-30">
                            <label className="font-sm color-text-mutted mb-10">
                              {t("textLanguage")}
                            </label>
                            <select
                              className="form-control"
                              {...register("language")}
                            >
                              <option value="fr">{t("textLngFrench")}</option>
                              <option value="en">{t("textLngEnglish")}</option>
                            </select>{" "}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group mb-30">
                            <label
                              className="font-sm color-text-mutted mb-10"
                              htmlFor="form-sex"
                            >
                              {t("textGender")}
                            </label>
                            <select
                              id="form-sex"
                              className="form-control"
                              {...register("gender")}
                            >
                              <option value="">{t("textChoose")}</option>
                              <option value="male">{t("textMale")}</option>
                              <option value="female">{t("textFemale")}</option>
                            </select>{" "}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group mb-30">
                            <label className="font-sm color-text-mutted mb-10">
                              {t("textAge")}
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              min={15}
                              max={120}
                              {...register("age")}
                              placeholder="18"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group mt-10">
                            <button
                              type="submit"
                              className="btn btn-default btn-brand"
                            >
                              {t("buttonSaveChange")}
                              {loading && (
                                <div
                                  className="spinner-border ml-10 spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-4 col-lg-4"></div>
        </div>
      )}
      <AuthFooter />
    </div>
  );
};

export default MyProfile;
