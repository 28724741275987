import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { LuTrash } from "react-icons/lu";
import { confirmDeletion } from "../../../../../../components/dletion";
import { useParams } from "react-router-dom";
import { URLS } from "../../../../../../services/urls";

const ManageExtraData = ({ handleAdd, questions, handleEdit, handleRemove }) => {
  const { t } = useTranslation();

  const formExtraRef = useRef();

  const { action } = useParams();

  const onSuccess = () => {
    handleRemove(toDelete);
    setToDelete();
  }

  const handleEditRow = (question) => {
    setToEdit(question);
    setRequired(question.required);
    setType(question.question_type);
    setQuestion(question.question);
    window.$('#manageDynamicForm').modal('toggle');
  }

  const [loading, setLoading] = useState(false);
  const [toDelete, setToDelete] = useState("");
  const [toEdit, setToEdit] = useState("");
  const [type, setType] = useState("");
  const [question, setQuestion] = useState("");
  const [required, setRequired] = useState("");

  const onSubmit = () => {
    let questiona = {question, required: required === "true" ? true : false , question_type: type, id: toEdit ? toEdit.id : Date.now() };
    if(toEdit) {
        questiona.is_edited = true;
        handleEdit(questiona);
        setRequired("");
        setType("");
        setQuestion("");

    } else {
        questiona.is_new = true;
        handleAdd(questiona);
        setToEdit();
        setRequired("");
        setType("");
        setQuestion("");
    }
    console.log(questiona)
    window.$('#manageDynamicForm').modal('toggle');
    formExtraRef?.current?.scrollIntoView()
  };

  return (
    <div ref={formExtraRef}>
      <hr />
      <h5>{t("textXtraDataMessage")}</h5>
      <dir className="d-flex">
        <button
          type="button"
          className="btn btn-primary ml-auto"
          data-bs-toggle="modal"
          data-bs-target="#manageDynamicForm"
        >
          {t("textAddEntry")}
        </button>
      </dir>
      {questions && <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">{t("textLabel")}</th>
                    <th scope="col">{t("textRequired")}</th>
                    <th scope="col">{t("textType")}</th>
                  </tr>
                </thead>
                <tbody>
                  {questions?.map((config) => (
                    <tr className="hover">
                      
                      <td style={{ verticalAlign: "middle" }}>
                        {config?.question}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {config?.required ? t("textYes") : t("textNo")}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {config?.question_type}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <button
                        onClick={() => handleEditRow(config)}
                          className="btn btn-default p-1 mr-3"
                          style={{ height: "30px", marginRight: "5px" }}

                        >
                          <img
                            src="/assets/imgs/page/dashboard/edit.svg"
                            alt=""
                          />
                        </button>
                        <button
                        onClick={action !== "create" ? () => {
                            setToDelete(config);
                            confirmDeletion(URLS.JOBS.RELATED_QUESTION.delete(config.id), t, onSuccess);
                        } : () => handleRemove(config)}
                          className="btn btn-default p-1"
                          style={{ height: "30px" }}

                        >
                            <span className="d-flex" style={{}}>

                          <LuTrash fontSize={18} />
                            </span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>}
      <div
        className="modal fade"
        id="manageDynamicForm"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content apply-job-form">
            <button
              className="btn-close"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-body pl-30 pr-30 pt-50">
              <div className="text-center">
                <p className="font-sm text-brand-2">
                  {toEdit ? t("textEdit") : t("textAddNew")}
                </p>
              </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="input-1">
                    {t("textLabel")} *
                  </label>
                  <input
                    className="form-control"
                    id="input-1"
                    type="text"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    placeholder={t("textLabel")}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="des">
                    {t("textRequired")}
                  </label>
                  <select 
                    value={required}
                    onChange={(e) => setRequired(e.target.value)}  className="form-control">
                    <option value=""> {t("textChoose")} </option>
                    <option value="true"> {t("textYes")} </option>
                    <option value="false"> {t("textNo")} </option>
                  </select>
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="des">
                    {t("textType")}
                  </label>
                  <select 
                    value={type}
                    onChange={(e) => setType(e.target.value)}  className="form-control">
                    <option value=""> {t("textChoose")} </option>
                    <option value="yes/no"> {t("textYesNo")} </option>
                    <option value="true/false"> {t("textTrueFalse")} </option>
                    <option value="number"> {t("textNumber")} </option>
                    <option value="characters"> {t("textCharacters")} </option>
                  </select>
                </div>
                <div className="row"></div>
                <div className="form-group">
                  <button
                    className="btn btn-default hover-up w-100"
                    onClick={onSubmit}
                    type="button"
                  >
                    {t("textAddNew")}
                    {loading && (
                      <div
                        className="spinner-border ml-10 spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageExtraData;
