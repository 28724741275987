import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ExperienceTimelineCard = (props) => {
  const { experience, handleChange, handleDelete, hideActions } = props;

  const { t } = useTranslation();
  return (
    <div className="item-timeline">
      <div className="timeline-year">
        {" "}
        <span>
          {experience?.start_date?.split("-")[0]} -{" "}
          {!experience?.current_job
            ? experience?.end_date?.split("-")[0]
            : t("currentlyShort")}
        </span>
      </div>
      <div className="timeline-info">
        <h5 className="color-brand-1 mb-20">{experience?.title}</h5>
        <p className="color-text-paragraph-2 mb-15">
          {t("experienceCompany")}: {experience?.company?.name} <br/>
          {t("experienceSector")}: {experience?.activity?.name} <br/>
          {t("textDescrption")}: {experience?.description} <br/>
        </p>
      </div>
      {!hideActions && <div className="timeline-actions">
        {" "}
        <Link to={"#"} onClick={(e) => handleChange(experience)} className="btn btn-editor position-inherit m-auto"></Link>
        <Link to="#" onClick={(e) => handleDelete(experience)} className="btn btn-remove m-auto"></Link>
      </div>}
    </div>
  );
};

export default ExperienceTimelineCard;
