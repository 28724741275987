import { Link } from "react-router-dom";
import useSWR from "swr";
import { URLS } from "../../../services/urls";
import AuthFooter from "../components/footer";
import { useTranslation } from "react-i18next";
import JobCardList from "../../../components/jobs/JobCardList";
import CountUp from "react-countup";
import { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { API } from "../../../services/axios";
import moment from "moment";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // CSS stylesheet for styling
import './tooltipTippy.css'
import { percentageFormater } from "../../../components/utils/functions";

const UserDashboard = () => {
  // const { data, isLoading } = useSWR(URLS.DASHBOARD.admin);
  const { data: jobs, isLoading: loadingJobs } = useSWR(
    URLS.JOBS.list("", 1, "")
  );
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      isInitial: true,
    },
  ]);

  useEffect(() => {
    if (date[0].isInitial && date[0].startDate !== date[0].endDate) {
      API.get(URLS.DASHBOARD.admin)
        .then((resp) => {
          setData(resp.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      API.get(
        URLS.DASHBOARD.adminInterval(
          moment(date[0].startDate).format("YYYY-MM-DD"),
          moment(date[0].endDate).format("YYYY-MM-DD")
        ),
        {
          params: {
            start: moment(date[0].startDate).format("YYYY-MM-DD"),
            end: moment(date[0].endDate).format("YYYY-MM-DD"),
          },
        }
      )
        .then((resp) => {
          setLoading(false);
          setData(resp.data);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [date]);

  function convertToCSV(data) {
    const escapeValue = (value) => {
      return "" + String(value).replace(/"/g, "") + "";
    };

    const header = Object.keys(data[0]).map(escapeValue).join(",         ");
    const rows = data
      .map((row) =>
        Object.values(row).map(escapeValue).join(",                    ")
      )
      .join("\n");

    return header + "\n" + rows;
  }
  function downloadCSV(data, filename) {
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function exportDataToCSV() {
    const csvData = [
      {
        widget: "Users   ",
        active: data?.users.active,
        growth: data?.users.growth,
        this_month: data?.users.this_month,
      },
      {
        widget: "CV     ",
        active: data?.profiles.active,
        growth: data?.profiles.growth,
        this_month: data?.profiles.this_month,
      },
      {
        widget: "Jobs     ",
        active: data?.job.active,
        growth: data?.job.growth,
        this_month: data?.job.this_month,
      },
      {
        widget: "Job found",
        active: data?.jobs_found.active,
        growth: data?.jobs_found.growth,
        this_month: data?.jobs_found.this_month,
      },
      {
        widget: "Applications",
        active: data?.application.active,
        growth: data?.application.growth,
        this_month: data?.application.this_month,
      },
      {
        widget: "Interviews",
        active: data?.interviews.active,
        growth: data?.interviews.growth,
        this_month: data?.interviews.this_month,
      },
      {
        widget: "Recruiters",
        active: data?.recruiters.active,
        growth: data?.recruiters.growth,
        this_month: data?.recruiters.this_month,
      },
      // Ajoutez d'autres widgets ici
    ];

    downloadCSV(csvData, "widgets_data.csv");
  }

  return (
    <div className="box-content">
      <div className="box-heading">
        <div className="box-title">
          <h3 className="mb-35 text-left">{t("textDashboard")}</h3>
        </div>
        <div className="box-breadcrumb">
          <div className="breadcrumbs">
            <ul>
              <li>
                {" "}
                <Link className="icon-home" to="/">
                  {t("textDashboard")}
                </Link>
              </li>
              <li>
                <span>{t("textDashboard")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 col-12">
          <div className="section-box">
            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6">
              <Tippy content={t("textwidinterviews")} theme="monThemeRouge">

                <div className="card-style-1 hover-up">
                  <div className="card-image">
                    {" "}
                    <img
                      src="assets/imgs/page/dashboard/computer.svg"
                      alt="Cible rh emploie"
                    />
                  </div>
                  <div className="card-info background-image-none p-0">
                    <div className="card-title">
                      <h3>
                        {data?.interviews?.active}
                        <span
                          className={`font-sm status ${
                            data?.interviews?.growth > 0
                              ? "up"
                              : data?.interviews?.growth === 0
                              ? ""
                              : "down"
                          }`}
                        >
                          {percentageFormater(data?.interviews?.growth)}
                          {/* {data?.interviews?.growth}
                          <span> %</span> */}
                        </span>
                      </h3>
                    </div>
                    <span className="font-sm">
                      {t("textThisMonth")}:{" "}
                      {loading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <CountUp
                          start={0}
                          delay={0}
                          end={data?.interviews?.this_month}
                        />
                      )}
                    </span>
                    <p className="color-text-paragraph-2 text-left">
                      {t("textInterviewSchedules")}
                    </p>
                  </div>
                </div>
                </Tippy>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6">
              <Tippy content={t("textwidinterjobfound")} theme="monThemeRouge">
                <div className="card-style-1 hover-up">
                  <div className="card-image">
                    {" "}
                    <img
                      src="assets/imgs/page/dashboard/open-file.svg"
                      alt="Cible rh emploie"
                    />
                  </div>
                  <div className="card-info background-image-none p-0">
                    <div className="card-title">
                      <h3>
                        {data?.jobs_found.active}
                        <span
                          className={`font-sm status ${
                            data?.jobs_found?.growth > 0
                              ? "up"
                              : data?.jobs_found?.growth === 0
                              ? ""
                              : "down"
                          }`}
                        >
                          {!loading &&
                            percentageFormater(data?.jobs_found?.growth)
                            // parseFloat(
                            //   String(data?.jobs_found?.growth)
                            // ).toFixed(2)
                            }
                          {/* <span> %</span> */}
                        </span>
                      </h3>
                    </div>
                    <span className="font-sm">
                      {t("textThisMonth")}:{" "}
                      {loading ? (
                        <span
                          cclassName="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <CountUp
                          start={0}
                          delay={0}
                          end={data?.jobs_found?.this_month}
                        />
                      )}
                    </span>
                    <p className="color-text-paragraph-2 text-left">
                      {t("jobFound")}
                    </p>
                  </div>
                </div>
                </Tippy>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6">
              <Tippy content={t("textwidinterapplication")} theme="monThemeRouge">
                <div className="card-style-1 hover-up">
                  <div className="card-image">
                    {" "}
                    <img
                      src="assets/imgs/page/dashboard/headphone.svg"
                      alt="Cible rh emploie"
                    />
                  </div>
                  <div className="card-info background-image-none p-0">
                    <div className="card-title">
                      <h3>
                        {data?.application.active}
                        <span
                          className={`font-sm status ${
                            data?.application?.growth > 0
                              ? "up"
                              : data?.application?.growth === 0
                              ? ""
                              : "down"
                          }`}
                        >
                          {!loading &&
                            percentageFormater(data?.application?.growth)
                            // parseFloat(
                            //   String(data?.application?.growth)
                            // ).toFixed(2)
                            }
                          {/* <span> %</span> */}
                        </span>
                      </h3>
                    </div>
                    <span className="font-sm">
                      {t("textThisMonth")}:{" "}
                      {loading ? (
                        <span
                          cclassName="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <CountUp
                          start={0}
                          delay={0}
                          end={data?.application?.this_month}
                        />
                      )}
                    </span>
                    <p className="color-text-paragraph-2 text-left">
                      {t("textApplication")}
                    </p>
                  </div>
                </div>
                </Tippy>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6">
              <Tippy content={t("textwidinterjob")} theme="monThemeRouge">
                <div className="card-style-1 hover-up">
                  <div className="card-image">
                    {" "}
                    <img
                      src="assets/imgs/page/dashboard/look.svg"
                      alt="Cible rh emploie"
                    />
                  </div>
                  <div className="card-info background-image-none p-0">
                    <div className="card-title">
                      <h3>
                        {data?.job.active}
                        <span
                          className={`font-sm status ${
                            data?.job?.growth > 0
                              ? "up"
                              : data?.job?.growth === 0
                              ? ""
                              : "down"
                          }`}
                        >
                          {!loading &&
                            percentageFormater(data?.job?.growth)
                            // parseFloat(String(data?.job?.growth)).toFixed()
                            }
                          {/* <span> %</span> */}
                        </span>
                      </h3>
                    </div>
                    <span className="font-sm">
                      {t("textThisMonth")}:{" "}
                      {loading ? (
                        <span
                          cclassName="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <CountUp
                          start={0}
                          delay={0}
                          end={data?.job?.this_month}
                        />
                      )}
                    </span>
                    <p className="color-text-paragraph-2 text-left">
                      {t("textJobs")}
                    </p>
                  </div>
                </div>
                </Tippy>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6">
              <Tippy content={t("textwidintercv")} theme="monThemeRouge">
                <div className="card-style-1 hover-up">
                  <div className="card-image">
                    {" "}
                    <img
                      src="assets/imgs/page/dashboard/man.svg"
                      alt="Cible rh emploie"
                    />
                  </div>
                  <div className="card-info background-image-none p-0">
                    <div className="card-title">
                      <h3>
                        {data?.profiles.active}
                        <span
                          className={`font-sm status ${
                            data?.profiles?.growth > 0
                              ? "up"
                              : data?.profiles?.growth === 0
                              ? ""
                              : "down"
                          }`}
                        >
                          {percentageFormater(data?.profiles?.growth)}
                          {/* {data?.profiles?.growth}
                          <span> %</span> */}
                        </span>
                      </h3>
                    </div>
                    <span className="font-sm">
                      {t("textThisMonth")}:{" "}
                      {loading ? (
                        <span
                          cclassName="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <CountUp
                          start={0}
                          delay={0}
                          end={data?.profiles?.this_month}
                        />
                      )}
                    </span>
                    <p className="color-text-paragraph-2 text-left">
                      {t("textCVAdded")}
                    </p>
                  </div>
                </div>
                </Tippy>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6">
              <Tippy content={t("textwidinteruser")} theme="monThemeRouge">
                <div className="card-style-1 hover-up">
                  <div className="card-image">
                    {" "}
                    <img
                      src="assets/imgs/page/dashboard/man.svg"
                      alt="Cible rh emploie"
                    />
                  </div>
                  <div className="card-info background-image-none p-0">
                    <div className="card-title">
                      <h3>
                        {data?.users.active}
                        <span
                          className={`font-sm status ${
                            data?.users?.growth > 0
                              ? "up"
                              : data?.users?.growth === 0
                              ? ""
                              : "down"
                          }`}
                        >
                          {!loading &&
                            percentageFormater(data?.users?.growth)
                            // parseFloat(String(data?.users?.growth)).toFixed()
                            }
                          {/* <span> %</span> */}
                        </span>
                      </h3>
                    </div>
                    <span className="font-sm">
                      {t("textThisMonth")}:{" "}
                      {loading ? (
                        <span
                          cclassName="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <CountUp
                          start={0}
                          delay={0}
                          end={data?.users?.this_month}
                        />
                      )}
                    </span>
                    <p className="color-text-paragraph-2 text-left">
                      {t("textUsers")}
                    </p>
                  </div>
                </div>
                </Tippy>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6">
              <Tippy content={t("textwidinterrecruiter")} theme="monThemeRouge">
                <div className="card-style-1 hover-up">
                  <div className="card-image">
                    {" "}
                    <img
                      src="assets/imgs/page/dashboard/bank.svg"
                      alt="Cible rh emploie"
                    />
                  </div>
                  <div className="card-info background-image-none p-0">
                    <div className="card-title">
                      <h3>
                        {data?.recruiters.active}
                        <span
                          className={`font-sm status ${
                            data?.recruiters?.growth > 0
                              ? "up"
                              : data?.recruiters?.growth === 0
                              ? ""
                              : "down"
                          }`}
                        >
                          {percentageFormater(data?.recruiters?.growth)}
                          {/* {data?.recruiters?.growth} */}
                          {/* <span> %</span> */}
                        </span>
                      </h3>
                    </div>
                    <span className="font-sm">
                      {t("textThisMonth")}:{" "}
                      {loading ? (
                        <span
                          cclassName="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <CountUp
                          start={0}
                          delay={0}
                          end={data?.recruiters?.this_month}
                        />
                      )}
                    </span>
                    <p className="color-text-paragraph-2 text-left">
                      {t("experienceCompany")}
                    </p>
                  </div>
                </div>
                </Tippy>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6">
              <Tippy content={t("textwidinterarticle")} theme="monThemeRouge">
                <div className="card-style-1 hover-up">
                  <div className="card-image">
                    {" "}
                    <img
                      src="assets/imgs/page/dashboard/doc.svg"
                      alt="Cible rh emploie"
                    />
                  </div>
                  <div className="card-info background-image-none p-0">
                    <div className="card-title">
                      <h3>
                        {data?.articles.active}
                        <span
                          className={`font-sm status ${
                            data?.articles?.growth > 0
                              ? "up"
                              : data?.articles?.growth === 0
                              ? ""
                              : "down"
                          }`}
                        >
                          {!loading &&
                            percentageFormater(data?.articles?.growth)
                            // parseFloat(String(data?.articles?.growth)).toFixed(2)
                            }
                          {/* <span> %</span> */}
                        </span>
                      </h3>
                    </div>
                    <span className="font-sm">
                      {t("textThisMonth")}:{" "}
                      {loading ? (
                        <span
                          cclassName="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <CountUp
                          start={0}
                          delay={0}
                          end={data?.articles?.this_month}
                        />
                      )}
                    </span>
                    <p className="color-text-paragraph-2 text-left">
                      {t("textArticlesAdded")}
                    </p>
                  </div>
                </div>
                </Tippy>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-12">
          <div>
            <h4>{t("textChoosePeriod")}</h4>
            <DateRange
              editableDateInputs={true}
              onChange={(item) => {
                setDate([{ ...item.selection, isInitial: false }]);
                console.log(item.selection);
              }}
              moveRangeOnFirstSelection={false}
              ranges={date}
            />
          </div>
          <br />
          <div className="d-flex">
            <button onClick={exportDataToCSV} className="btn  btn-secondary">
              {t("Exporter les données en CSV")}
            </button>
          </div>
        </div>

        <div className="col-12">
          <div>
            <div className="section-box">
              <div className="container">
                <div className="panel-white">
                  <div className="panel-head">
                    <h5>{t("textLatestJobs")}</h5>
                    <Link
                      className="menudrop"
                      id="dropdownMenu3"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-display="static"
                    ></Link>
                    <ul
                      className="dropdown-menu dropdown-menu-light dropdown-menu-end"
                      aria-labelledby="dropdownMenu3"
                    >
                      <li>
                        <Link className="dropdown-item active" to="/job/create">
                          {t("textAddNew")}
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/configurations">
                          {t("txtConfig")}
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="panel-body max-h-90-vh overflow-y-scroll">
                    <div className="row display-list">
                      {loadingJobs && (
                        <div className="col-12 mb-11 d-flex">
                          <span
                            className="spinner-border m-auto"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </div>
                      )}
                      {jobs?.results?.map((job) => (
                        <>{job?.validated && (<div key={job.id} className="col-lg-6">
                          <JobCardList job={job} />
                        </div>)}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AuthFooter />
    </div>
  );
};

export default UserDashboard;
