import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { API } from "../../../../../../services/axios";
import { URLS } from "../../../../../../services/urls";

const CompanyManage = ({ toEdit, onSuccess }) => {
  const { register, reset, handleSubmit, formState: {errors} } = useForm();
  const { t } = useTranslation();
  const { auth } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [previewLink, setPReviewLink] = useState(toEdit?.icon);

  const handleChangeImage = (event) => {
    const file = event?.target?.files[0];
    setFile(file);
    setPReviewLink(URL.createObjectURL(file));
  };

  const onSubmit = (data) => {
    let formData = new FormData();
    formData.append("name", data.name);
    formData.append("company_url", data.companyUrl);
    formData.append("email", data.email);
    formData.append("is_partner", data.isPartner);
    if(auth?.user?.id){

      formData.append("created_by", auth?.user?.id);
    }
    setLoading(true);
    if (file) {
      formData.append("icon", file);
    }
    if (toEdit) {
      API.put(URLS.CONFIGS.PROFILE.COMPANY.update(toEdit.id), formData)
        .then((resp) => {
          toast.success(t("textConfigEditionSuccess"));
          setLoading(false);
          onSuccess();

          window.$("#manageIndustryForm").modal("hide");
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.detail ?? error?.response?.data?.message ?? t("textConfigEditionError")
          );
          setLoading(false);
        });
    } else {
      API.post(URLS.CONFIGS.PROFILE.COMPANY.create, formData)
        .then((resp) => {
          toast.success(t("textConfigCreatedSuccess"));
          setLoading(false);
          onSuccess();
          window.$("#manageIndustryForm").modal("hide");
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.detail ?? error?.response?.data?.message ?? t("textConfigCreatedError")
          );
          setLoading(false);
        });
    }
  };

  return (
    <div
      className="modal fade"
      id="manageIndustryForm"
      tabIndex={-1}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content apply-job-form">
          <button
            className="btn-close"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div className="modal-body pl-30 pr-30 pt-50">
            <div className="text-center">
              <p className="font-sm text-brand-2">{t("textAddNew")}</p>
              {/* <h2 className="mt-10 mb-5 text-brand-1 text-capitalize">Start your career today</h2> */}
              {/* <p className="font-sm text-muted mb-30">Please fill in your information and send it to the employer.                   </p> */}
            </div>
            <form
              className="login-register text-start mt-20 pb-30"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-group">
                <label className="form-label" htmlFor="input-name">
                  {t("textName")} *
                </label>
                <input
                  className="form-control"
                  id="input-name"
                  defaultValue={toEdit?.name}
                  type="text"
                  {...register("name", { required: true })}
                  placeholder="Cible RH Emploie"
                />
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="input-email">
                  {t("textEmail")} *
                </label>
                <input
                  className="form-control"
                  id="input-email"
                  defaultValue={toEdit?.email}
                  type="text"
                  {...register("email", {
                    required: true,
                    validate: {
                      maxLength: (v) => v.length <= 50 || t("textAtMost"),
                      matchPattern: (v) =>
                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                        t("textValidEmail"),
                    },
                  })}
                  placeholder="email@example.com"
                />
                <span className="text-primary">
                                {errors.email?.message && (
                                  <small>{errors.email.message}</small>
                                )}
                              </span>
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="input-company_url">
                  {t("textCompanyUrl")} *
                </label>
                <input
                  className="form-control"
                  id="input-company_url"
                  defaultValue={toEdit?.company_url}
                  type="text"
                  {...register("companyUrl", { required: true,
                    validate: {
                      maxLength: (v) => v.length <= 50 || t("textAtMost"),
                      matchPattern: (v) =>
                      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/.test(v) ||
                        t("textInvalidUrl"),
                    }
                   })}
                  placeholder="https://example.com"
                />
                <span className="text-primary">
                {errors.companyUrl?.message && (
                  <small>{errors.companyUrl.message}</small>
                )}
              </span>
              </div>
              <div>
                <div className="login_footer form-group d-flex justify-content-between">
                  <label className="cb-container">
                    <input
                      type="checkbox"
                      defaultValue={toEdit?.is_partner}
                      {...register("isPartner")}
                    />
                    <span className="text-small">{t("textPartner")} ?</span>
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                {/* <div className="col-md-6">
                        <div>
                            <div className="form-group mb-30">
                              <div className="box-upload">
                                <div className="add-file-upload" style={previewLink ? {background: `#ffffff url(${previewLink})`} : {}}>
                                  <input
                                    className="fileupload"
                                    type="file"
                                    name="file"
                                    id="file"
                                    onChange={handleChangeImage}
                                  />
                                </div>
                                <label htmlFor="file" className="btn btn-default">
                                    {t("textUploadImage")}
                                </label>
                              </div>
                            </div>
                        </div>
                    </div> */}
              </div>
              <div className="form-group">
                <button
                  className="btn btn-default hover-up w-100"
                  type="submit"
                  name="login"
                >
                  {t("textAddNew")}
                  {loading && (
                    <div
                      className="spinner-border ml-10 spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
              {/* <div className="text-muted text-center">Do you need support? <a href="page-contact.html">Contact Us</a></div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyManage;
