import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { formatLinkString } from "../utils/functions";
import { DEFAULT_IMAGE } from "../utils/consts";

const JobCardList = ({ job }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className="card-style-2 job-card-list hover-up cursor-pointer"
      onClick={() => navigate(`/jobs/${job.slug}/details`)}
      style={{height: "385px"}}
    >
      <div className="card-head">
        <div className="card-image">
          {" "}
          <img
            className=""
            src={job?.image ? formatLinkString(job?.image) : DEFAULT_IMAGE}
            alt={job.title}
          />
        </div>
        <div className="card-title">
          <Link to={`/jobs/${job?.slug}/details`}>
            <h6>{job.title}</h6>
          </Link>
          <span className="job-type">{job?.type}</span>
          <span className="time-post">{moment(job?.created_at).fromNow()}</span>
          <span className="location">
            {job?.city?.name}, {job?.country?.name}
          </span>
        </div>
        {/* <span className="card-time bg-none p-0">
        {t("textNberPlaces")}: {job.required}, {" "}
        {t("textNberAppl")}: {job.submitted},  {" "}
        {t("textDeadline")}: {moment(job?.application_end).fromNow()}
        </span> */}
      </div>
      <div className="card-tags w-100">
        {" "}
        {job.skills.slice(0, 2).map((skill) => (
          <Link key={"skill-" + skill.id} className="btn btn-tag">
            {skill.name}
          </Link>
        ))}
      </div>
      {/* <div className="card-price">
        <strong>XAF {job.salary_min}</strong>
        <span className="hour">/{t('textMonth')}</span>
      </div> */}
      <div className="d-flex w-100">
        <span>
          {job.applications.length} {t("textApplications")}
        </span>
        <span className="ml-auto">
          {job.required} {t("textAvailablePosts")}
          {job.required > 1 ? "s" : ""}
        </span>
      </div>
    </div>
  );
};

export default JobCardList;
