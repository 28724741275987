import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls";
import { toast } from "react-toastify";
import { setUserDetails } from "../../components/utils/functions";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../store/slices/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./passwordInput.css";

const Login = () => {
  const [loading, setLoading] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    setLoading(true);
    API.post(URLS.AUTHS.LOGIN, data)
      .then((resp) => {
      console.log('Response Data:', resp.data);
      console.log('User Level:', resp.data.user["user_level"]);
      console.log('Search Params Redirect:', searchParams.get("redirect"));
      console.log(resp.data['user'])
        if (
          resp.data.user["user_level"] === "jobseeker" ||
          resp.data.user["user_level"] === "jobseeket"
        ) {
          setLoading(false);
          toast.error(t("textLoginError"));
        } else {
          const redirect = window.localStorage.getItem("redirect");
          const redirect_link = redirect?.includes('login')?"/":redirect;
          setLoading(false);
          setUserDetails(resp.data);
          dispatch(loginSuccess(resp.data));
          navigate(searchParams.get("redirect") ?? redirect_link ?? "/");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.detail ??
            error?.response?.data?.message ??
            t("textLoginError")
        );
      });
  };
  return (
    <div className="box-content">
    <div className="banner-hero hero-2">
        <div className="banner-inner"></div>
      </div>
      <div className="box-heading"></div>
      <div className="row">
        <div className="col-lg-12">
          <div className="section-box">
            <div className="container">
              <div className="panel-white mb-30">
                <div className="box-padding">
                  <div className="login-register">
                    <div className="row login-register-cover pb-3">
                      <div className="col-lg-4 col-md-6 col-sm-12 mx-auto">
                        <div className="form-login-cover">
                        <div class="text-center">
                            <img
                              src="assets/imgs/page/about/logo.png"
                              style={{ width: "185px" }}
                              alt="logo"
                            />
                          </div>
                          <form
                            className="login-register text-start mt-20"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <div className="form-group">
                              <label className="form-label" htmlFor="input-1">
                                {t("email")} *
                              </label>
                              <input
                                className="form-control"
                                id="input-1"
                                type="text"
                                required=""
                                {...register("email", {
                                  required: true,
                                  validate: {
                                    maxLength: (v) =>
                                      v.length <= 50 || t("textAtMost"),
                                    matchPattern: (v) =>
                                      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                                        v
                                      ) || t("textValidEmail"),
                                  },
                                })}
                                placeholder="stevenjob@gmail.com"
                              />
                              <span className="text-primary">
                                {errors.email?.message && (
                                  <small>{errors.email.message}</small>
                                )}
                              </span>
                            </div>
                            <div className="form-group">
                              <label className="form-label" htmlFor="input-4">
                                {t("password")} *
                              </label>
                              <input
                                className="form-control"
                                id="input-4"
                                type={showPassword ? "text" : "password"}
                                {...register("password")}
                                required=""
                                placeholder="************"
                              />
                              <div
                                className="eye-icon"
                                onClick={togglePasswordVisibility}
                              >
                                <FontAwesomeIcon
                                  icon={showPassword ? faEye : faEyeSlash}
                                />
                              </div>
                            </div>
                            <div className="login_footer form-group d-flex justify-content-between">
                              {/* <label className="cb-container">
                                <input type="checkbox" />
                                <span className="text-small">
                                  {t("rememberMe")}
                                </span>
                                <span className="checkmark"></span>
                              </label> */}
                              <Link className="text-muted" to="/password-reset">
                                {t("forgotPassword")}
                              </Link>
                            </div>
                            <div className="form-group">
                              <button
                                className="btn btn-brand-1 hover-up w-100"
                                type="submit"
                                name="login"
                                disabled={loading}
                              >
                                {t("actionLogin")}
                                {loading && (
                                  <div
                                    className="spinner-border ml-10 spinner-border-sm"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                )}
                              </button>
                            </div>
                            {/* <div className="text-muted text-center">
                              {t("dontHaveAccount")}{" "}
                              <Link to="/register">{t("signUp")}</Link>
                            </div> */}
                          </form>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
