import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { URLS } from "../../../../services/urls";
import TimelineCard from "../components/TimelineCard";
import ExperienceTimelineCard from "../components/ExperienceTimeLine";
import { API } from "../../../../services/axios";
import { toast } from "react-toastify";
import { useState } from "react";
import {
  APPLICATION_STEPS,
  DEFAULT_PROFILE,
} from "../../../../components/utils/consts";

const UserCVDetails = (props) => {
  const { jobId, cvId, applicationId } = useParams();
  const { t } = useTranslation();
  const [advancing, setAdvancing] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [accepting, setAccepting] = useState(false);

  const {
    data: application,
    mutate,
    isLoading,
  } = useSWR(
    applicationId
      ? URLS.JOBS.APPLICATIONS.details(applicationId)
      : URLS.USERS.PROFILE.details(cvId)
  );
  
  const handleAccept = () => {
    setAccepting(true)
    API.post(URLS.JOBS.APPLICATIONS.accept(applicationId))
    .then((resp)=>{
      toast.success(t("textAccepted"))
      setAccepting(false);
      mutate();
    })
    .catch((e) => {
      toast.error(
        e?.response?.data?.detail ??
          e?.response?.data?.message ??
          t("textAcceptedError")
      );
      setAdvancing(false);
    });
  }

  const handleAdvance = () => {
    setAdvancing(true);
    API.post(URLS.JOBS.APPLICATIONS.advance(applicationId))
      .then((resp) => {
        toast.success(t("textAdvanced"));
        setAdvancing(false);
        mutate();
      })
      .catch((e) => {
        toast.error(
          e?.response?.data?.detail ??
            e?.response?.data?.message ??
            t("textAdvancingError")
        );
        setAdvancing(false);
      });
  };
  const handleCancel = () => {
    setCancelling(true);
    API.post(URLS.JOBS.APPLICATIONS.cancel(applicationId))
      .then((resp) => {
        toast.success(t("textApplicationCanceled"));
        setCancelling(false);
        mutate();
      })
      .catch((e) => {
        toast.error(
          e?.response?.data?.detail ??
            e?.response?.data?.message ??
            t("textApplicationCancelingError")
        );
        setCancelling(false);
      });
  };

  return (
    <div className="box-content">
      <div className="box-heading">
        <div className="box-title">
          <h3 className="mb-35">{t("textProfileDetails")}</h3>
        </div>
        <div className="box-breadcrumb">
          <div className="breadcrumbs">
            <ul>
              <li>
                {" "}
                <Link className="icon-home" to="/">
                  {t("textAdmin")}
                </Link>
              </li>
              <li>
                {" "}
                <Link className="icon-home" to="/cv-theque">
                  {t("textCvTheque")}
                </Link>
              </li>
              <li>
                <span>
                  {application?.user?.user?.first_name ??
                    application?.user?.first_name ??
                    ""}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="d-flex">
        {isLoading && (
          <div
            className="spinner-border spinner-border-md m-auto"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
      </div>
      {!isLoading && (
        <div className="card p-3 border-radius-15">
          <section className="section-box-2">
            <div className="container">
              <div className="banner-hero banner-image-single pt-0">
                <img
                  src="https://picsum.photos/seed/picsum/500/150"
                  alt="CIBLE RH"
                />
              </div>
              <div className="box-company-profile">
                <div className="image-compay image-company">
                  <img
                    src={
                      application?.user?.user?.picture ??
                      application?.user?.picture ??
                      DEFAULT_PROFILE
                    }
                    alt={
                      application?.user?.user?.first_name ??
                      application?.user?.first_name ??
                      "Cile RH"
                    }
                  />
                </div>
                <div className="row mt-10">
                  <div className="col-lg-8 col-md-12">
                    <h5 className="f-18">
                      {(application?.user?.user?.first_name ??
                        application?.user?.first_name ??
                        "") +
                        " " +
                        (application?.user?.user?.last_name ??
                          application?.user?.last_name ??
                          "")}{" "}
                      <span className="card-location font-regular ml-20">
                        {application?.user?.country}
                      </span>
                      {jobId && application && (
                        <span className="text-success">
                          ({t(`text_${application?.status}`)})
                        </span>
                      )}
                    </h5>
                    <p className="mt-0 font-md color-text-paragraph-2 mb-15">
                      {application?.user?.primary_role}
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-12 text-lg-end">
                    {application?.user?.cv && (
                      <Link
                        target="_blank"
                        download
                        className="btn mb-3 btn-download-icon btn-apply btn-apply-big"
                        to={application?.user?.cv}
                      >
                        {t("textDownloadCv")}
                      </Link>
                    )}
                    {application?.cv && (
                      <Link
                        target="_blank"
                        download
                        className="btn mb-3 btn-download-icon btn-apply btn-apply-big"
                        to={application?.cv}
                      >
                        {t("textDownloadCv")}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              {jobId && application && (
                <div>
                  <h5 className="mb-10">{t("textApplicationStatus")}</h5>
                  <h6 className="mb-20">
                    {
                      // t(`text_${application?.offer?.status}`)
                      // Object.keys(application?.offer?.steps).map(function(key, index) {
                      //   if(application?.offer?.steps[key] && key !== "id"){
                      //     return <span className={`btn btn-tags-sm mb-10 mr-5 ${application?.offer?.steps[key] === application?.status ? 'actual-step' : ''}`}>{index}: {t(`text_${application?.offer?.steps[key]}`)}</span>
                      //   }
                      // })
                    }
                    {APPLICATION_STEPS.map(function (step, index) {
                      return (
                        <span
                          key={step + index}
                          className={`btn btn-tags-sm mb-10 mr-5 ${
                            step === application?.status
                              ? "actual-step"
                              : index <
                                APPLICATION_STEPS.indexOf(application?.status)
                              ? "step-passed"
                              : "next-step"
                          }`}
                        >
                          {index <
                            APPLICATION_STEPS.indexOf(application?.status) && (
                            <span>
                              <img
                                src="/assets/imgs/page/homepage3/check-box.svg"
                                width={"10px"}
                                className="mr-5"
                              />
                            </span>
                          )}
                          {index + 1}: {t(`text_${step}`)}
                        </span>
                      );
                    })}
                  </h6>
                  <div className="d-flex">
                    <Link
                      to={"#"}
                      onClick={handleCancel}
                      className="btn mb-3 mr-2 btn-apply btn-apply-big"
                    >
                      {t("textCancel")}
                      {cancelling && (
                        <div
                          className="spinner-border ml-10 spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </Link>
                    {(application?.status==="waiting_user")?
                      (<Link to={"#"} onClick={handleAccept} 
                      className="btn mb-3 ml-10 btn-apply-big btn-apply"
                      style={{float: "right" }}> {t("textAccept")}
                      {accepting && (
                        <div
                          className="spinner-border ml-10 spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                      </Link>):(
                      <Link
                        to={"#"}
                        onClick={handleAdvance}
                        className="btn mb-3 btn-apply-big btn-secondary"
                        style={{ lineHeight: "27px" }}
                      >
                        {t("textAdvance")}
                        {advancing && (
                          <div
                            className="spinner-border ml-10 spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </Link>)
                    
                      }
                  </div>
                </div>
              )}
              <div className="box-nav-tabs mt-40 mb-5">
                <ul className="nav" role="tablist">
                  <li>
                    <Link
                      className="btn btn-border aboutus-icon mr-15 mb-5 active"
                      to="#tab-short-bio"
                      data-bs-toggle="tab"
                      role="tab"
                      aria-controls="tab-short-bio"
                      aria-selected="true"
                    >
                      {t("textShortBio")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="btn btn-border recruitment-icon mr-15 mb-5"
                      to="#tab-skills"
                      data-bs-toggle="tab"
                      role="tab"
                      aria-controls="tab-skills"
                      aria-selected="false"
                    >
                      {t("textSkills")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="btn btn-border people-icon mb-5"
                      to="#tab-work-experience"
                      data-bs-toggle="tab"
                      role="tab"
                      aria-controls="tab-work-experience"
                      aria-selected="false"
                    >
                      {t("workExperience")}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="border-bottom pt-10 pb-10"></div>
            </div>
          </section>
          <section className="section-box mt-50">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-7 col-12">
                  <div className="content-single">
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="tab-short-bio"
                        role="tabpanel"
                        aria-labelledby="tab-short-bio"
                      >
                        <h4>{t("textAboutMe")}</h4>
                        <p>{application?.user?.bio ?? application?.bio}</p>
                        <h4>{t("titleEducation")}</h4>
                        <div>
                          <div className="row">
                            {(
                              application?.user?.education ??
                              application?.education
                            )?.map((education) => (
                              <div className="col-md-12">
                                <TimelineCard
                                  education={education}
                                  hideActions
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="tab-skills"
                        role="tabpanel"
                        aria-labelledby="tab-skills"
                      >
                        <h4>{t("textSkills")}</h4>
                        <div>
                          <div className="card-2-bottom card-2-bottom-candidate mt-30">
                            <div className="text-start">
                              {(
                                application?.user?.skills ?? application?.skills
                              )?.map((profile) => (
                                <Link
                                  key={profile?.id}
                                  className="btn btn-tags-sm mb-10 mr-5"
                                  to="#"
                                >
                                  {profile?.name}
                                </Link>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="tab-work-experience"
                        role="tabpanel"
                        aria-labelledby="tab-work-experience"
                      >
                        <h4>{t("workExperience")}</h4>
                        <div>
                          <div className="row">
                            {(
                              application?.user?.experience ??
                              application?.experience
                            )?.map((experience) => (
                              <div className="col-md-12">
                                <ExperienceTimelineCard
                                  experience={experience}
                                  hideActions
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box-related-job content-page">
                    {/* <h3 className="mb-30">Work History</h3>
                  <div className="box-list-jobs display-list">
                    <div className="col-xl-12 col-md-12 col-12">
                      <JobHistory />
                    </div>
                  </div> */}
                    {/* Add comment if this profile firure in a candidature */}
                    {/* {jobId && (
                    <div className="box-related-job ">
                      <h3 className="mb-30">Candidature comments</h3>
                      <CommentApplication />
                    </div>
                  )} */}
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-5 col-12 pl-40 pl-lg-15 mt-lg-30">
                  <div className="sidebar-border">
                    <h5 className="f-18">{t("textOverview")}</h5>
                    <div className="sidebar-list-job">
                      <ul>
                        <li>
                          <div className="sidebar-icon-item">
                            <i className="fi-rr-dollar"></i>
                          </div>
                          <div className="sidebar-text-info">
                            <span className="text-description">
                              {t("textExpectedSalary")}
                            </span>
                            <strong className="small-heading">
                              XAF{" "}
                              {application?.user?.salary_min ??
                                application?.salary_min}
                            </strong>
                          </div>
                        </li>
                        <li>
                          <div className="sidebar-icon-item">
                            <i className="fi-rr-marker"></i>
                          </div>
                          <div className="sidebar-text-info">
                            <span className="text-description">
                              {t("jobCountry")}
                            </span>
                            <strong className="small-heading">
                              {application?.user?.user?.country ??
                                application?.user?.country}
                            </strong>
                          </div>
                        </li>
                        <li>
                          <div className="sidebar-icon-item">
                            <i className="fi-rr-link"></i>
                          </div>
                          <div className="sidebar-text-info">
                            <span className="text-description">
                              {t("textLinkedIn")}
                            </span>
                            <strong className="small-heading">
                              {application?.user?.user?.linkedin ??
                                application?.user?.linkedin}
                            </strong>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="sidebar-list-job">
                      <ul className="ul-disc">
                        <li>
                          {t("textPhone")}:{" "}
                          {application?.user?.user?.phone_number ??
                            application?.user?.phone_number}
                        </li>
                        <li>
                          {t("textEmail")}:{" "}
                          {application?.user?.user?.email ??
                            application?.user?.email}
                        </li>
                      </ul>
                      <div className="mt-30">
                        <a
                          className="btn btn-primary"
                          href={`mailto:${application?.user?.user?.email}`}
                        >
                          {t("buttonSendMessage")}
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <div className="sidebar-border-bg bg-right"><span className="text-grey">WE ARE</span><span className="text-hiring">HIRING</span>
                <p className="font-xxs color-text-paragraph mt-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto</p>
                <div className="mt-15"><Link className="btn btn-paragraph-2" to="#">Know More</Link></div>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default UserCVDetails;
