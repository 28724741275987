import { useState } from "react";
import { useTranslation } from "react-i18next";
import { URLS } from "../../../../../../services/urls";
import useSWR from "swr";
import { LuTrash } from "react-icons/lu";
import { confirmDeletion } from "../../../../../../components/dletion";
import { Link } from "react-router-dom";
import { DEFAULT_PAGE_COUNT } from "../../../../../../components/utils/consts";
import CollegeManage from "./manage";

const CollegeList = () => {

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [inputSearch, setInputSearch] = useState("");
  const [toEdit, setToEdit] = useState();

  const { data, isLoading, mutate } = useSWR(URLS.CONFIGS.PROFILE.COLLEGE.list(search));
  const { data:countries, isLoading: loadingCountries } = useSWR(
    URLS.CONFIGS.PROFILE.COUNTRIES.list("")
  );
  const { t } = useTranslation();

  const handleSearch = () => {
    setSearch(inputSearch);
  };
  const handleChangePage = (event, pg) => {
    event.preventDefault();
    setPage(pg);
  };

  const handleEdit = (data) => {
    const newData = {
      ...data,
      country: {
        id: data.country,
        name: countries?.results?.filter((country) => String(country.url).split("/")[String(country.url).split("/").length-2] == data.country)[0]?.name
      }
    }
    console.log(newData)
    setToEdit(newData)
    window.$("#manageIndustryForm").modal("show")
  };

  const onSuccess = () => {
    mutate()
  }

  return (
    <div>
      <CollegeManage countries={countries?.results} toEdit={toEdit} onSuccess={onSuccess} />
      <div className="panel-white mb-30">
        <div className="p-3">
          <div className="row">
            <div className="col-md-6 col-12">
              <div>
                <div className="d-flex">
                  <div className="form-group mx-sm-3 mb-0">
                    <input
                      value={inputSearch}
                      onChange={(e) => setInputSearch(e.target.value)}
                      type="text"
                      className="form-control"
                      id="inputPassword2"
                      placeholder={t("textSearch")}
                    />
                  </div>
                  <button
                    onClick={handleSearch}
                    type="submit"
                    className="btn btn-default "
                  >
                    {t("textSearch")}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div>
                <div className="d-flex w-100">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#manageIndustryForm"
                    type="submit"
                    className="btn btn-default ml-auto"
                  >
                    {t("textAddNew")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="panel-white mb-30">
        <div className="box-padding">
          {isLoading && (
            <div className="d-flex">
              <div className="col-12 mb-10 d-flex">
                <span
                  className="spinner-border m-auto"
                  role="status"
                  aria-hidden="true"
                ></span>
              </div>
            </div>
          )}
          {!isLoading && data && (
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">{t("textName")}</th>
                    <th scope="col">{t("textCountry")}</th>
                    <th scope="col">{t("Actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.results?.map((config) => (
                    <tr className="hover" key={config.id}>
                      
                      <td style={{ verticalAlign: "middle" }}>
                        {config?.name}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        
                        {countries?.results?.filter((country) => String(country.url).split("/")[String(country.url).split("/").length-2] == config.country)[0]?.name}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <button
                        onClick={() => handleEdit(config)}
                          className="btn btn-default p-1 mr-3"
                          style={{ height: "30px", marginRight: "5px" }}

                        >
                          <img
                            src="/assets/imgs/page/dashboard/edit.svg"
                            alt=""
                          />
                        </button>
                        <button
                        onClick={() => confirmDeletion(URLS.CONFIGS.PROFILE.COLLEGE.update(config.id), t, onSuccess)}
                          className="btn btn-default p-1"
                          style={{ height: "30px" }}
                        >
                            <span className="d-flex" style={{}}>

                          <LuTrash />
                            </span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <div className="panel-white mb-30">
        <div className="p-3">
          <div className="paginations">
                  <ul className="pager">
                  
                  <li>
                    <Link
                        tabIndex={-1}
                        onClick={(event) => handleChangePage(event, page - 1)} className={`pager-prev ${page === 1 ? "disabled" : ""}`} to="#"></Link>
                  </li>
                  {data && new Array(Math.ceil(data.count / DEFAULT_PAGE_COUNT))
                    .fill(3)
                    .map((pag, index) => (
                      <li
                        key={"page" + index}
                        className={` ${
                          page === index + 1 ? "active" : ""
                        }`}
                        aria-current="page"
                      >
                        <Link
                          className={`pager-number ${
                            page * DEFAULT_PAGE_COUNT === Math.ceil(data.count )
                              ? "disabled"
                              : ""
                          } ${
                            page === index + 1 ? "active" : ""
                          }`}
                          to="#"
                          tabIndex={-1}
                          onClick={(event) =>
                            handleChangePage(event, index + 1)
                          }
                        >
                          {index + 1}
                        </Link>
                      </li>
                    ))}
                       <li>
                       <Link
                        tabIndex={-1} className={`pager-next ${page === 1 ? "disabled" : ""}`} to="#"></Link>
                     </li>
                </ul>
                  </div>
        </div>
      </div>
    </div>
  );
};

export default CollegeList;
