import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { downloadExcel } from 'react-export-table-to-excel';
import DataTable from 'react-data-table-component';
import { URLS } from "../../../../../services/urls";
import { API } from "../../../../../services/axios";


const CvTable = ({ profiles, handleDownloadResult }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState([]);

  const navigate = useNavigate();
  const { jobId } = useParams()

  // Data table columns
  const columns = [
    {
      name: t("postTitle"),
      selector: row => row?.primary_role,
    },
    {
      name: t("textFullname"),
      selector: row => row?.user?.first_name + " " + row?.user?.last_name,
    },
    {
      name: t("textSalary") + " (XAF)",
      selector: row => row?.salary_min + " - " + row?.salary_max,
    },
    {
      name: t("textActions"),
      selector: row => <Link className="btn btn-link" to={`/cv-theque/${row?.id}/details`}>{t("textDetails")}</Link>,
    },
  ];



  const tableRef = useRef(null);

  const tableHeader = [
    "Email",
    "Noms",
    "Contact",
    "Genre",
    "Age",
    "Pays de residence",
    "Langue principale",
    "Groupe competence",
    "Lien CV",
    "Annee d'obtention dernier diplome",
    "Dernier diplome",
    "Experience professionnelle",
  ]

  const onDownload = () => {

    const id = toast.loading(t("textPreparingDonwload"))

    let toExport = []

    try {
      
      selected.forEach(async (element) => {
        const profile = await API.get(URLS.USERS.PROFILE.details(element?.id)).then((resp => resp.data)).catch((err) => undefined)
        if(profile){
          
          toExport = [
            ...toExport,
            [
              profile?.user?.email,
              profile?.user?.first_name + " " + profile?.user?.last_name,
              profile?.user?.phone_number ?? "",
              profile?.user?.gender,
              profile?.user?.age,
              profile?.user?.country?.name,
              profile?.user?.language,
              profile?.primary_role,
              profile?.cv,
              profile?.education[0]?.end_date ?? "",
              profile?.education[0]?.degree?.name,
              profile?.experience[0]?.level
              // profile?.experience
            ]
          ]
          if(toExport.length === selected.length){
  
            console.log(toExport);
  
            downloadExcel({
              fileName: t("textcvTheque"),
              sheet: t("textProfiles"),
              tablePayload: {
                header: tableHeader,
                // accept two different data structures
                body: toExport,
              },
            });
  
            toast.update(id, {render: t("textDownloadSuccess"), type: "success", isLoading: false, autoClose:true});
          }
        }
      });
      if(toExport.length > 0) {
      }
    } catch (error) {
      
      toast.update(id, {render: error?.response?.data?.detail ?? error?.response?.data?.message ?? t("textDownloadFailed"), type: "error", isLoading: false, autoClose:true});
    }

  }

  const handleToggleCheck = (application) => {
    if(selected.filter(app => app.id === application.id).length > 0){
        setSelected((prev) => prev.filter((i) => i.id !== application.id))
    } else {
        setSelected((prev) => [...prev, application])
    }
  }

  const handleCancelApplications = () => {
    selected.forEach((application) => {
        const id = toast.loading(t("textCancelingApplication"));
        API.post(URLS.JOBS.APPLICATIONS.cancel(application.id))
      .then((resp) => {
        toast.update(id, { render: t("textApplicationCanceled"), type: "success", isLoading: false, autoClose:true});
      })
      .catch((e) => {
        toast.update(id, { render: e?.response?.data?.detail ?? t("textApplicationCancelingError"), type: "error", isLoading: false, autoClose:true});
      });
    })
  }

  const handleDetails = (profile) => {
    navigate(`/jobs/${jobId}/applications/${profile?.id}/cv/${profile?.user?.id}/details`)
  }


  return (
    <div>
        {
            <div className="d-flex">
                {selected.length > 0 && <p>
                    {selected.length} {" "} {t("textSelectedItems")}{selected.length > 1 ? 's' : ''}
                </p>} 
                <div className="ml-auto">
                    <button className="btn btn-secondary mr-2" onClick={handleDownloadResult}>
                        {t("textExportResult")}
                    </button>
                    {selected.length > 0 && <button className="btn btn-secondary" onClick={onDownload}>
                        {t("textExportSelected")}
                    </button>
                    }
                </div>
            </div>
        }

        {
          profiles?.length >0 &&  <DataTable
          onSelectedRowsChange={({selectedRows}) => setSelected(selectedRows)}
          columns={columns}
          data={profiles}
          selectableRows
          // onRowClicked={(profile) => navigate(`/cv-theque/${profile?.id}/details`)}
      />
      }
    </div>
  );
};

export default CvTable;
