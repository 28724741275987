export const fr = {
    textWaitingValidate: "En attente de validation",
    textwidintercv: "le nombre de cv sur la période indiquée dans le calendrier",
    textwidinteruser: "le nombre d'utilisateurs sur la période indiquée dans le calendrier",
    textwidinterrecruiter: "le nombre de sociétés sur la période indiquée dans le calendrier",
    textwidinterjob: "le nombre d'emploi sur la période indiquée dans le calendrier",
    textwidinterjobfound: "le nombre d'emplois trouvé sur la période indiquée dans le calendrier",
    textwidinterviews: "le nombre d'entretiens sur la période indiquée dans le calendrier",
    textwidinterarticle: "le nombre d'articles sur la période indiquée dans le calendrier",
    textwidinterapplication: "le nombre de candidats sur la période indiquée dans le calendrier",
    textLoginingOut: "Déconnexion",
    passMustMatch: "Les mots de passes doivent correspondre",
    required: "Requis",
    noPassProvided: "Aucun mot de passe fournis.",
    passToShort: "Mot de passe trop court - doit être de * caractéres au minimum.",
    passOnlyLatinLetters: "Le mot de passe contient Uniquement des caratéres de l'aphabet latine.",
    startForFree: "Commencez Gratuitement",
    acessAllFeatures: "Access à toutes les fonctionnalités. Carte de crédit non requise.",
    firstName: "Nom",
    lastName: "Prom",
    email: "Email",
    password: "Mot de passe",
    confirmPassword: "Confirmer Votre mot de passe",
    agreeTerms: "Accepter nos termes et conditions",
    learnMore: "En savoir plus",
    submitNRegister: "Valider et enregistrer",
    alreadyHaveAccount: "Avez-vous un compte?",
    signIn: "S'authentifier",
    rememberMe: "Se souvenir de moi",
    forgotPassword: "Mot de passe oublié?",
    actionLogin: "S'authentifier",
    dontHaveAccount: "Don't have an Account?",
    signUp: "S'enregistrer",
    phoneNumber: "Numéro de téléphone",
    emailValidationError: "Erreur de validation de l'adresse Email;",
    myProfiles: "Mes profiles",
    menu_user: "Utilisateurs",
    addNew: "Ajouter",
    updateYourCV: "Mettre à jour mon CV",
    uploadFile: "Uploader un Fichier",
    buttonDelete: "Supprimer",
    postTitle: "Post title",
    minSalary: "Salaire Minimum",
    maxSalary: "Salaire Maximum",
    userBio: "Description",
    btnContinue: "Continuer",
    profileSkills: "Competences",
    btnUpdate: "Mettre à jour",
    titleEducation: "Formations",
    educationFrom: "De",
    educationTo: "A",
    educationDegree: "diplome",
    educationSchool: "Etablissement",
    educationGpa: "Note Obtenue",
    educationfieldOfStudy: "Domaine d'étude",
    educationCollege: "Etablissement",
    currentlyWorking: "Actuellement poste",
    currentlyStudying: "Formation en cours",
    currentlyShort: "Present",
    addTimeline: "Ajouter Timeline",
    educationAdded: "Formation ajoutéé avec succèss",
    menuHome: "Acceuil",
    myResume: "Mon CV",
    editTimeline: "Editer ma Timeline",
    educationEdited: "Formation modifée avec succès",
    deletionAreYouSure: 'Etes-vous sûr?',
    deletionIrreversibleAlert: "Vous ne pourrez pas revenir en arrière!",
    deletionActionButton: "Oui, Supprimer!",
    deletionDeleted: "Supprimé!",
    deletionSuccessMessage: 'Suppression éffectuée.',
    deletionActionCancel: "Annuler",
    deletionProcess: "Suppression en cours",
    requestGlobalErrorMessage: "Une Erreur s'est produite",
    experienceCompany: "Entreprise",
    textDescrption: "Description",
    textResponsabilities: "Responsabilités",
    workExperience: "Emplois & Experience Professionnelle",
    experienceAdded: "Experience Professionnelle ajoutée",
    experienceEdited: "Experience Professionnelle modifiée",
    experienceSector: "Secteur d'activité",
    markCvDefault: "Marquer comme CV par défaut",
    deleteButtonText: "Supprimer",
    introEditedSuccessfuly: "Profile modifié",
    postAJob: "Publier un Emploi",
    textAdmin: "Admin",
    postNewJob: "Publier l'offre",
    jobInformations: "Informations du Job",
    jobTitle: "Titre de l'Emploi",
    jobType: "Type",
    workplaceType: "Lieu de Travail",
    workingTypePart: "Part time",
    workingTypeFull: "Full",
    workingTypeRemote: "Remote",
    workingTypeFreelancer: "Freelance",
    jobExperienceYear: "Nombre d'années d'expérience",
    jobExperienceLevel: "Niveau d'Experience",
    jobExpEntry: "Débutant",
    jobExpInterm: "Intermediaire",
    jobExpSenior: "Senior",
    jobExpExpert: "Expert",
    jobMinSalary: "Salaire Min.",
    jobMaxSalary: "Salaire Max.",
    jobWorkPlaceOnsite: "Sur site",
    jobWorkPlaceRemote: "En Remote",
    jobWorkPlaceHybrid: "En Hybride",
    jobApplicationEnd: "Date de clôture",
    jobVisaSponsored: "Visa Sponsorisé",
    jobDescription: "Description",
    jobResponsabilities: "Responsabilités",
    jobSpecialNote: "Note spéciale à l'endoit du candidat",
    jobIndustry: "Industrie",
    jobSector: "Secteur D'activité",
    jobCompany: "Entreprise recrutant",
    jobSkills: "Compétences",
    textJobs: "Jobs",
    jobCountry: "Pays",
    jobCity: "Ville",
    jobProcess: "Processus de recrutement (Separatés des virgules)",
    jobIncludePertners: "Admin en charge",
    textJobDetails: "Details du Job",
    textJobApplications: "Candidatures au Job",
    textCvTheque: "Cv Theque",
    textProfileDetails: "Détails du Profil",
    textDownloadCv: "Télécharger le CV",
    textProfileSteps: "Etape du profil",
    textShortBio: "Courte Descrption",
    textSkills: "Compétences",
    textskills: "Compétences",
    textAboutMe: "A propos de Moi",
    textOverview: "Aperçu",
    textExpectedSalary: "Prétention Salariale",
    textLinkedIn: "LinkedIn",
    textPhone: "Téléphone",
    textEmail: "Email",
    buttonSendMessage: "Envoyé le Message",
    textMyProfile: "Mon Profil",
    textUpdateProfile: "Mettre à jour mon profil",
    textUploadAvatar: "Uploader un Avatar",
    textFirstName: "Nom",
    textLastName: "Prénom",
    textPhoneNumber: "Numéro de téléphone",
    textLanguage: "Langue",
    buttonSaveChange: "Enregistrer les modifications",
    textContactInformation: "Information de Contacte",
    textLngFrench: "Français",
    textLngEnglish: "Anglais",
    textDashboard: "Dashboard",
    textLogout: "Deconnexion",
    textMyProfile: "Mon Profil",
    textcvTheque: "CV Theque",
    textJobs: "Offres d'emploi",
    jobWorkPlace: "Mode de travail",
    jobApplicationStarts: "Début de la candidature",
    textUploadImage: "Uploader l'Image",
    textJobCreated: "Offre crée !",
    textJobEdited: "Offre modifiée !",
    textMonth: "Moi",
    textJobDetails: "Détails de l'offre",
    textEditThisJob: "Editer cette Offre",
    textDelete: "Supprimer",
    textViewApplications: "Voir les candidatures",
    textEmploymentInfo: "Employment Information",
    textIndustry: "Secteur d'activité",
    textindustry: "Secteur d'activité",
    textJobLevel: "Job level",
    textSalary: "Salaire",
    textExperience: "Experience",
    textYears: "Années",
    textJobType: "Type d'offre",
    textCreated: "Crée",
    textDeadline: "Date limite",
    textLocation: "Lieux",
    textApplyNow: "Postuler",
    textShareJob: "Partager",
    textEmail: "Email",
    textJobDetails: "Détails de l'Offre",
    textSkills: "Compétences",
    textDescrption: "Description",
    textCandidatures: "Candidatures",
    textApplicationsReceived: "Candidatures reçues pour cet offre",
    textSearch: "Rechercher",
    textApplications: "Candidatures",
    textDescription: "Description",
    textSteps: "Etapes",
    tetSkills: "Compétences",
    textJObDetails: "Détails de l'Offre",
    texIsActive: "Actif",
    textNewJob: "Publier l'offre",
    textEditJob: "Modifier l'offre",
    textStatus: "Status",
    text_sent: "Envoyé",
    text_in_progress: "En cours",
    text_interview: "Interview",
    text_test: "Test",
    text_hr: "RH",
    text_human_ressources: "Ressources Humaines",
    text_rejected: "Rejectée",
    text_validated: "Validatée",
    text_waiting_user: "En attente de votre confirmation",
    text_accepted: "Acceptée",
    text_canceled: "Annulée",
    textNoItemFound: "Aucun élément trouvé",
    txtConfig: "Configurations",
    textIndustries: "Secteurs d'activités",
    textCompanies: "Entreprises",
    textcompanies: "Entreprises",
    textCollege: "Etablissement Scolaires",
    textcollege: "Etablissement Scolaires",
    text_industry: "Secteur d'activité",
    textLogo: "Logo",
    textIcon: "Icône",
    textName: "Nom",
    textDescription: "Description",
    textAddNew: "+ Ajouter",
    textUploadLogoImage: "Uploader le logo",
    textUploadIconImage: "Uploader l'icon",
    textConfigCreatedSuccess: "Configuration ajoutée!",
    textConfigCreatedFailed: "Erreur lors de l'qajout de la configuration ",
    textConfigEditionFailed: "Erreur lors de la modification de la configuration ",
    textConfigEditionSuccess: "Configuration edité !",
    textCompanyUrl: "URL de l'entreprise",
    textPartner: "Partenaires",
    textCountry: "Pays",
    textAdvance: "Avancer",
    textCancel: "Annuler",
    textAdvanced: "Profil Advancé",
    textAdvancingError: "Impossible de faire avancer le profil",
    textApplicationCanceled: "La candidature a été annulée avec succèss",
    textApplicationCancelingError: "Erreur lors de l'annulation de la Candidature.",
    textConfigCreatedError: "Erreur de configuration",
    textFilters: "Filtres",
    textMinAge: "Age Min",
    textMaxAge: "Age Max",
    textFilterWorking: "Employé",
    textNotWorking: "Sans enploie",
    textTrainingLevelType: "Niveau et type de formation",
    textxpCategory: "Catégorie d'experience",
    textSubscriptionDate: "Date D'inscription",
    textAdminArea: "Admin area",
    textPostJob: "Créer une offre",
    textProfileEditedSuccess: "Profil modifié",
    textApplicationStatus: "Status de la candidature",
    textStatusChoose: "Choisir un status",
    textTrainingLevelTypeChoose: "Choisir un domaine de formation",
    textChoose: "Choisir",
    textEntry: "Débutant",
    textIntermediate: "Intermediaire",
    textSenior: "Senior",
    textExpert: "Expert",
    textResetForm: "Reinitialiser les Filtres",
    textLatestJobs: "Offres rescentes",
    textInterviewSchedules: "Programmation des interviews",
    textArticlesAdded: "Articles Ajoutés",
    textCVAdded: "CV Ajoutés",
    textThisMonth: "Ce Mois",
    textApplication: "Candidatures",
    textUsers: "Utilisateurs",
    textOurProcess: "Notre Process",
    textNoJobFound: "Aucune Offre trouveé",
    textAll: "Tout",
    textLogin: "S'authentifier",
    textRegister: "S'enregistrer",
    textSignIn: "S'authentifier",
    textNoJob: "Aucune Offre trouveé",
    textLoginError: "Impossible de s'authentier avec ces identifiants.",
    textWelcomeBack: "Bienvenue!",
    textAtMost: "L'address email doit contenir au moins 50 caractères",
    textValidEmail: "L'adresse Email doit être valide",
    textEmailNotFound: "Email introuvable",
    textResetPw: "Reinitialiser le mot de passe",
    textRecoverPwEmailSend: "Email de Réinitialisation envoyé.",
    textConfirmError: "Echec de la réinitialialisation du mot de passe",
    textPwMustMatch: "Vos mots de passes ne correspondent pas",
    textSetNewPassword: "Définir un nouveau mot de passe",
    textNoNotifs: "Aucune notification",
    textNotifications: "Notifications",
    textTitle: "Titre",
    textContent: "Contenue",
    textNotificationsDetails: "Details de la Notification",
    textNotifDetailsError: "Impossible d'afficher la notification, reéssayer plus tard",
    textProfileEditedError: "Erreur lors de la modification du profil",
    textUploadLogo: "Uploader le logo",
    textEditIndustry: "Modification du Secteur",
    textInvalidUrl: "URL Invalide",
    textDiploms: "Diplômes",
    textdiploms: "Diplômes",
    textMessages: "Messages",
    textmessages: "Messages",
    text_skills: "Competances",
    textEdit: "Modifier",
    text_diploms: "Diplomes",
    text_messages: "Messages",
    textCompany: "Entreprises",
    textAvailablePosts: "Places disponibles",

    textProfiles: "Profils",
    textRecommendedJobs: "Emplois Recommendés",
    textTopRecruitment: "Top recruitments",
    textApplicationDetails: "Détails de la candidature",
    textHistory: "Historiques",
    textApplicationInformations: "Détails de l'offre",
    textAppliedOn: "Dâte de Soumission",
    textIsActive: "Actif",
    textYes: "Oui",
    textNo: "Non",
    textStep: "Etape",
    textJob: "Offre",
    textPreviousStep: "Etape précédente",
    textApplicationNotFound: "Candidature non trouvée",
    textActions: "Actions",
    textCancelApplication: "Annuler ma candidature",
    textAcceptJob: "Accepter l'offre",
    textYesCancel: "Oui, annuler",
    textApplicationCancelError: "Erreur lors de l'annulation de la candidature",
    textApplicationCancelled: "Candidature annulée !",
    textApplicationAccepted: "Candidarure acceptée",
    textApplicationAcceptationError: "Erreur de validation de la candidature",
    textIntroduction: "Introduction",
    textCertifications: "Certifications",
    textLanguages: "Langues",
    textReferences: "References",
    textAddEducation: "Ajouter une for,ation",
    textEditEducation: "Modifier la formation",
    textPrevious: "Précédent",
    textNext: "Suivant",
    textAddExperience: "Ajouter une experience professionnelle",
    textEditExperience: "Modifier l'experoence",
    textCertifName: "Titre de la Certification",
    textDate: "Dâte",
    textCertifAdded: "Certification ajoutée",
    textCertifAddingError: "Erreur de créaion de la Certification",
    textAddCertification: "Ajouter une certification",
    textEditCertification: "Modifier certification",
    textJoinedFile: "Fichiers joints",
    textLangName: "Langue",
    textLevel: "Niveau",
    textAddLanguage: "Ajouter une langue",
    textEditLanguage: "Modifier la langue",
    textLanguageAddingError: "Erreur de modification de la langue !",
    languageEdited: "Langue mofifiée",
    textLanguageAdded: "Langue ajoutée",
    textName: "Nom",
    textAddReference: "Ajouter une reference",
    textEditReference: "Modifier la reference",
    textReferenceAddingError: "Erreur lors de l'ajout de la reférence !",
    referenceEdited: "Reference Ajoutée",
    textReferenceAdded: "Reference Ajoutée",
    textViewResumes: "Afficher mon Resumé",
    textCloseOffer: "Fermer l'offre",
    textCloseApplications: "Fermer l'offre",
    textReoponJob: "Reouvrir",
    textTerminateJob: "Cloturer",
    textJobAdvanced: "Offre Avancée!",
    textJobAdvancedError: "Erreur! Impossible d'avance l'offre",
    textJobClosed: "Offre fermée!",
    textJobClosedError: "Erreur de fermeture de l'offre!",
    textJobReopened: "Emploi reouvert avec success!",
    textJobReopenError: "Erreur lors de l'ouverture de l'Emploi!",
    textJobTerminated: "Offre cloturée!",
    textJobTerminationError: "Impossible de cloturer l'offre!",
    textNberPlaces: "Places disponibles",
    textNberAppl: "Candidatures",
    textGender: "Sexe",
    textAge: "Age",
    textChoose: "choisir une option",
    textMale: "Homme",
    textFemale: "Femme",
    textMale: "Homme",
    textXtraDataMessage: "Remplissez cette section si l'offre requière des informations supplémentaires de l'utilisateur.",
    textAddEntry: "Ajouter un nouveau Champ",
    textType: "Type",
    textRequired: "Requis",
    textLabel: "Label",
    textYesNo: "Oui ou Non",
    textTrueFalse: "Vrai or Faux",
    textNumber: "Nombre",
    textCharacters: "Texte",
    textEditAJob: "Modifier l'offre",
    textSeperateSkills: "Pour ajouter plusieurs aptitudes, separez les des virgules (,)",
    textFullname: "Nom",
    textExport: "Exporter",
    textSelectedItems: "Selectionner un element",
    textCancelingApplication: "Annulation de la candidature... ",
    textCustomFilters: "Filtres personnalisés",
    text_messagess: "Messages",
    textMessage: "Message",
    textChoosePeriod: "Selectionner une période",
    textSubject: "Sujet",
    text_submition: "Candidature Recue",
    text_rejection: "Reject d'une Candidature",
    text_validation: "Validation d'une vandidature",
    text_reminder: "Rappel",
    text_newjob: "Publication d'un Nouvel Offre",
    rejection: "Reject d'une Candidature",
    validation: "Validation d'une vandidature",
    reminder: "Rappel",
    submition: "Candidature Recue",
    newjob: "Publication d'un Nouvel Offre",
    textSalaire: "Salaire",
    textExportResult: "Exporter le résultat",
    textExportSelected: "Exporter la sélection",
    textDetails: "Détails",
    textSeperateDiploms: "Pour ajouter plusieurs diplômes, veuillez les séparer par une virgule (,)",
    textJobEditedFailed: "Échec de l'édition du travail",
    textJobCreationFailed: "La création de la tâche a échoué",
    textPreparingDonwload : "Préparation de l'export",
    textDownloadSuccess: "Exportation réussie",
    textDownloadFailed: "Échec de l'exportation",
    jobFound: "Emplois trouvés",
    contractType: "Type de contrat",
    enterShip: "Stage",
    interim: "Intérimaire",
    cdd: "CDD",
    cdi: "CDI",
    profession: "Metiers",
    textWaitingUser: "En Attente de validation",
    textDisplayAll: "Tout afficher",
    textAdvance2: "Avancer les selections",
    textArchived:"Candidatures archivées",
    textUnArchived:"Offre retiré de l'archive",
    textUnArchivedError: "Erreur lors du désarchivage",
    textArchivedBtn:"Restaurer",
    textAppDate: "Date de soumission",
    textDegreeChoose: "Choisir un diplome",
    textProfessionChoose: "Choisir un metier",
    year: "Annee",
    month: "Month",
    chooseYear: "Choisir ..",
    jan: "Janvier",
    feb: "Fevrier",
    mar: "Mars",
    apr: "Avril",
    may: "Mai",
    jun: "Juin",
    jul: "Juillet",
    aug: "Aout",
    sep: "Septembre",
    nov: "Novembre",
    dec: "Decembre",
    selectSkill: "Selectionner une competence",
    selectCompany: "Choisir une Company",
    selectIndustry: "Choisir une industrie",
    textAcceptedError: "Impossible de valider la demande",
    textAccept: "Accept",
    textAccepted: "Demande Acceptee",
    textRequestedDiploma: "Diplôme requis",
    textprofessions: "Professions",
    textImageFormat: "Format du fichier",
    textImageSize: "Taille maximale",
    textJobValidationButton: "Valider l'offre",
    textWaitingValidationMsg: "En Attente de Validation par l'Administrateur",
    textJobValidationSuccess: "Offre validée avec Succès",
    textJobValidationError: "Erreur lors de la Validation de l'Offer, Veuillez reessayer plus tard"
};
