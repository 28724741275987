import { Link } from "react-router-dom";
import JobCardList from "../../../../components/jobs/JobCardList";
import AuthFooter from "../../components/footer";
import useSWR from "swr";
import { URLS } from "../../../../services/urls";
import { useEffect, useState } from "react";
import { DEFAULT_PAGE_COUNT } from "../../../../components/utils/consts";
import { styles } from "../../../../components/utils/reactSelect";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import NotValidatedJobs from "./components/not-validated";
import { API } from "../../../../services/axios";
const invalidJobsFetcher = (url) => API.get(url).then(resp=>{
  console.log("\t\t Job listing\n\n")
  let jbs = []
  resp.data.results.forEach((item)=>{
    if(!item.validated){
      jbs.push(item);
    }
  });
  console.log("\t\t Job list\n\n")
  console.log(jbs)
  console.log("\t\t end List \n\n")
  return jbs;
})
const JobsList = () => {
  const {t} = useTranslation();
  const today = new Date()
  const [page, setPage] = useState(1);
  const [skill, setSkill] = useState();
  const [extra, setExtra] = useState("");
  const [search, setSearch] = useState("");
  const [industry, setIndustry] = useState();
  const [company, setCompany] = useState();
  const [searchText, setSearchText] = useState("");
  const [searchSkills, setSearchSkills] = useState("");
  const [contractType, setContractType] = useState("");
  const [experienceLevel, setExperienceLevel] = useState("");
  const [profession, setProfession] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [notValid, setNotValid] = useState(false);

  const TEXT_SKILLS = t("textSkills");

  const { data: invalidJobs, isLoading2 } = useSWR(URLS.JOBS.listInvalid());
  const { data: jobs, isLoading } = useSWR(URLS.JOBS.list(search, page, extra));
  // const { data: invalidJobs, isLoading2 } = useSWR(
  //   URLS.JOBS.list(search, page, extra),
  //   invalidJobsFetcher
  // );
  const { data: industries, loadingIndustries } = useSWR(
    URLS.CONFIGS.PROFILE.SECTOR.list(search)
    );
  const { data: skills, loadingSkills } = useSWR(
    URLS.CONFIGS.PROFILE.SKILLS.list(searchSkills)
    );
  const { data: companies, loadingCompanies } = useSWR(
    URLS.CONFIGS.PROFILE.COMPANY.list(search)
    );
    
    const { data: professionList, isLoading: loadingProfessionList } = useSWR(
      URLS.CONFIGS.PROFILE.PROFESSION.list("")
    );

    const handleChangePage = (event, pg) => {
      event.preventDefault();
      setPage(pg);
    };
    const handleSearch = () => {
      let extraParam = ""
      if(searchText){
        setSearch(searchText);
      }
      if(industry && industry !== "ALL") {
        extraParam = extraParam + "&industry__id=" + industry
      }
      if(contractType) {
        extraParam = extraParam + "&contract_type=" + contractType
      }
      if(experienceLevel) {
        extraParam = extraParam + "&experience_level=" + experienceLevel
      }
      if(skill) {
        extraParam = extraParam + "&skills__name__contains=" + skill
      }
      if(company) {
        extraParam = extraParam + "&client__id=" + company
      }
      if(year) {
        extraParam = extraParam + "&created_at__year=" + Number(year)
      }
      if(month) {
        extraParam = extraParam + "&created_at__month=" + Number(month)
      }if (profession) {
        extraParam =
        extraParam + "&profession__id=" + profession;
      }

      if(extraParam){
        setExtra(extraParam);
        console.log(extraParam)
      }
    }
    const handleReset = () => {
      setSearchText("");
      setExtra("");

      setExperienceLevel("");
      setContractType("");
      setProfession("");
      setSkill("");
      setSearch("");
      setYear("");
      setMonth("")
      setCompany("");
      setIndustry("")
    }
  return (
    <div className="box-content">
      <div className="box-heading">
        <div className="box-title">
          <h3 className="mb-35">{t("textJobs")}</h3>
        </div>
        <div className="box-breadcrumb">
          <div className="breadcrumbs">
            <ul>
              <li>
                {" "}
                <Link className="icon-home" to="/">
                  {t("textDashboard")}
                </Link>
              </li>
              <li>
                <span>{t("textJobs")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-9 col-md-8 col-12">
          <div className="section-box">
            <div className="container">
              <div className="panel-white mb-30">
                <div className="box-padding">
                  <div className="row display-list">
                    {(isLoading || isLoading2) && (
                      <div className="col-12 mb-11 d-flex">
                        <span
                          className="spinner-border m-auto"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </div>
                    )}
                    {!(isLoading || isLoading2) && jobs?.count === 0 && (
                      <p className="text-center"> {t("textNoJobFound")} </p>
                    )}
                    {jobs?.results?.map((job) => (
                      <>{job?.validated &&
                      <div key={job.slug} className="col-lg-6">
                        <JobCardList job={job} />
                      </div>}</>
                    ))}
                  </div>
                  <div className="paginations">
                    <ul className="pager">
                      <li>
                        <Link
                          tabIndex={-1}
                          onClick={(event) => handleChangePage(event, page - 1)}
                          className={`pager-prev ${
                            page === 1 ? "disabled" : ""
                          }`}
                          to="#"
                        ></Link>
                      </li>
                      {jobs &&
                        new Array(Math.ceil(jobs.count / DEFAULT_PAGE_COUNT))
                          .fill(3)
                          .map((pag, index) => (
                            <li
                              key={"page" + index}
                              className={` ${
                                page === index + 1 ? "active" : ""
                              }`}
                              aria-current="page"
                            >
                              <Link
                                className={`pager-number ${
                                  page * DEFAULT_PAGE_COUNT ===
                                  Math.ceil(jobs.count)
                                    ? "disabled"
                                    : ""
                                } ${page === index + 1 ? "active" : ""}`}
                                to="#"
                                tabIndex={-1}
                                onClick={(event) =>
                                  handleChangePage(event, index + 1)
                                }
                              >
                                {index + 1}
                              </Link>
                            </li>
                          ))}
                      <li>
                        <Link
                          tabIndex={-1}
                          className={`pager-next ${
                            page === 1 ? "disabled" : ""
                          }`}
                          to="#"
                        ></Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(!isLoading2 && invalidJobs?.length!==0) &&
            <NotValidatedJobs jobs={invalidJobs}/>
          }
        </div>
        <div className="col-lg-3 col-md-3 col-12">
          <div className="section-box">
            <div className="panel-white p-16">
              <h4 className="mb-15">{t("textFilters")}</h4>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    id="search"
                    className="form-control"
                    onChange={(e) => setSearchText(e.target.value)}
                    type="text"
                    value={searchText}
                    placeholder={t("textSearch")}
                  />
                  <label htmlFor="search">{t("textSearch")}</label>
                </div>
              </div>
              <div className="mb-3">
                <div className="row">
                  <div className="col-6">
                    <div className="form-floating mb-3">
                      <select
                      className="form-select"
                      aria-label={t("year")}
                      onChange={(e) => setYear(e.target.value)}
                      value={year}
                    >
                        <option value="" disabled>{t("chooseYear")}</option>
                        <option value={today.getFullYear()}>{today.getFullYear()}</option>
                        <option value={today.getFullYear()-1}>{today.getFullYear()-1}</option>
                        <option value={today.getFullYear()-2}>{today.getFullYear()-2}</option></select>
                      <label htmlFor="chooseYear">{t("year")}</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-floating mb-3">
                      <select
                      className="form-select"
                      aria-label={t("month")}
                      onChange={(e) => setMonth(e.target.value)}
                      value={month}
                    >
                      <option value="" disabled>{t("chooseYear")}</option>
                        <option value={1}>{t("jan")}</option>
                        <option value={2}>{t("feb")}</option>
                        <option value={3}>{t("mar")}</option>
                        <option value={4}>{t("apr")}</option>
                        <option value={5}>{t("may")}</option>
                        <option value={6}>{t("jun")}</option>
                        <option value={7}>{t("jul")}</option>
                        <option value={8}>{t("aug")}</option>
                        <option value={9}>{t("sep")}</option>
                        <option value={10}>{t("oct")}</option>
                        <option value={11}>{t("nov")}</option>
                        <option value={12}>{t("dec")}</option>
                      </select>
                      <label htmlFor="chooseYear">{t("month")}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                  <div className="form-floating mb-3">
                    <select
                        className="form-select"
                        aria-label={t("month")}
                        onChange={(e) => setSkill(e.target.value)}
                        value={skill}
                      >
                      <option value="">{t("selectSkill")}</option>
                      {skills?.results?.map((sk)=>(<option value={sk.name}>{sk.name}</option>))}
                    </select>
                      <label htmlFor="skill">{t("textSkills")}</label>
                  </div>
                {/* <Select
                  id="job-industry"
                  inputId="select-industry"
                  styles={styles}
                  value={skill}
                  isSearchable
                  isLoading={loadingSkills}
                  onChange={(newValue) => setSkill(newValue)}
                  // onCreateOption={handleCreateIndustry}
                  onInputChange={(val) => setSearchSkills(val)}
                  options={
                    [{label: t("textAll"), value:""}].concat(
                      skills?.results?.map((i) => {
                        return { label: i.name, value: i.id };
                      }) ?? []
                    )
                  }
                /> */}
                {/* <div className="form-floating">
                  <input
                    id="skills"
                    className="form-control"
                    onChange={(e) => setSkill(e.target.value)}
                    type="text"
                    placeholder={t("jobSkills")}
                  />
                  <label htmlFor="skills">{t("jobSkills")}</label>
                </div> */}
              </div>
              <div className="mb-3">
                  <div className="form-floating mb-3">
                    <select
                        className="form-select"
                        aria-label={t("month")}
                        onChange={(e) => setIndustry(e.target.value)}
                        value={industry}
                        id="job-industry"
                      >
                      <option value="">{t("selectIndustry")}</option>
                      {industries?.results?.map((ind)=>(<option value={ind.id}>{ind.name}</option>))}
                    </select>
                      <label htmlFor="skill">{t("textIndustries")}</label>
                  </div>
                {/* <Select
                  id="job-industry"
                  inputId="select-industry"
                  styles={styles}
                  value={industry}
                  isSearchable
                  isLoading={loadingIndustries}
                  onChange={(newValue) => setIndustry(newValue)}
                  // onCreateOption={handleCreateIndustry}
                  options={
                    [{label: t("textAll"), value:""}].concat(
                      industries?.results?.map((i) => {
                        return { label: i.name, value: i.id };
                      }) ?? []
                    )
                  }
                /> */}
              </div>
              <div className="mb-3">
                  <div className="form-floating mb-3">
                    <select
                        className="form-select"
                        aria-label={t("textCompany")}
                        onChange={(e) => setCompany(e.target.value)}
                        value={company}
                        id="job-company"
                      >
                      <option value="">{t("selectCompany")}</option>
                      {companies?.results?.map((com)=>(<option value={com.id}>{com.name}</option>))}
                    </select>
                      <label htmlFor="skill">{t("textCompany")}</label>
                  </div>
                {/* <Select
                  id="job-company"
                  inputId="select-company"
                  styles={styles}
                  value={company}
                  isSearchable
                  isLoading={loadingCompanies}
                  onChange={(newValue) => setCompany(newValue)}
                  // onCreateOption={handleCreateIndustry}
                  options={
                    [{label: t("textAll"), value:""}].concat(
                      companies?.results?.map((i) => {
                        return { label: i.name, value: i.id };
                      }) ?? []
                    )
                  }
                /> */}
              </div>
              <div className="form-floating mb-3">
                <select
                  className="form-select"
                  id="workPlace"
                  aria-label={t("jobExperienceLevel")}
                  onChange={(e) => setExperienceLevel(e.target.value)}
                  value={experienceLevel}
                >
                  <option value="" disabled>{t("textChoose")}</option>
                  <option value="entry">{t("jobExpEntry")}</option>
                  <option value="intermediate">{t("jobExpInterm")}</option>
                  <option value="senior">{t("jobExpSenior")}</option>
                  <option value="expert">{t("jobExpExpert")}</option>
                </select>
                <label htmlFor="workPlace">{t("jobExperienceLevel")}</label>
              </div>

              <div className="form-floating mb-3">
                <select
                  className="form-select"
                  id="workPlace"
                  aria-label={t("contractType")}
                  onChange={(e) => setContractType(e.target.value)}
                  value={contractType}
                >
                  <option value="" disabled>{t("textChoose")}</option>
                  <option value="cdd">{t("cdd")}</option>
                  <option value="cdi">{t("cdi")}</option>
                  <option value="interim">{t("interim")}</option>
                  <option value="enternship">{t("enternship")}</option>
                </select>
                <label htmlFor="workPlace">{t("contractType")}</label>
              </div>
              <div className="form-floating mb-3">
                <select
                  className="form-select" 
                  id="floatingSelectGrid"
                  aria-label="Floating label select example"
                  onChange={(e) => setProfession(e.target.value)}
                  value={profession}
                >
                  <option value="" disabled>
                    {t("textProfessionChoose")}
                  </option>
                  {professionList?.results?.map((prof)=>(
                    <option value={prof.id}>{prof.name}</option>
                  ))}
                </select>
                <label htmlFor="floatingSelectGrid">
                  {t("profession")}
                </label>
              </div>

              <div className="d-flex mb-3">
                <button
                  onClick={handleSearch}
                  className="btn btn-default w-100"
                >
                  {t("textSearch")}
                  {search && isLoading && (
                    <div
                      className="spinner-border ml-10 spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
              <div className="d-flex">
                <button
                  onClick={handleReset}
                  className="btn w-100 btn-secondary"
                >
                  {t("textResetForm")}
                  
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mt-10">
        <div className="section-box">
          <div className="container">
            <div className="panel-white pt-30 pb-30 pl-15 pr-15">
              <Clients removePadding />
            </div>
          </div>
        </div>
      </div> */}
      <AuthFooter />
    </div>
  );
};

export default JobsList;
