import { getUserDetails, setUserDetails } from '../components/utils/functions';
import { AUTHAPI } from './axios';
import { URLS } from './urls';


export const refreshToken = async () => {
    let token = getUserDetails();
    console.log("Token refreshing...........")
    const newToken = await AUTHAPI.post(URLS.AUTHS.REFRESH, {
        refresh_token: token.refresh_token
    })
        .then((resp) => {
            console.log("Token successfully refreshed");
            token.access_token = resp.data?.access;
            setUserDetails(token);
            return resp.data?.access;
        })
        .catch((error) => {
            console.log("Unable to refresh token")
            window.localStorage.setItem('redirect', window.location.pathname);
            window.localStorage.removeItem('user-details');
            window.location.pathname = '/login';
        });
    return newToken;
};