import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { API } from "../../../../../../services/axios";
import { URLS } from "../../../../../../services/urls";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const MessagesManage = ({ toEdit, onSuccess }) => {
  const { register, reset, handleSubmit } = useForm();
  const { t } = useTranslation();
  const { auth } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const onSubmit = (data) => {
    let toSend = data;
    toSend.content = message;
    toSend.created_by = auth.user.id;
    setLoading(true);
    if (toEdit) {
      API.put(URLS.CONFIGS.PROFILE.EMAIL_MESSAGES.update(toEdit.id), toSend)
        .then((resp) => {
          toast.success(t("textConfigEditionSuccess"));
          setLoading(false);
          onSuccess();
          reset();
          window.$("#manageIndustryForm").modal("hide");
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            error?.response?.data?.detail ??
              error?.response?.data?.message ??
              t("textConfigEditionError")
          );
          setLoading(false);
        });
    } else {
      API.post(URLS.CONFIGS.PROFILE.EMAIL_MESSAGES.create, toSend)
        .then((resp) => {
          toast.success(t("textConfigCreatedSuccess"));
          setLoading(false);
          onSuccess();
          reset();
          window.$("#manageIndustryForm").modal("hide");
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.detail ??
              error?.response?.data?.message ??
              t("textConfigCreatedError")
          );
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (toEdit) {
      setMessage(toEdit?.content);
    }
  }, [toEdit]);

  return (
    <div
      className="modal fade"
      id="manageIndustryForm"
      tabIndex={-1}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content apply-job-form">
          <button
            className="btn-close"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div className="modal-body pl-30 pr-30 pt-50">
            <div className="text-center">
              <p className="font-sm text-brand-2">
                {toEdit ? t("textEdit") : t("textAddNew")}
              </p>
              {/* <h2 className="mt-10 mb-5 text-brand-1 text-capitalize">Start your career today</h2> */}
              {/* <p className="font-sm text-muted mb-30">Please fill in your information and send it to the employer.                   </p> */}
            </div>
            <form
              className="login-register text-start mt-20 pb-30"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-group">
                <label className="form-label" htmlFor="input-1">
                  {t("textStep")} <span className="text-primary">*</span>
                </label>
                <select
                  className="form-control"
                  defaultValue={toEdit?.step}
                  {...register("step", { required: true })}
                >
                  <option value="" disabled>
                    {t("textChoose")}
                  </option>
                  <option value={"submition"}>{t(`text_submition`)}</option>
                  <option value={"rejection"}>{t(`text_rejection`)}</option>
                  <option value={"validation"}>{t(`text_validation`)}</option>
                  <option value={"reminder"}>{t(`text_reminder`)}</option>
                  <option value={"newjob"}>{t(`text_newjob`)}</option>
                  {/* {
                    APPLICATION_STEPS.map((i) => {
                      return(
                        <option value={i}>{t(`text_${i}`)}</option>
                      )
                    })
                  } */}
                </select>
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="input-12">
                  {t("textLanguage")} <span className="text-primary">*</span>
                </label>
                <select
                  className="form-control"
                  defaultValue={toEdit?.language}
                  {...register("language", { required: true })}
                >
                  <option value="" disabled>
                    {t("textChoose")}
                  </option>
                  <option value="fr">{t(`textLngFrench`)}</option>
                  <option value="eng">{t(`textLngEnglish`)}</option>
                </select>
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="input-13">
                  {t("textSubject")} <span className="text-primary">*</span>
                </label>
                <input
                  defaultValue={toEdit?.subject}
                  className="form-control"
                  type="text"
                  {...register("subject", { required: true })}
                />
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="input-13">
                  <span className="text-primary">
                    {t(
                      "instead of the candidate's name, just write username and instead of the job name, write job_title"
                    )}
                  </span>
                </label>
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="des">
                  {t("textMessage")} <span className="text-primary">*</span>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={message}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setMessage(data);
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
              <div className="row">
                {/* <div className="col-md-6">
                        <div>
                            <div className="form-group mb-30">
                              <div className="box-upload">
                                <div className="add-file-upload" style={previewLink ? {background: `#ffffff url(${previewLink})`} : {}}>
                                  <input
                                    className="fileupload"
                                    type="file"
                                    name="file"
                                    id="file"
                                    onChange={handleChangeImage}
                                  />
                                </div>
                                <label htmlFor="file" className="btn btn-default">
                                    {t("textUploadImage")}
                                </label>
                              </div>
                            </div>
                        </div>
                    </div> */}
              </div>
              <div className="form-group">
                <button
                  className="btn btn-default hover-up w-100"
                  type="submit"
                  name="login"
                >
                  {toEdit ? t("textEdit") : t("textAddNew")}
                  {loading && (
                    <div
                      className="spinner-border ml-10 spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
              {/* <div className="text-muted text-center">Do you need support? <a href="page-contact.html">Contact Us</a></div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagesManage;
