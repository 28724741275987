import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { styles } from "../../../../../../components/utils/reactSelect";
import { API } from "../../../../../../services/axios";
import { URLS } from "../../../../../../services/urls";
import CreatableSelect from "react-select/creatable";
import { useEffect } from "react";

const CollegeManage = ({ toEdit, onSuccess, countries }) => {
  const { register, reset, handleSubmit } = useForm();
  const { t } = useTranslation();
  const { auth } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState();

  const onSubmit = (data) => {
    let formData = new FormData();
    formData.append("name", data.name);
    if(country){
      formData.append("country", country.value);
    }
    formData.append("created_by", auth?.user?.id);
    setLoading(true);

    
    if (toEdit) {
      API.put(URLS.CONFIGS.PROFILE.COLLEGE.update(toEdit.id), formData)
        .then((resp) => {
          toast.success(t("textConfigEditionSuccess"));
          setLoading(false);
          onSuccess();
          reset();
          window.$("#manageIndustryForm").modal("hide");
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.detail ?? error?.response?.data?.message ?? t("textConfigEditionError")
          );
          setLoading(false);
        });
    } else {
      API.post(URLS.CONFIGS.PROFILE.COLLEGE.create, formData)
        .then((resp) => {
          toast.success(t("textConfigCreatedSuccess"));
          setLoading(false);
          onSuccess();
          reset()
          window.$("#manageIndustryForm").modal("hide");
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.detail ?? error?.response?.data?.message ?? t("textConfigCreatedError")
          );
          setLoading(false);
        });
    }
  };


  return (
    <div
      className="modal fade"
      id="manageIndustryForm"
      tabIndex={-1}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content apply-job-form">
          <button
            className="btn-close"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div className="modal-body pl-30 pr-30 pt-50">
            <div className="text-center">
              <p className="font-sm text-brand-2">{t("textAddNew")}</p>
              {/* <h2 className="mt-10 mb-5 text-brand-1 text-capitalize">Start your career today</h2> */}
              {/* <p className="font-sm text-muted mb-30">Please fill in your information and send it to the employer.                   </p> */}
            </div>
            <form
              className="login-register text-start mt-20 pb-30"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-group">
                <label className="form-label" htmlFor="input-1">
                  {t("textName")} *
                </label>
                <input
                  className="form-control"
                  id="input-1"
                  defaultValue={toEdit?.name}
                  type="text"
                  {...register("name", { required: true })}
                  placeholder="University of Bamenda"
                />
              </div>
              <div className="form-group">
                <CreatableSelect
                  id="job-country"
                  inputId="select-country"
                  styles={styles}
                  isSearchable
                  value={!country ? {label: toEdit?.country?.name, value: toEdit?.country?.id} : country}
                  onChange={(newValue) => setCountry(newValue)}
                  // onCreateOption={handleCreateIndustry}
                  options={
                    countries?.map((i) => {
                      return {
                        label: i.name,
                        value: String(i.url).split("/")[
                          String(i.url).split("/").length - 2
                        ],
                      };
                    }) ?? []
                  }
                />
              </div>
              <div className="row">
                {/* <div className="col-md-6">
                        <div>
                            <div className="form-group mb-30">
                              <div className="box-upload">
                                <div className="add-file-upload" style={previewLink ? {background: `#ffffff url(${previewLink})`} : {}}>
                                  <input
                                    className="fileupload"
                                    type="file"
                                    name="file"
                                    id="file"
                                    onChange={handleChangeImage}
                                  />
                                </div>
                                <label htmlFor="file" className="btn btn-default">
                                    {t("textUploadImage")}
                                </label>
                              </div>
                            </div>
                        </div>
                    </div> */}
                
              </div>
              <div className="form-group">
                <button
                  className="btn btn-default hover-up w-100"
                  type="submit"
                  name="login"
                >
                  {t("textAddNew")}
                  {loading && (
                    <div
                      className="spinner-border ml-10 spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
              {/* <div className="text-muted text-center">Do you need support? <a href="page-contact.html">Contact Us</a></div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollegeManage;
