import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slices/auth'
import localeReducer from './slices/lannguage'

const store = configureStore({
  reducer: {
    auth: authReducer,
    locale: localeReducer,
  },
})


export {store};
