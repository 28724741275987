import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { API } from "../../../../../../services/axios";
import { URLS } from "../../../../../../services/urls";

const DiplomsManage = ({toEdit, onSuccess}) => {
    const { register, reset, handleSubmit } = useForm();
    const { t } = useTranslation();
    const { auth } = useSelector((state) => state.auth)

    const [loading, setLoading] = useState(false);

    const onSubmit = (data) => {
        let formData = new FormData();
        formData.append("name", data.name);
        formData.append("description", data.description);
        formData.append("created_by", auth?.user?.id);
        setLoading(true);
        if(toEdit){
            API.put(URLS.CONFIGS.PROFILE.DIPLOMA.update(toEdit.id), formData).then((resp) => {
                toast.success(t("textConfigEditionSuccess"));
                setLoading(false);
                onSuccess();
                reset();
                window.$("#manageIndustryForm").modal('hide');
            }).catch((error) => {
                toast.error(error?.response?.data?.detail ?? error?.response?.data?.message ?? t("textConfigEditionError"));
                setLoading(false);
            })
        } else {
          const splittedDiploms = String(data.name).split(',');
          splittedDiploms.forEach((diplom) => {

            if(diplom){

              let formData1 = new FormData();
              formData1.append("name", diplom);
              formData1.append("description", data.description);
              formData1.append("created_by", auth?.user?.id);
  
              API.post(URLS.CONFIGS.PROFILE.DIPLOMA.create, formData1).then((resp) => {
                  toast.success(t("textConfigCreatedSuccess"));
                  setLoading(false);
                  onSuccess()
                  reset();
                  // window.$("#manageIndustryForm").modal('hide');
              }).catch((error) => {
                  toast.error(error?.response?.data?.detail ?? error?.response?.data?.message ?? t("textConfigCreatedError"));
                  setLoading(false);
              })
            }
          })
        }
    }

    return(
        <div className="modal fade" id="manageIndustryForm" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content apply-job-form">
            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="modal-body pl-30 pr-30 pt-50">
              <div className="text-center">
                <p className="font-sm text-brand-2">{toEdit ? t("textEdit") : t("textAddNew")}</p>
                {/* <h2 className="mt-10 mb-5 text-brand-1 text-capitalize">Start your career today</h2> */}
                {/* <p className="font-sm text-muted mb-30">Please fill in your information and send it to the employer.                   </p> */}
              </div>
              <form className="login-register text-start mt-20 pb-30" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label className="form-label" htmlFor="input-1">{t("textName")} *</label>
                  <input className="form-control" id="input-1" defaultValue={toEdit?.name}type="text" {...register("name", {required: true})} placeholder={toEdit ? t("textName") : t("textSeperateDiploms")}/>
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="des">{t("textDescription")}</label>
                  <textarea className="form-control" rows={5} defaultValue={toEdit?.description} {...register("description")} placeholder={t("textDescription")}/>
                </div>
                <div className="row">
                    {/* <div className="col-md-6">
                        <div>
                            <div className="form-group mb-30">
                              <div className="box-upload">
                                <div className="add-file-upload" style={previewLink ? {background: `#ffffff url(${previewLink})`} : {}}>
                                  <input
                                    className="fileupload"
                                    type="file"
                                    name="file"
                                    id="file"
                                    onChange={handleChangeImage}
                                  />
                                </div>
                                <label htmlFor="file" className="btn btn-default">
                                    {t("textUploadImage")}
                                </label>
                              </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="form-group">
                  <button className="btn btn-default hover-up w-100" type="submit" name="login">
                      {t("textAddNew")}
                      {loading && <div
                                  className="spinner-border ml-10 spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>}
                      </button>
                </div>
                {/* <div className="text-muted text-center">Do you need support? <a href="page-contact.html">Contact Us</a></div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    )
}

export default DiplomsManage;