import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IoSchoolOutline } from "react-icons/io5";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { PiHandshake } from "react-icons/pi";
import { MdOutlineEmail, MdOutlineNote } from "react-icons/md";
import { GiSkills, GiDiploma } from "react-icons/gi";

const Configurations = () => {
  const { t } = useTranslation();
  return (
    <div className="box-content">
      <div className="box-heading">
        <div className="box-title">
          <h3 className="mb-35">{t("txtConfig")}</h3>
        </div>
        <div className="box-breadcrumb">
          <div className="breadcrumbs">
            <ul>
              <li>
                {" "}
                <Link className="icon-home" to="/">
                  {t("textDashboard")}
                </Link>
              </li>
              <li>
                <span>{t("txtConfig")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="section-box">
            <div className="container">
              <div className="row">
                <div className="col-md-3 col-sm-4 col-12">
                  <div className=" configuration-item">
                    <Link to="/configurations/industry">
                      <div className="item-logo d-flex">
                        <div className="image-left">
                          <HiOutlineBuildingOffice2 fontSize={45} />
                        </div>
                        <div className="text-info-right d-flex">
                          <h4 className="m-auto">{t("Secteurs")}</h4>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3 col-sm-4 col-12">
                  <div className=" configuration-item">
                    <Link to="/configurations/companies">
                      <div className="item-logo d-flex">
                        <div className="image-left">
                          <PiHandshake fontSize={45} />
                        </div>
                        <div className="text-info-right d-flex">
                          <h4 className="m-auto">{t("textCompanies")}</h4>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3 col-sm-4 col-12">
                  <div className="configuration-item">
                    <Link to="/configurations/college">
                      <div className="item-logo d-flex">
                        <div className="image-left">
                          <IoSchoolOutline fontSize={45} />
                        </div>
                        <div className="text-info-right d-flex">
                          <h4 className="m-auto">{t("textCollege")}</h4>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3 col-sm-4 col-12">
                  <div className="configuration-item">
                    <Link to="/configurations/skills">
                      <div className="item-logo d-flex">
                        <div className="image-left">
                          <GiSkills fontSize={45} />
                        </div>
                        <div className="text-info-right d-flex">
                          <h4 className="m-auto">{t("textSkills")}</h4>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3 col-sm-4 col-12">
                  <div className="configuration-item">
                    <Link to="/configurations/diploms">
                      <div className="item-logo d-flex">
                        <div className="image-left">
                          <GiDiploma fontSize={45} />
                        </div>
                        <div className="text-info-right d-flex">
                          <h4 className="m-auto">{t("textDiploms")}</h4>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3 col-sm-4 col-12">
                  <div className="configuration-item">
                    <Link to="/configurations/messages">
                      <div className="item-logo d-flex">
                        <div className="image-left">
                          <MdOutlineEmail fontSize={45} />
                        </div>
                        <div className="text-info-right d-flex">
                          <h4 className="m-auto">{t("textMessages")}</h4>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3 col-sm-4 col-12">
                  <div className="configuration-item">
                    <Link to="/configurations/professions">
                      <div className="item-logo d-flex">
                        <div className="image-left">
                          <MdOutlineNote fontSize={45} />
                        </div>
                        <div className="text-info-right d-flex">
                          <h4 className="m-auto">{t("profession")}</h4>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Configurations;
