import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { API } from "../../../../../services/axios";
import { URLS } from "../../../../../services/urls";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { downloadExcel } from 'react-export-table-to-excel';
import DataTable from 'react-data-table-component';
import useSWR from "swr";


const ApplicationTable = ({ applications, archived, handleDownloadResult, handleDisplayAll }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState([]);
  const [checked, setChecked] = useState(false);
  const [advancing, setAdvancing] = useState(false);
  console.log(applications)
  const navigate = useNavigate();
  const { jobId } = useParams()
  // console.log(applications)
  // Data table columns
  const {data: job, isLoading: loadingJob} = useSWR(URLS.JOBS.details(jobId))
  const columns = [
    {
      name: t("textFullname"),
      selector: row => row?.user?.user?.first_name + " " + row?.user?.user?.last_name,
    },
    {
      name: t("age"),
      selector: row => row?.user?.user?.age,
    },
    {
      name: t("professsion"),
      selector: row => row?.user?.profession?.name,
    },
    {
      name: t("experience"),
      selector: row => row?.user?.years_experience,
    },
    {
      name: t("textStatus"),
      selector: row => t(`text_${row?.status}`),
    },
    {
      name: t("textActions"),
      selector: row => <Link className="btn btn-link" to={`/jobs/${jobId}/applications/${row?.id}/cv/${row?.user?.id}/details`}>{t("textDetails")}</Link>
    },
  ];



  const tableRef = useRef(null);

  const tableHeader = [
    "Email",
    "Noms",
    "Contact",
    "Genre",
    "Age",
    "Ville de residence",
    "Langue principale",
    "Groupe competence",
    "Lien CV",
    "Annee d'obtention dernier diplome",
    "Dernier diplome",
    "Experience professionnelle",
  ]

  const onDownload = () => {
    const id = toast.loading(t("textPreparingDonwload"))

    let toExport = []

    try {
      // console.log(selected)
      selected.forEach(async (element) => {
        const profile = await API.get(URLS.USERS.PROFILE.details(element?.user?.id)).then((resp => resp.data)).catch((err) => undefined)
        
        if(profile){
          console.log(profile)
          
          toExport = [
            ...toExport,
            [
              profile?.user?.email,
              profile?.user?.first_name + " " + profile?.user?.last_name,
              profile?.user?.phone_number ?? "",
              profile?.user?.gender,
              profile?.user?.age,
              profile?.user?.country?.name,
              profile?.user?.language,
              profile?.primary_role,
              profile?.cv,
              profile?.education[0]?.end_date ?? "",
              profile?.education[0]?.degree?.name,
              profile?.experience[0]?.level
            ]
          ]
          if(toExport.length === selected.length){
  
            // console.log(toExport);
  
            downloadExcel({
              fileName: selected[0]?.offer?.title + " - " + t("textJobApplications"),
              sheet: t("textJobApplications"),
              tablePayload: {
                header: tableHeader,
                // accept two different data structures
                body: toExport,
              },
            });
  
            toast.update(id, {render: t("textDownloadSuccess"), type: "success", isLoading: false, autoClose:true});
          }
        }
      });
      if(toExport.length > 0) {
      }
    } catch (error) {
      toast.update(id, {render: error?.response?.data?.detail ?? error?.response?.data?.message ?? t("textDownloadFailed"), type: "error", isLoading: false, autoClose:true});
    }
  }

  const handleToggleCheck = (application) => {
    if(selected.filter(app => app.id === application.id).length > 0){
        setSelected((prev) => prev.filter((i) => i.id !== application.id))
    } else {
        setSelected((prev) => [...prev, application])
    }
  }

  const handleCancelApplications = () => {
    selected.forEach((application) => {
        const id = toast.loading(t("textCancelingApplication"));
        API.post(URLS.JOBS.APPLICATIONS.cancel(application.id))
      .then((resp) => {
        toast.update(id, { render: t("textApplicationCanceled"), type: "success", isLoading: false, autoClose:true});
      })
      .catch((e) => {
        toast.update(id, { render: e?.response?.data?.detail ?? t("textApplicationCancelingError"), type: "error", isLoading: false, autoClose:true});
      });
    })
  }

  const handleDetails = (profile) => {
    navigate(`/jobs/${jobId}/applications/${profile?.id}/cv/${profile?.user?.id}/details`)
  }
 const handleCheck=()=>{
  console.log(`Previous checked ${checked}`)
  const check=checked;
    setChecked(!checked)
  handleDisplayAll(checked)
 }

const handleUnarchive = () => {
  selected.forEach((element)=>{
    API.post(URLS.JOBS.APPLICATIONS.unarchive(element.id))
    .then((resp)=>{
      toast.success(t("textUnArchived"))
    })
    .catch((e)=>{
      e?.response?.data?.detail ??
      e?.response?.data?.message ??
      t("textUnArchivedError")
    })
  })
}

 const handleAdvance = () => {
  setAdvancing(true);
  selected.forEach((element)=>{
    console.log(element?.id)
    API.post(URLS.JOBS.APPLICATIONS.advance(element?.id))
    .then((resp)=>{
      toast.success(`${t("textAdvanced")}`)
    })
    .catch((e)=>{
      e?.response?.data?.detail ??
      e?.response?.data?.message ??
      t("textAdvancingError")
    })
  })
  setSelected([]);
  setAdvancing(false);
};
  return (
    <div>
        {
          <>
            <div className="d-flex">
                {selected.length > 0 && <p>
                    {selected.length} {" "} {t("textSelectedItems")}{selected.length > 1 ? 's' : ''}
                </p>}
                <div className="ml-auto">
                    {(selected.length && job?.status==="sent")>0 && <button className="btn btn-secondary mr-2" onClick={handleAdvance}>
                      {advancing?(
                        <div
                          className="spinner-border ml-10 spinner-border-sm"
                          role="status"
                        ><span className="visually-hidden">Loading...</span>
                        </div>
                      ):(
                        <>{t("textAdvance2")}</>
                        )}
                    </button>}
                    {(applications?.length>0 && !archived) &&
                    <button className="btn btn-secondary mr-2" onClick={handleDownloadResult}> 
                        {t("textExportResult")}
                    </button>}
                    {(!archived && selected.length > 0) && <button className="btn btn-secondary" onClick={onDownload}>
                        {t("textExportSelected")}
                    </button>
                    }
                    {(selected.length > 0 && applications?.length>0 && !archived) && <button onClick={handleCancelApplications} className="btn btn-primary ml-20">
                        {t("textCancel")}
                    </button>}
                    {(archived && selected.length > 0) && <button className="btn btn-primary ml-2" onClick={handleUnarchive}>{t("textArchivedBtn")}</button>}
                </div>
            </div>
              {/* {applications.length>0 && (
                <div className="login_footer form-group d-flex justify-content-between" style={{float:"left"}}>
                  <label className="cb-container">
                    <input type="checkbox" onChange={handleCheck}/>
                    <span className="text-small text-danger">
                    {t("textDisplayAll")}
                    </span>
                    <span className="checkmark"></span>
                  </label>
                </div>
                )} */}
          </>
        }

        {
          applications?.length >0 &&  <DataTable
          onSelectedRowsChange={({selectedRows}) => setSelected(selectedRows)}
          columns={columns}
          data={applications}
          selectableRows
      />
      }

      {/* {applications?.length >0 && <table ref={tableRef} className="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{t("textFullname")}</th>
            <th scope="col">{t("textJob")}</th>
            <th scope="col">{t("textStatus")}</th>
          </tr>
        </thead>
        <tbody>
          {applications?.map((application) => {
            return (
              <tr className="" key={"application_" + application.id}>
                <th scope="row">
                  <label className="cb-container" style={{ minHeight: "6px" }}>
                    <input type="checkbox" checked={selected.filter(app => app.id === application.id).length > 0} onChange={() => handleToggleCheck(application)} />
                    <span className="checkmark"></span>
                  </label>
                </th>
                <td onClick={() => handleDetails(application)} className="cursor-pointer pt-20 pb-20">{application?.user?.user?.first_name} {" "} {application?.user?.user?.last_name}</td>
                <td onClick={() => handleDetails(application)} className="cursor-pointer pt-20 pb-20">{application?.offer?.title}</td>
                <td onClick={() => handleDetails(application)} className="cursor-pointer pt-20 pb-20">{t(`text_${application?.status}`)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>} */}
    </div>
  );
};

export default ApplicationTable;
