import { useEffect } from "react";
import Swiper from "swiper";

const Clients = (props) => {

    const { removePadding } = props;

    useEffect(() => {
        var swiper_10_items = new Swiper(".swiper-group-9", {
            spaceBetween: 20,
            slidesPerView: 9,
            slidesPerGroup: 2,
            loop: true,
            navigation: {
                nextEl: ".swiper-button-next-group-9",
                prevEl: ".swiper-button-prev-group-9"
            },
            autoplay: {
                delay: 10000
            },
            breakpoints: {
                1360: {
                    slidesPerView: 9
                },
                1199: {
                    slidesPerView: 7
                },
                800: {
                    slidesPerView: 5
                },
                390: {
                    slidesPerView: 4
                },
                250: {
                    slidesPerView: 3,
                    slidesPerGroup: 1,
                    spaceBetween: 15
                }
            }
        });
    }, []);

    return (
<div className="container">
          <div className={removePadding ? "" : "list-brands mt-40 mb-30"}>
            <div className="box-swiper">
              <div className="swiper-container swiper-group-9 swiper">
                <div className="swiper-wrapper">
                  <div className="swiper-slide"><a href="#"><img src="assets/imgs/slider/logo/microsoft.svg" alt="Cible rh emploie" /></a></div>
                  <div className="swiper-slide"><a href="#"><img src="assets/imgs/slider/logo/sony.svg" alt="Cible rh emploie" /></a></div>
                  <div className="swiper-slide"><a href="#"><img src="assets/imgs/slider/logo/acer.svg" alt="Cible rh emploie" /></a></div>
                  <div className="swiper-slide"><a href="#"><img src="assets/imgs/slider/logo/nokia.svg" alt="Cible rh emploie" /></a></div>
                  <div className="swiper-slide"><a href="#"><img src="assets/imgs/slider/logo/assus.svg" alt="Cible rh emploie" /></a></div>
                  <div className="swiper-slide"><a href="#"><img src="assets/imgs/slider/logo/casio.svg" alt="Cible rh emploie" /></a></div>
                  <div className="swiper-slide"><a href="#"><img src="assets/imgs/slider/logo/dell.svg" alt="Cible rh emploie" /></a></div>
                  <div className="swiper-slide"><a href="#"><img src="assets/imgs/slider/logo/panasonic.svg" alt="jobBo/x" /></a></div>
                  <div className="swiper-slide"><a href="#"><img src="assets/imgs/slider/logo/vaio.svg" alt="Cible rh emploie" /></a></div>
                  <div className="swiper-slide"><a href="#"><img src="assets/imgs/slider/logo/sharp.svg" alt="Cible rh emploie" /></a></div>
                </div>
             </div>
            </div>
          </div>
        </div>
    )
}

export default Clients;