export const URLS = {
  // BASE_URL: 'http://127.0.0.1:8000',
  // BASE_URL: "https://api.cible-emploie.we-techcorp.com",
  BASE_URL: "https://api.ciblerh-emploi.com",
  CLIENT_ID: "rgFz0scCkvH2qAm4iBWwk5m8f6qC9SVM5RHzrKze",
  AUTHS: {
    LOGIN: "auth/mlogin/",
    // LOGIN: "auth/login/",
    REFRESH: "auth/token/refresh/",
    LOGOUT: "auth/logout/",
    REGISTRATION: "user/accounts/registration/",
    MANAGER_REGISTRATION: "accounts/staff/registration/",
    VERIFY_EMAIL: (key) => `user/accounts/confirm-email/${key}/`,
    VERIFY_ACCOUNT: (id) => `/users/verify/${id}/`,
    resetPassword: "accounts/password-reset/",
    resetPasswordAdmin: "accounts/password/admin-reset/",
    me: "users/me/",
    PASSWORD: {
      reset: "auth/password/reset/",
      confirm: "auth/password/reset/confirm/",
    },
    VERIFY: {
      email: "user/accounts/email/verification/",
    },
  },
  DASHBOARD: {
    admin: "/dashboard/admin/",
    adminInterval: (start, end) =>
      `/dashboard/admin/interval/?start=${start}&end=${end}`,
  },
  CONFIGS: {
    PROFILE: {
      SKILLS: {
        create: "configs/profile/skills/",
        update: (id) => `configs/profile/skills/${id}/`,
        list: (search, page) =>
          `configs/profile/skills/?search=${search}&offset=${
            ((page ?? 1) - 1) * 25
          }&page=${page}`,
      },
      PROFESSION: {
        create: "configs/profile/profession/",
        update: (id) => `configs/profile/profession/${id}/`,
        list: (search, page) =>
          `configs/profile/profession/?search=${search}&offset=${
            ((page ?? 1) - 1) * 25
          }&page=${page}`,
      },
      EMAIL_MESSAGES: {
        create: "configs/profile/email_msgs/",
        update: (id) => `configs/profile/email_msgs/${id}/`,
        list: (search, page) =>
          `configs/profile/email_msgs/?search=${search}&offset=${
            ((page ?? 1) - 1) * 25
          }&page=${page}`,
      },
      COLLEGE: {
        create: "configs/profile/college/",
        update: (id) => `configs/profile/college/${id}/`,
        list: (search) => `configs/profile/college/?search=${search}`,
      },
      COMPANY: {
        create: "configs/profile/company/",
        update: (id) => `configs/profile/company/${id}/`,
        list: (search) => `configs/profile/company/?search=${search}`,
      },
      COUNTRIES: {
        create: "configs/profile/countries/countries/",
        list: (search) =>
          `configs/profile/countries/countries/?search=${search}&limit=300`,
        CITIES: {
          create: "configs/profile/countries/cities/",
          list: (search) =>
            `configs/profile/countries/cities/?search=${search}`,
          // list: (search) => `configs/profile/countries/cities/?search=${search}`,
          list: (search) =>
            `configs/profile/villes/?search=${search}&limit=500`,
        },
      },
      SECTOR: {
        create: "configs/profile/industry/",
        update: (search) => `configs/profile/industry/${search}/`,
        list: (search) => `configs/profile/industry/?search=${search}`,
      },
      DIPLOMA: {
        create: "configs/profile/diploma/",
        list: (search, page) =>
          `configs/profile/diploma/?search=${search}&offset=${
            ((page ?? 1) - 1) * 25
          }&page=${page}`,
        update: (id) => `configs/profile/diploma/${id}/`,
      },
    },
  },
  USERS: {
    PROFILE: {
      my: "users/my_profiles/",
      create: "users/profile/",
      list: (search, page, extra, limit) =>
        `users/profile/?search=${search}&offset=${
          ((page ?? 1) - 1) * 25
        }&limit=${limit ?? 25}&page=${page}${extra ?? ""}`,
      my: (search, page) =>
        `users/my_profiles/?search=${search}&offset=${
          ((page ?? 1) - 1) * 25
        }&page=${page}`,
      update: (id) => `users/profile/${id}/`,
      details: (id) => `users/profile/${id}/`,
      EDUCATION: {
        create: "users/profile/education/",
        update: (id) => `users/profile/education/${id}/`,
        details: (id) => `users/profile/education/${id}/`,
      },
      EXPERIENCE: {
        create: "users/profile/experience/",
        update: (id) => `users/profile/experience/${id}/`,
        details: (id) => `users/profile/experience/${id}/`,
      },
    },
    update: (id) => `users/${id}/`,
    list: (search, page) =>
      `users/?search=${search}&offset=${((page ?? 1) - 1) * 25}&page=${page}`,
  },
  JOBS: {
    list: (search, page, extra) =>
      `/jobs/admin/?search=${search}&offset=${((page ?? 1) - 1) * 25}&page=${
        page ?? 1
      }${extra ?? ""}`,
    listInvalid: () => `jobs/listInvalid/`,
    applications: (slug) => `/jobs/${slug}/applications/`,
    update: (slug) => `/jobs/${slug}/`,
    details: (slug) => `/jobs/admin/${slug}/`,
    advance: (slug) => `/jobs/${slug}/advance/`,
    close: (slug) => `/jobs/${slug}/close/`,
    reopen: (slug) => `/jobs/${slug}/reopen/`,
    terminate: (slug) => `/jobs/${slug}/terminate/`,
    delete: (slug) => `/jobs/${slug}/`,
    validate: (slug) => `/jobs/${slug}/validate/`,
    create: `/jobs/`,
    search: (search, category) =>
      `/jobs/?search=${search}${category ? "&category=" + category : ""}`,
    STEPS: {
      list: (search, page) =>
        `/jobs/job_steps/?search=${search}&offset=${
          ((page ?? 1) - 1) * 25
        }&page=${page ?? 1}`,
      search: (search, category) =>
        `/jobs/job_steps/?search=${search}${
          category ? "&category=" + category : ""
        }`,
    },
    APPLICATIONS: {
      list: (search, page) =>
        `/jobs/applications/?search=${search}page=${page ?? 1}`,
      update: (id) => `/jobs/applications/${id}/`,
      advance: (id) => `/jobs/applications/${id}/advance/`,
      unarchive: (id) => `/jobs/applications/${id}/unarchive/`,
      cancel: (id) => `/jobs/applications/${id}/cancel/`,
      accept: (id) => `/jobs/applications/${id}/accept/`,
      details: (id) => `/jobs/applications/${id}/`,
      delete: (id) => `/jobs/applications/${id}/`,
      applications: (search, page, offer, extra, limit) =>
        `/jobs/applications/?search=${search}&limit=${limit ?? 25}&offset=${
          ((page ?? 1) - 1) * 25
        }&page=${page ?? 1}&offer__slug=${offer}${extra}`,
      archived_applications: (id) =>`/jobs/${id}/archived_applications/`,
    },
    RELATED_QUESTION: {
      list: (search, page) =>
        `/jobs/related_question/?search=${search}page=${page ?? 1}`,
      update: (id) => `/jobs/related_question/${id}/`,
      create: `/jobs/related_question/`,
      details: (id) => `/jobs/related_question/${id}/`,
      delete: (id) => `/jobs/related_question/${id}/`,
      applications: (search, page, offer, extra) =>
        `/jobs/applications/?search=${search}&offset=${
          ((page ?? 1) - 1) * 25
        }&page=${page ?? 1}&offer__id=${offer}${extra}`,
    },
  },
  NOTIFICATIONS: {
    messages: (search, page) =>
      `notifications/messages/?search=${search}page=${page ?? 1}`,
    details: (id) => `notifications/messages/${id}/`,
  },
  CAROUSSEL: {
    create: "caroussel-content/",
    list: (search) => `caroussel-content/?search=${search}`,
    update: (id) => `caroussel-content/${id}/`,
    delete: (id) => `caroussel-content/${id}/`,

    CARROUSEL_CONTENT: {
      list: (search) => `caroussel-content/images/?search=${search}`,
    },
  },
};
