import { useEffect, useState } from "react";
import JobCardList from "../../../../../components/jobs/JobCardList";
import { useTranslation } from "react-i18next";


const NotValidatedJobs = ({jobs, available, setAvailable}) => {
  const {t} = useTranslation();

  // useEffect(()=>{
  //   jobs.forEach(element => {
  //     if(!element.validated){
  //       setAvailable(true);
  //     }
  //   });
  // })
    return (
      <div className="section-box">
        <div className="container">
          <div className="panel-white mb-30">
            <div className="box-padding">
              <div className="row display-list">
                <div className="col-12 mt-10 mb-10">
                  <h4 className="mb-20">{t("textWaitingValidate")}</h4>
                </div>
                {jobs?.map((job) => (
                  <>{!job.validated &&(
                  <div key={job.slug} className="col-lg-6">
                    <JobCardList job={job} />
                  </div>)}</>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>)

}
export default NotValidatedJobs