import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  language: "fr"
}

export const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    changeLanguage: (state, action) => {
      state.language = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { changeLanguage } = localeSlice.actions

export default localeSlice.reducer