import { Link, useParams,useNavigate } from "react-router-dom";
import CandidateCard from "../../components/candidateCard";
import { URLS } from "../../services/urls";
import moment from "moment";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import { confirmDeletion } from "../../components/dletion";
import { useEffect, useState } from "react";
import { API } from "../../services/axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const JobDetails = () => {
  const navigate = useNavigate()
  const [closing, setClosing] = useState(false);
  const [advancing, setAdvancing] = useState(false);
  const [validating, setValidating] = useState(false);
  const { jobId } = useParams();
  const { data: job, isLoading } = useSWR(URLS.JOBS.details(jobId));
  console.log(job);
  const { t } = useTranslation();
  const [lastImage, setLastImage] = useState();
  const onSuccess = () => {
    navigate("/jobs");
  };
  const handleAdvance = () => {
    setAdvancing(true);
    API.post(URLS.JOBS.advance(jobId), {})
      .then((resp) => {
        setAdvancing(false);
        toast.success(t("textJobAdvanced"));
      })
      .catch((error) => {
        setAdvancing(false);
        toast.error(
          error?.response?.data?.detail ??
            error?.response?.data?.message ??
            error?.response?.data?.error ??
            t("textJobAdvancedError")
        );
      });
  };
  const handleClose = () => {
    setClosing(true);
    API.post(URLS.JOBS.close(jobId), job)
      .then((resp) => {
        setClosing(false);
        toast.success(t("textJobClosed"));
      })
      .catch((error) => {
        setClosing(false);
        toast.error(
          error?.response?.data?.detail ??
            error?.response?.data?.error ??
            t("textJobClosedError")
        );
      });
  };
  const handleReopen = () => {
    setClosing(true);
    API.post(URLS.JOBS.reopen(jobId), job)
      .then((resp) => {
        setClosing(false);
        toast.success(t("textJobReopened"));
      })
      .catch((error) => {
        setClosing(false);
        toast.error(
          error?.response?.data?.detail ??
            error?.response?.data?.error ??
            t("textJobReopenError")
        );
      });
  };
  const handleTerminate = () => {
    setClosing(true);
    API.post(URLS.JOBS.terminate(jobId), job)
      .then((resp) => {
        setClosing(false);
        toast.success(t("textJobTerminated"));
      })
      .catch((error) => {
        setClosing(false);
        toast.error(
          error?.response?.data?.detail ??
            error?.response?.data?.error ??
            t("textJobTerminationError")
        );
      });
  };
  const handleValidation = () => {
    setValidating(true);
    API.post(URLS.JOBS.validate(jobId),job)
    .then((resp) => {
      setValidating(false);
      toast.success(t("textJobValidationSuccess"));
      navigate("/jobs");
    }).catch((error) => {
      setValidating(false);
      toast.error(
        error?.response?.data?.detail ??
          error?.response?.data?.error ??
          t("textJobValidationError")
      )
    })
  }
  const { data: cals, isLoadingCal } = useSWR(URLS.CAROUSSEL.list());
  const { auth } = useSelector((state)=>state.auth);
  useEffect(() => {
    if (cals) {
      const userImages = cals.results.filter(
        (item) => item.image_list.step === "job"
      );
      const lastImage = userImages[userImages.length - 1];
      setLastImage(lastImage);
    }
  }, [cals]);
  // console.log(job);
  return (
    <div className="box-content">
      {" "}
      <div className="box-heading">
        {" "}
        <div className="box-title">
          {" "}
          <h3 className="mb-35">{t("textJObDetails")}</h3>{" "}
        </div>{" "}
        <div className="box-breadcrumb">
          {" "}
          <div className="breadcrumbs">
            {" "}
            <ul>
              {" "}
              <li>
                {" "}
                <Link className="icon-home" to="/">
                  {" "}
                  {t("textDashboard")}{" "}
                </Link>{" "}
              </li>{" "}
              <li>
                {" "}
                <Link to="/jobs">{t("textJobs")}</Link>{" "}
              </li>{" "}
              <li>
                {" "}
                <Link to="">{t("textJobDetails")}</Link>{" "}
              </li>{" "}
            </ul>{" "} 
          </div>{" "}
        </div>{" "}
      </div>{" "}
      <section className="section-box-2">
        {" "}
        <div className="container">
          {" "}
          {/* <div className="banner-hero banner-image-single">
            {" "}
            <img src={lastImage?.image} alt="Cible rh emploie" />{" "}
          </div>{" "} */}
          {job && (
            <div className="row mt-10 d-flex">
              {" "}
              <div className="col-lg-8 col-md-12">
                {" "}
                <h3>{job.title}</h3>{" "}
                <div className="mt-0 mb-15">
                  {" "}
                  <span className="card-briefcase">{job?.work_place}</span>{" "}
                  <span className="card-time">
                    {" "}
                    {moment(job?.created_at).fromNow()}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
              <div className="ml-auto"></div>{" "}
              <div className="col-lg-4 col-md-12">
                {!job?.validated && (
                    <h6 className="text-danger">{t("textWaitingValidationMsg")}</h6>
                )}
                </div>{" "}
            </div>
          )}{" "}
          <div className="border-bottom pt-10 pb-10"></div>{" "}
        </div>{" "}
      </section>{" "}
      {(job)&& (
        <div className="panel-white mb-30 mt-3">
          {" "}
          <div className="p-3 d-flex">
            {" "}
            {job?.validated?(<button
              disabled={advancing}
              onClick={handleAdvance}
              className="btn btn-secondary hover-up mr-2 mb-2"
            >
              {" "}
              {t("textAdvance")}{" "}
              {advancing && (
                <div
                  className="spinner-border ml-10 spinner-border-sm" role="status">
                  {" "}
                  <span className="visually-hidden">Loading...</span>{" "}
                </div>
              )}{" "}
            </button>):(
              <>
              {auth?.user?.user_level==='admin'&&(
                <button className="btn btn-secondary hover-up mr-2 mb-2" disabled={validating} onClick={handleValidation}>
                  {t("textJobValidationButton")}
                </button>
              )}
              </>
            )}{" "}
            {(job?.status === "accepted" ||
              job?.status === "canceled" ||
              job?.status === "waiting_user" ||
              job?.closed === true)? (
              <button
                disabled={closing}
                onClick={handleReopen}
                className="btn btn-secondary hover-up mr-2 mb-2"
              >
                {" "}
                {t("textReoponJob")}{" "}
                {closing && (
                  <div
                    className="spinner-border ml-10 spinner-border-sm"
                    role="status"
                  >
                    {" "}
                    <span className="visually-hidden">Loading...</span>{" "}
                  </div>
                )}{" "}
              </button>
            ):(
              <button
                disabled={closing}
                onClick={handleClose}
                className="btn btn-default hover-up mr-2 mb-2"
              >
                {" "}
                {t("textCloseApplications")}{" "}
                {closing && (
                  <div
                    className="spinner-border ml-10 spinner-border-sm"
                    role="status"
                  >
                    {" "}
                    <span className="visually-hidden">Loading...</span>{" "}
                  </div>
                )}{" "}
              </button>)}{" "}
            {job?.status === "waiting_user" && (
              <button
                disabled={closing}
                onClick={handleTerminate}
                className="btn btn-default hover-up mr-2 mb-2"
              >
                {" "}
                {t("textTerminateJob")}{" "}
                {closing && (
                  <div
                    className="spinner-border ml-10 spinner-border-sm"
                    role="status"
                  >
                    {" "}
                    <span className="visually-hidden">Loading...</span>{" "}
                  </div>
                )}{" "}
              </button>
            )}{" "}
            <div className="ml-auto"></div>{" "}
            <Link
              className="btn btn-default icon-edit hover-up mr-2 mb-2"
              to={`/jobs/${job.slug}`}
            >
              {" "}
              {t("textEditThisJob")}{" "}
            </Link>{" "}
            {job?.validated && (<Link
              className="btn btn-default hover-up mr-2 mb-2"
              to={`/jobs/${job.slug}/applications`}
            >
              {" "}
              {t("textViewApplications")}{" "}
            </Link>)}{" "}
            <Link
              className="btn btn-delete icon-delete hover-up mb-2"
              to="#"
              onClick={() =>
                confirmDeletion(URLS.JOBS.delete(jobId), t, onSuccess)
              }
            >
              {" "}
              {t("textDelete")}{" "}
            </Link>{" "}
          </div>{" "}
        </div>
      )}{" "}
      {isLoading && (
        <div className="col-12 mb-11 d-flex">
          {" "}
          <span
            className="spinner-border m-auto"
            role="status"
            aria-hidden="true"
          ></span>{" "}
        </div>
      )}{" "}
      {job && (
        <>
          {" "}
          <section className="section-box mt-50 panel-white">
            {" "}
            <div className="container">
              {" "}
              <div className="row">
                {" "}
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  {" "}
                  <div className="job-overview bg-white">
                    {" "}
                    <h5 className="border-bottom pb-15 mb-30">
                      {" "}
                      {t("textEmploymentInfo")}{" "}
                    </h5>{" "}
                    <div className="row">
                      {" "}
                      <div className="col-md-4 d-flex">
                        {" "}
                        <div className="sidebar-icon-item">
                          {" "}
                          <img
                            src="/assets/imgs/page/job-single/industry.svg"
                            alt="Cible rh emploie"
                          />{" "}
                        </div>{" "}
                        <div className="sidebar-text-info ml-10">
                          {" "}
                          <span className="text-description industry-icon mb-10">
                            {" "}
                            {t("textIndustry")}{" "}
                          </span>{" "}
                          <strong className="small-heading">
                            {" "}
                            {job?.industry?.name}{" "}
                          </strong>{" "}
                        </div>{" "}
                      </div>{" "}
                      <div className="col-md-4 d-flex">
                        {" "}
                        <div className="sidebar-icon-item">
                          {" "}
                          <img
                            src="/assets/imgs/page/job-single/industry.svg"
                            alt="Cible rh emploie"
                          />{" "}
                        </div>{" "}
                        <div className="sidebar-text-info ml-10">
                          {" "}
                          <span className="text-description industry-icon mb-10">
                            {" "}
                            {t("profession")}{" "}
                          </span>{" "}
                          <strong className="small-heading">
                            {" "}
                            {job?.profession?.name}{" "}
                          </strong>{" "}
                        </div>{" "}
                      </div>{" "}
                      <div className="col-md-4 d-flex mt-sm-15">
                        {" "}
                        <div className="sidebar-icon-item">
                          {" "}
                          <img
                            src="/assets/imgs/page/job-single/job-level.svg"
                            alt="Cible rh emploie"
                          />{" "}
                        </div>{" "}
                        <div className="sidebar-text-info ml-10">
                          {" "}
                          <span className="text-description joblevel-icon mb-10">
                            {" "}
                            {t("textJobLevel")}{" "}
                          </span>{" "}
                          <strong className="small-heading">
                            {" "}
                            {job?.experience_level}{" "}
                          </strong>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="row mt-25">
                      {" "}
                      <div className="col-md-4 d-flex mt-sm-15">
                        {" "}
                        <div className="sidebar-icon-item">
                          {" "}
                          <img
                            src="/assets/imgs/page/job-single/experience.svg"
                            alt="Cible rh emploie"
                          />{" "}
                        </div>{" "}
                        <div className="sidebar-text-info ml-10">
                          {" "}
                          <span className="text-description salary-icon mb-10">
                            {" "}
                            {t("textStatus")}{" "}
                          </span>{" "}
                          <strong className="small-heading">
                            {" "}
                            {job?.status === "waiting_user"
                              ? t("textWaitingUser")
                              : t(`text_${job?.status}`)}{" "}
                          </strong>{" "}
                        </div>{" "}
                      </div>{" "}
                      <div className="col-md-4 d-flex">
                        {" "}
                        <div className="sidebar-icon-item">
                          {" "}
                          <img
                            src="/assets/imgs/page/job-single/experience.svg"
                            alt="Cible rh emploie"
                          />{" "}
                        </div>{" "}
                        <div className="sidebar-text-info ml-10">
                          {" "}
                          <span className="text-description experience-icon mb-10">
                            {" "}
                            {t("textExperience")}{" "}
                          </span>{" "}
                          <strong className="small-heading">
                            {" "}
                            {job?.experience} {t("textYears")}{" "}
                          </strong>{" "}
                        </div>{" "}
                      </div>{" "}
                      <div className="col-md-4 d-flex mt-sm-15">
                        {" "}
                        <div className="sidebar-icon-item">
                          {" "}
                          <img
                            src="/assets/imgs/page/job-single/job-type.svg"
                            alt="Cible rh emploie"
                          />{" "}
                        </div>{" "}
                        <div className="sidebar-text-info ml-10">
                          {" "}
                          <span className="text-description jobtype-icon mb-10">
                            {" "}
                            {t("textJobType")}{" "}
                          </span>{" "}
                          <strong className="small-heading">{job?.type}</strong>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="row mt-25">
                      {" "}
                      <div className="col-md-4 d-flex mt-sm-15">
                        {" "}
                        <div className="sidebar-icon-item">
                          {" "}
                          <img
                            src="/assets/imgs/page/job-single/deadline.svg"
                            alt="Cible rh emploie"
                          />{" "}
                        </div>{" "}
                        <div className="sidebar-text-info ml-10">
                          {" "}
                          <span className="text-description mb-10">
                            {" "}
                            {t("textCreated")}{" "}
                          </span>{" "}
                          <strong className="small-heading">
                            {" "}
                            {moment(job?.created_at).format(
                              "DD/MM/yyyy, HH:MM"
                            )}{" "}
                          </strong>{" "}
                        </div>{" "}
                      </div>{" "}
                      <div className="col-md-4 d-flex mt-sm-15">
                        {" "}
                        <div className="sidebar-icon-item">
                          {" "}
                          <img
                            src="/assets/imgs/page/job-single/updated.svg"
                            alt="Cible rh emploie"
                          />{" "}
                        </div>{" "}
                        <div className="sidebar-text-info ml-10">
                          {" "}
                          <span className="text-description jobtype-icon mb-10">
                            {" "}
                            {t("textDeadline")}{" "}
                          </span>{" "}
                          <strong className="small-heading">
                            {" "}
                            {moment(job?.application_end).fromNow()}{" "}
                          </strong>{" "}
                        </div>{" "}
                        {/* <div className="sidebar-text-info ml-10"><span className="text-description jobtype-icon mb-10">{t("textDeadline")}</span><strong className="small-heading">{moment(data?.application_end).format('MMMM Do YYYY, h:mm:ss a')}</strong></div> */}{" "}
                      </div>{" "}
                      <div className="col-md-4 d-flex mt-sm-15">
                        {" "}
                        <div className="sidebar-icon-item">
                          {" "}
                          <img
                            src="/assets/imgs/page/job-single/location.svg"
                            alt="Cible rh emploie"
                          />{" "}
                        </div>{" "}
                        <div className="sidebar-text-info ml-10">
                          {" "}
                          <span className="text-description mb-10">
                            {" "}
                            {t("textLocation")}{" "}
                          </span>{" "}
                          <strong className="small-heading">
                            {" "}
                            {job?.city?.name}, {job?.country?.name}{" "}
                          </strong>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                    {/* <div className="row mt-25"> </div> */}{" "}
                    <div className="row mt-25">
                      {" "}
                      <div className="col-md-4 d-flex mt-sm-15">
                        {" "}
                        <div className="sidebar-icon-item">
                          {" "}
                          <img
                            src="/assets/imgs/page/job-single/job-level.svg"
                            alt="Cible rh emploie"
                          />{" "}
                        </div>{" "}
                        <div className="sidebar-text-info ml-10">
                          {" "}
                          <span className="text-description mb-10">
                            {" "}
                            {t("textNberPlaces")}{" "}
                          </span>{" "}
                          <strong className="small-heading">
                            {" "}
                            {job?.required}
                          </strong>{" "}
                        </div>{" "}
                      </div>{" "}
                      <div className="col-md-4 d-flex mt-sm-15">
                        {" "}
                        <div className="sidebar-icon-item">
                          {" "}
                          <img
                            src="/assets/imgs/page/job-single/job-level.svg"
                            alt="Cible rh emploie"
                          />{" "}
                        </div>{" "}
                        <div className="sidebar-text-info ml-10">
                          {" "}
                          <span className="text-description mb-10">
                            {" "}
                            {t("textNberAppl")}{" "}
                          </span>{" "}
                          <strong className="small-heading">
                            {" "}
                            {job?.submitted}{" "}
                          </strong>{" "}
                        </div>{" "}
                      </div>{" "}
                      <div className="col-md-4 d-flex mt-sm-15">
                        {" "}
                          <div className="sidebar-icon-item">
                            {" "}
                            <img
                              src="/assets/imgs/page/job-single/job-level.svg"
                              alt="Cible rh emploie"
                            />{" "}
                          </div>
                        {" "}
                        <div className="sidebar-text-info ml-10">
                          {" "}
                          <span className="text-description mb-10">
                            {" "}
                            {t("textRequestedDiploma")}{" "}
                          </span>{" "}
                          <strong className="small-heading">
                            {" "}
                            {job?.diploma?.name}{" "}
                          </strong>{" "}
                        </div>{" "}
                      </div>
                    </div>{" "}
                  </div>{" "}
                  <div className="content-single p-16">
                    {" "}
                    <h4>{t("textDescription")}</h4>{" "}
                    <div
                      dangerouslySetInnerHTML={{ __html: job?.description }}
                    />{" "}
                    <h4 className="mt-30">{t("tetSkills")}</h4>{" "}
                    <div>
                      {" "}
                      {job?.skills?.map((skill) => (
                        <Link
                          key={skill?.id}
                          className="btn btn-tag mr-5 mb-2"
                          to="#"
                        >
                          {" "}
                          {skill?.name}{" "}
                        </Link>
                      ))}{" "}
                    </div>{" "}
                    <h4>{t("textResponsabilities")}</h4>{" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: job?.responsabilities,
                      }}
                    />{" "}
                  </div>{" "}
                  {/* <div className="author-single"> <span>Cible RH</span> </div> */}{" "}
                  <div className="single-apply-jobs px-16">
                    {" "}
                    <div className="row align-items-center">
                      {" "}
                      <div className="col-md-5">
                        {" "}
                        {/* <Link className="btn btn-border" href="#">Save job</Link> */}{" "}
                      </div>{" "}
                      <div className="col-md-7 text-lg-end social-share">
                        {" "}
                        <h6 className="color-text-paragraph-2 d-inline-block d-baseline mr-10">
                          {" "}
                          {t("textShareJob")}{" "}
                        </h6>{" "}
                        <Link
                          className="mr-5 d-inline-block d-middle"
                          to={`https://www.facebook.com/sharer.php?u=${window.location.href}`}
                        >
                          {" "}
                          <img
                            alt="Cible rh emploie"
                            src="/assets/imgs/template/icons/share-fb.svg"
                          />{" "}
                        </Link>{" "}
                        <Link
                          className="mr-5 d-inline-block d-middle"
                          to={`https://twitter.com/intent/tweet?url=${window.location.href}`}
                        >
                          {" "}
                          <img
                            alt="Cible rh emploie"
                            src="/assets/imgs/template/icons/share-tw.svg"
                          />{" "}
                        </Link>{" "}
                        <Link
                          className="d-inline-block d-middle"
                          to={`whatsapp://send?text=${window.location.href}`}
                        >
                          {" "}
                          <img
                            alt="Cible rh emploie"
                            src="/assets/imgs/template/icons/share-whatsapp.svg"
                          />{" "}
                        </Link>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
                <div className="col-lg-4 col-md-12 col-sm-12 col-12 pl-40 pl-lg-15 mt-lg-30"></div>{" "}
              </div>{" "}
            </div>{" "}
          </section>{" "}
          <section className="section-box mt-50 mb-50">
            {" "}
            <div className="container">
              {" "}
              {/* <div className="text-left"> <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp"> {t("textCandidatures")} </h2> <p className="font-lg color-text-paragraph-2 wow animate__animated animate__fadeInUp"> {t("textApplicationsReceived")} </p> </div> */}{" "}
              {/* <div className="mt-50"> <div className="row"> { job?.applications?.map((application) => ( <div key={application.id} className="col-xl-4 col-lg-4 col-md-6"> <CandidateCard profile={application} /> </div> )) } </div> </div> */}{" "}
            </div>{" "}
          </section>{" "}
        </>
      )}{" "}
    </div>
  );
};
export default JobDetails;
