import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import NotFound from "../pages/notfound/NotFound";
import Login from "../pages/login";
import Register from "../pages/register";
import JobDetails from "../pages/jobDetails";
import UserDashboardLayout from "../components/Layouts/UserDashboard";
import UserDashboard from "../pages/userDashboard/pages";
import MyApplicationDetails from "../pages/userDashboard/pages/myApplications/details";
import MyProfile from "../pages/userDashboard/pages/myProfile";
import MyResumes from "../pages/userDashboard/pages/myResumes";
import ManageResume from "../pages/userDashboard/pages/myResumes/manageResume";
import JobsList from "../pages/userDashboard/pages/jobs";
import ManageJobOffer from "../pages/userDashboard/pages/jobs/manage";
import Candidates from "../pages/userDashboard/pages/myApplications";
import UserCVDetails from "../pages/userDashboard/pages/myApplications/details";
import Configurations from "../pages/userDashboard/pages/configs";
import ConfigList from "../pages/userDashboard/pages/configs/ConfigList";
import RecoverPassword from "../pages/recoverPassword/RecoverPassword";
import ConfirmRecover from "../pages/recoverPassword/Confirm";


const AppRoutes = () => {

    return (
        <Router>
            <Routes>
                <Route path="login" element={<Login/>} />
                <Route path="register" element={<Register/>} />
                <Route path="password-reset" element={<RecoverPassword/>} />
                <Route path="password-reset/confirm/:uuid/:token" element={<ConfirmRecover/>} />
                <Route element={<UserDashboardLayout/>}>
                    <Route index path="/" element={<UserDashboard />} />
                    <Route path="cv-theque" element={<Candidates/>} />
                    <Route path="cv-theque/:cvId/details" element={<UserCVDetails/>} />
                    <Route path="jobs" element={<JobsList/>} />
                    <Route path="jobs/:jobId/details" element={<JobDetails/>} />
                    <Route path="jobs/:jobId/applications" element={<Candidates/>} />
                    {/* <Route path="jobs/:jobId/applications/:cvId/details" element={<UserCVDetails/>} /> */}
                    <Route path="jobs/:jobId/applications/:applicationId/cv/:cvId/details" element={<UserCVDetails/>} />
                    <Route path="jobs/:action" element={<ManageJobOffer/>} />
                    <Route path="candidate/:applicationId/details" element={<MyApplicationDetails/>} />
                    <Route path="profile" element={<MyProfile/>} />
                    <Route path="my-resumes" element={<MyResumes/>} />
                    <Route path="my-resumes/manage/:action" element={<ManageResume/>} />
                    <Route path="configurations" element={<Configurations/>} />
                    <Route path="configurations/:config" element={<ConfigList/>} />
                </Route>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </Router>
    )
}

export default AppRoutes;

//glpat-rJzcbzWSBmRUCfPUysfh