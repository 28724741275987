import { Link, useNavigate, useParams } from "react-router-dom";
import AuthFooter from "../../../components/footer";
import { useEffect, useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useTranslation } from "react-i18next";
import { styles } from "../../../../../components/utils/reactSelect";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useForm } from "react-hook-form";
import { URLS } from "../../../../../services/urls";
import { API } from "../../../../../services/axios";
import { useSelector } from "react-redux";
import useSWR from "swr";
import { toast } from "react-toastify";
import ManageExtraData from "./components/ManageExtraData";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const countryFetcher = (url) => API.get(url).then(res=>{
  let resp = [];
  res?.data?.results.forEach((element)=>{
    if(element.continent==="AF" && element.phone==="237"){
      resp.push(element);
    }
  })
  return resp;
})

const cityFetcher = (url) => API.get(url).then(res=>{
  let resp = []
  res?.data?.results.forEach((element)=>{
    if(element.name==="Douala" || element.name==="Yaoundé" || element.name==="Bafoussam" || element.name==="Garoua" || 
    element.name==="Maroua"|| element.name==="Bamenda" || element.name==="Buea" || element.name==="Bertoua" || element.name==="Kribi"){
      resp.push(element)
    }
  })
  return resp;
})
// We have to retrieve the variable :action (create or edit) in url params
const ManageJobOffer = () => {
  const { t } = useTranslation();
  const { auth } = useSelector((state) => state.auth);
  const { action } = useParams();

  const [loading, setLoading] = useState(false);
  const [loadingJob, setLoadingJb] = useState(false);
  const [toEdit, setToEdit] = useState({});
  const [description, setDescription] = useState("");
  const [responsabilities, setResponsabilities] = useState("");
  const [file, setFile] = useState();
  const [previewLink, setPReviewLink] = useState("");
  const [industry, setIndustry] = useState();
  const [searchIndustry, setSearchIndustry] = useState("");
  const [creatingIndustry, setCreatingIndustry] = useState(false);
  const [recruiter, setRecruiter] = useState();
  const [searchRecruiter, setSearchRecruiter] = useState("");
  const [creatingRecruiter, setCreatingRecruiter] = useState();
  const [skills, setSkills] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [searchSkills, setSearchSkills] = useState("");
  const [creatingSkills, setCreatingSkills] = useState(false);
  const [country, setCountry] = useState();
  const [searchCountries, setSearchCountries] = useState("");
  const [city, setCity] = useState();
  const [searchCities, setSearchCities] = useState("");
  const [partners, setPartners] = useState([]);
  const [searchPartners, setSeachPartners] = useState(false);
  const [profession, setProfession] = useState("");
  const [diploma, setDiploma] = useState("");
  const [searchDiploma, setSearchDiploma] = useState(false);
  const [searchProfession, setSearchProfession] = useState(false);
  const [jobValidated, setJobValidated] = useState(false);
  // Load data we will use
  const { data: industries, isLoading: loadingIndustries } = useSWR(
    URLS.CONFIGS.PROFILE.SECTOR.list(searchIndustry)
  );
  const { data: recuiters, isLoading: loadingRecuiters } = useSWR(
    URLS.CONFIGS.PROFILE.COMPANY.list(searchRecruiter)
  );
  const { data: skillss, isLoading: loadingSkills } = useSWR(
    URLS.CONFIGS.PROFILE.SKILLS.list(searchSkills)
  );
  const { data: countries, isLoading: loadingCountries } = useSWR(
    URLS.CONFIGS.PROFILE.COUNTRIES.list(searchCountries), countryFetcher
  );
  const { data: cities, isLoading: loadingCities } = useSWR(
    URLS.CONFIGS.PROFILE.COUNTRIES.CITIES.list(searchCities), cityFetcher
  );
  const { data: steps, isLoading: loadingSteps } = useSWR(
    URLS.JOBS.STEPS.list("")
  );
  const { data: users, isLoading: loadingPartners } = useSWR(
    URLS.USERS.list("", 1)
  );
  const { data: professions, isLoading: loadingProfessions } = useSWR(
    URLS.CONFIGS.PROFILE.PROFESSION.list("", 1)
  );
  const {data: diplomas, isLoading: loadingDiplomas } = useSWR(
    URLS.CONFIGS.PROFILE.DIPLOMA.list("")
  );

  const [jobId, setJobId] = useState("");

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({});

  const handleChangeImage = (event) => {
    const uploaded = event.target.files[0];
    setFile(uploaded);
    if (uploaded) {
      setPReviewLink(URL.createObjectURL(uploaded));
    }
  };

  const handleAddQuestion = (question) => {
    setQuestions((prev) => [...prev, question]);
  };

  const handleEditQuestion = (question) => {
    setQuestions((prev) =>
      prev.map((i) => {
        if (i.id === question.id) {
          i = question;
        }
        return i;
      })
    );
  };

  const handleRemoveQuestion = (question) => {
    setQuestions((prev) => prev.filter((i) => i.id !== question.id));
  };

  const onSubmit = (data) => {
    // console.log(data)
    setLoading(true);
    console.log(country);
    let info = {
      status: "sent",
      title: data.title,
      // type: data.contractType,
      description: description,
      responsabilities: responsabilities,
      experience: data.experianceYears,
      salary_min: data.minSalary ?? 0,
      salary_max: data.maxSalary ?? 0,
      required: data.required,
      visa: data.visa,
      validated: jobValidated,
      application_start: data.applicationStart,
      application_end: data.applicationEnd,
      contract_type: data.contractType,
      experience_level: data.experienceLevel,
      note: data.note,
      active: true,
      steps: data.jobProcess,
      industry: industry.value,
      client: recruiter?.value,
      profession: profession.value,
      created_by: auth?.user?.id,
      diploma: diploma.value,
      skills: skills?.map((i) => i.value),
      // "country": [parseInt(country.value)],
      // "city": [parseInt(city?.value)]
      country: parseInt(country.value),
      city: parseInt(city?.value),
    };
    console.log(info);
    if (action === "create") {
      API.post(URLS.JOBS.create, info)
        .then((resp) => {
          const { data } = resp;
          if (file) {
            let formData = new FormData();
            formData.append("image", file);
            API.patch(URLS.JOBS.update(data.id), formData)
              .then((resp) => {})
              .catch((error) => {});
          }
          // Now let's create related form entries
          questions.forEach((question) => {
            // If it's newly created
            if (question.is_new) {
              question.id = undefined;
              question.offer = data.id;

              API.post(URLS.JOBS.RELATED_QUESTION.create, question)
                .then((resp) => {})
                .catch((error) => {});
            } else if (!question.is_new && question.is_edited) {
              // Or edit
              question.id = undefined;
              API.patch(
                URLS.JOBS.RELATED_QUESTION.update(question.id),
                question
              )
                .then((resp) => {})
                .catch((error) => {});
            }
          });
          setLoading(false);
          toast.success(t("textJobCreated"));
          navigate("/jobs");
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            t("textJobCreationFailed") ?? error?.response?.data?.detail
          );
          setLoading(false);
        });
    } else {
      // info["active"] = data.active;
      // info["status"] = data.status;
      API.patch(URLS.JOBS.update(action), info)
        .then((resp) => {
          const { data } = resp;
          if (file) {
            let formData = new FormData();
            formData.append("image", file);
            API.patch(URLS.JOBS.update(data.id), formData)
              .then((resp) => {})
              .catch((error) => {});
          }
          // Now let's create related form entries
          questions.forEach((question) => {
            // If it's newly created
            if (question.is_new) {
              question.id = undefined;
              question.offer = data.id;

              API.post(URLS.JOBS.RELATED_QUESTION.create, question)
                .then((resp) => {})
                .catch((error) => {});
            } else if (!question.is_new && question.is_edited) {
              // Or edit
              question.id = undefined;
              API.patch(
                URLS.JOBS.RELATED_QUESTION.update(question.id),
                question
              )
                .then((resp) => {})
                .catch((error) => {});
            }
          });
          setLoading(false);
          toast.success(t("textJobEdited"));
          navigate("/jobs");
        })
        .catch((error) => {
          toast.error(
            t("textJobEditedFailed") ?? error?.response?.data?.detail
          );
          setLoading(false);
        });
    }
  };

  const handleCreateIndustry = (inputValue) => {
    setCreatingIndustry(true);
    const data = {
      name: inputValue,
      created_by: auth?.user?.id,
    };
    API.post(URLS.CONFIGS.PROFILE.SECTOR.create, data)
      .then((resp) => {
        const { data } = resp;
        setIndustry({ label: data.name, value: data.id });
        setCreatingIndustry(false);
      })
      .catch((error) => {
        setCreatingIndustry(false);
      });
  };

  const handleCreateCompany = (inputValue) => {
    setCreatingRecruiter(true);
    const data = {
      name: inputValue,
      created_by: auth?.user?.id,
    };
    API.post(URLS.CONFIGS.PROFILE.COMPANY.create, data)
      .then((resp) => {
        const { data } = resp;
        setRecruiter({ label: data.name, value: data.id });
        setCreatingRecruiter(false);
      })
      .catch((error) => {
        setCreatingRecruiter(false);
      });
  };

  const handleCreateSkill = (inputValue) => {
    setCreatingSkills(true);
    const data = {
      name: inputValue,
      created_by: auth?.user?.id,
    };
    API.post(URLS.CONFIGS.PROFILE.SKILLS.create, data)
      .then((resp) => {
        const { data } = resp;
        setSkills((prev) => [...prev, { label: data.name, value: data.id }]);
        setCreatingSkills(false);
      })
      .catch((error) => {
        setCreatingSkills(false);
      });
  };

  useEffect(() => {
    if (action !== "create") {
      setLoadingJb(true);
      API.get(URLS.JOBS.details(action))
        .then((resp) => {
          const { data } = resp;
          setToEdit(data);
          setLoadingJb(false);
          // Reset the form
          reset({
            title: data.title,
            // status: data.status,
            contractType: data.contract_type,
            description: data.description,
            responsabilities: data.responsabilities,
            experianceYears: data.experience,
            minSalary: data.salary_min ?? 0,
            maxSalary: data.salary_max ?? 0,
            required: data.required,
            expvisaerianceYears: data.visa,
            applicationStart: data.application_start,
            applicationEnd: data.application_end,
            workPlace: data.place,
            experienceLevel: data.experience_level,
            note: data.note,
            // active: data.active,
            industry: data.industry,
            client: data.client,
            profession: data.profession,
            // jobProcess: data.steps.id,
          });
          setPReviewLink(data.image);
          setSkills(
            data.skills?.map((skill) => {
              return { label: skill.name, value: skill.id };
            })
          );
          setJobId(data?.id);
          setIndustry({
            label: data?.industry?.name,
            value: data?.industry?.id,
          });
          setRecruiter({ label: data?.client?.name, value: data?.client?.id });
          setCountry({ label: data?.country.name, value: data?.country.id });
          setCity({ label: data?.city.name, value: data?.city.id });
          setDiploma({ label: data?.diploma.name, value: data?.diploma.id})
          setProfession({
            label: data?.profession.name,
            value: data?.profession.id,
          });
          setDescription(data?.description);
          setResponsabilities(data?.responsabilities);
          setQuestions(data.questions);
          setJobValidated(data?.validated)
          // "skills": skills?.map(i => i.value),
          // "country": [parseInt(country.value)],
          // "city": [parseInt(city?.value)]
        })
        .catch((error) => {
          setLoadingJb(false);
        });
    }
  }, []);
  return (
    <div className="box-content">
      <div className="box-heading">
        <div className="box-title">
          <h3 className="mb-35">
            {action === "create" ? t("postAJob") : t("textEditAJob")}
          </h3>
        </div>
        <div className="box-breadcrumb">
          <div className="breadcrumbs">
            <ul>
              <li>
                {" "}
                <Link className="icon-home" href="index.html">
                  {t("textAdmin")}
                </Link>
              </li>
              <li>
                <span>
                  {action === "create" ? t("postAJob") : t("textEditAJob")}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="section-box">
            <div className="container">
              {loadingJob && (
                <div className="col-12 mb-10 d-flex">
                  <span
                    className="spinner-border m-auto"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              )}
              {
                <div className="panel-white mb-30">
                  <div className="box-padding bg-postjob">
                    <h5 className="icon-edu">{t("jobInformations")}</h5>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row mt-30">
                        <div className="col-lg-9">
                          <div className="row">
                            <div className="col-lg-6 col-12">
                              <div className="form-group mb-30">
                                <label className="font-sm mb-10">
                                  {t("jobTitle")}{" "}
                                  <span className="text-primary">*</span>
                                </label>
                                <input
                                  {...register("title", { required: true })}
                                  className="form-control"
                                  type="text"
                                  placeholder="e.g. Senior Product Designer"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-30">
                                <label className="font-sm mb-10">
                                  {t("contractType")}{" "}
                                  <span className="text-primary">*</span>
                                </label>
                                <select
                                  className="form-control"
                                  {...register("contractType", {
                                    required: true,
                                  })}
                                >
                                  <option value="" disabled>
                                    {t("textChoose")}
                                  </option>
                                  <option value="enternship">
                                    {t("enterShip")}
                                  </option>
                                  <option value="interim">
                                    {t("interim")}
                                  </option>
                                  <option value="cdd">{t("cdd")}</option>
                                  <option value="cdi">{t("cdi")}</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-6 col-12">
                              <div className="form-group mb-30">
                                <label className="font-sm mb-10">
                                  {t("jobExperienceYear")}
                                  <span className="text-primary">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  {...register("experianceYears", {
                                    required: true,
                                  })}
                                  type="number"
                                  min={0}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-30">
                                <label className="font-sm mb-10">
                                  {t("jobExperienceLevel")}
                                  <span className="text-primary">*</span>
                                </label>
                                <select
                                  className="form-control"
                                  {...register("experienceLevel", {
                                    required: true,
                                  })}
                                >
                                  <option value="" disabled>
                                    {t("textChoose")}
                                  </option>
                                  <option value="entry">
                                    {t("jobExpEntry")}
                                  </option>
                                  <option value="intermediate">
                                    {t("jobExpInterm")}
                                  </option>
                                  <option value="senior">
                                    {t("jobExpSenior")}
                                  </option>
                                  <option value="expert">
                                    {t("jobExpExpert")}
                                  </option>
                                </select>
                              </div>
                            </div>
                            {/* <div className="col-lg-6 col-12">
                            <div className="form-group mb-30">
                              <label className="font-sm mb-10">
                                {t("jobMinSalary")}<span className="text-primary">*</span>
                              </label>
                              <input className="form-control"  {...register("minSalary", {required: true})} type="number" min={0} />
                            </div>
                          </div> */}
                            <div className="col-lg-6 col-12">
                              <div className="form-group mb-30">
                                <label className="font-sm mb-10">
                                  {t("textAvailablePosts")}{" "}
                                  <span className="text-primary">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  {...register("required", { required: true })}
                                  type="number"
                                  min={1}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-30">
                                <label className="font-sm mb-10">
                                  {t("jobWorkPlace")}
                                  <span className="text-primary">*</span>
                                </label>
                                <select
                                  className="form-control"
                                  {...register("workPlace", { required: true })}
                                >
                                  <option value="" disabled>
                                    {t("textChoose")}
                                  </option>
                                  <option value="onsite">
                                    {t("jobWorkPlaceOnsite")}
                                  </option>
                                  <option value="remote">
                                    {t("jobWorkPlaceRemote")}
                                  </option>
                                  <option value="hybrid">
                                    {t("jobWorkPlaceHybrid")}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-6 col-12">
                              <div className="form-group mb-10">
                                <label className="font-sm mb-10">
                                  {t("jobApplicationStarts")}{" "}
                                  <span className="text-primary">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="date"
                                  {...register("applicationStart", {
                                    required: true,
                                  })}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-12">
                              <div className="form-group mb-30">
                                <label className="font-sm mb-10">
                                  {t("jobApplicationEnd")}{" "}
                                  <span className="text-primary">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="date"
                                  {...register("applicationEnd", {
                                    required: true,
                                  })}
                                />
                              </div>
                              {/* <div className="login_footer form-group d-flex justify-content-between">
                                <label className="cb-container">
                                  <input
                                    type="checkbox"
                                    {...register("visa")}
                                  />
                                  <span className="text-small">
                                    {t("jobVisaSponsored")}
                                  </span>
                                  <span className="checkmark"></span>
                                </label>
                              </div> */}
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-30">
                                <label className="font-sm mb-10">
                                  {t("jobSector")}
                                  <span className="text-primary">*</span>
                                </label>
                                <div>
                                  <CreatableSelect
                                    id="job-industry"
                                    styles={styles}
                                    inputId="select-industry"
                                    isSearchable
                                    value={industry}
                                    onInputChange={(value) =>
                                      setSearchIndustry(value)
                                    }
                                    isLoading={
                                      creatingIndustry || loadingIndustries
                                    }
                                    onChange={(newValue) =>
                                      setIndustry(newValue)
                                    }
                                    onCreateOption={handleCreateIndustry}
                                    options={
                                      industries?.results?.map((i) => {
                                        return { label: i.name, value: i.id };
                                      }) ?? []
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-30">
                                <label className="font-sm mb-10">
                                  {t("jobCompany")}
                                  {/* <span className="text-primary">*</span> */}
                                </label>
                                <div>
                                  <CreatableSelect
                                    id="job-company"
                                    styles={styles}
                                    inputId="select-company"
                                    isSearchable
                                    value={recruiter}
                                    onInputChange={(value) =>
                                      setSearchRecruiter(value)
                                    }
                                    isLoading={
                                      creatingRecruiter || loadingRecuiters
                                    }
                                    onChange={(newValue) =>
                                      setRecruiter(newValue)
                                    }
                                    onCreateOption={handleCreateCompany}
                                    options={
                                      recuiters?.results?.map((i) => {
                                        return { label: i.name, value: i.id };
                                      }) ?? []
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-30">
                                <label className="font-sm mb-10">
                                  {t("jobSkills")}
                                  <span className="text-primary">*</span>
                                </label>
                                <div>
                                  <CreatableSelect
                                    id="job-skills"
                                    styles={styles}
                                    inputId="select-skills"
                                    isSearchable
                                    value={skills}
                                    isMulti
                                    onInputChange={(value) =>
                                      setSearchSkills(value)
                                    }
                                    isLoading={creatingSkills || loadingSkills}
                                    onChange={(newValue) => setSkills(newValue)}
                                    onCreateOption={handleCreateSkill}
                                    options={
                                      skillss?.results?.map((i) => {
                                        return { label: i.name, value: i.id };
                                      }) ?? []
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-30">
                                <label className="font-sm mb-10">
                                  {t("jobCountry")}
                                  <span className="text-primary">*</span>
                                </label>
                                <div>
                                  <Select
                                    id="job-country"
                                    inputId="select-country"
                                    styles={styles}
                                    isSearchable
                                    value={country}
                                    onInputChange={(value) =>
                                      setSearchCountries(value)
                                    }
                                    isLoading={loadingCountries}
                                    onChange={(newValue) =>
                                      setCountry(newValue)
                                    }
                                    // onCreateOption={handleCreateIndustry}
                                    options={
                                      countries?.map((i) => {
                                        return {
                                          label: i.name,
                                          value: String(i.url).split("/")[
                                            String(i.url).split("/").length - 2
                                          ],
                                        };
                                      }) ?? []
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-30">
                                <label className="font-sm mb-10">
                                  {t("jobCity")}{" "}
                                  <span className="text-primary">*</span>
                                </label>
                                <div>
                                  <Select
                                    id="job-city"
                                    inputId="select-city"
                                    styles={styles}
                                    value={city}
                                    isSearchable
                                    onInputChange={(value) =>
                                      setSearchCities(value)
                                    }
                                    isLoading={loadingCities}
                                    onChange={(newValue) => setCity(newValue)}
                                    // onCreateOption={handleCreateIndustry}
                                    options={
                                      cities?.map((i) => {
                                        return { label: i.name, value: i.id };
                                        // return { label: i.display_name, value: String(i.url).split("/")[String(i.url).split("/").length-2] };
                                      }) ?? []
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-30">
                                <label className="font-sm mb-10">
                                  {t("textRequestedDiploma")} {" "}
                                  <span className="text-primary">*</span>
                                </label>
                                <div>
                                  <Select
                                    id="diplomas"
                                    inputId="diploma"
                                    styles={styles}
                                    isSearchable
                                    // isMulti
                                    value={diploma}
                                    onInputChange={(value) =>
                                      setSearchDiploma(value)
                                    }
                                    isLoading={loadingDiplomas}
                                    onChange={(newValue) =>
                                      setDiploma(newValue)
                                    }
                                    // onCreateOption={handleCreateIndustry}
                                    options={
                                      diplomas?.results?.map((i) => {
                                        return { label: i.name, value: i.id };
                                      }) ?? []
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-lg-6 col-md-6">
                            <div className="form-group mb-30">
                              <label className="font-sm mb-10">
                                {t("jobProcess")}<span className="text-primary">*</span>
                              </label>
                              <select className="form-control" {...register("jobProcess", {required: true})}>
                              <option value="" disabled>
                                  {t("textChoose")}
                                </option>
                                {
                                  steps?.results?.map((step) => {
                                    let text = ""
                                    Object.keys(step).forEach(function(key, index) {
                                      if(step[key] && key !== "id"){
                                        text = index === 1 ? step[key] : text + " --> " + step[key]
                                      }
                                    });
                                    return <option key={step.id} value={step.id}>{text}</option>
                                  })
                                }
                              </select>
                            </div>
                          </div> */}
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-30">
                                <label className="font-sm mb-10">
                                  {t("jobIncludePertners")}
                                </label>
                                <div>
                                  <Select
                                    id="include-partners"
                                    inputId="select-partners"
                                    styles={styles}
                                    isSearchable
                                    isMulti
                                    value={partners}
                                    onInputChange={(value) =>
                                      setSeachPartners(value)
                                    }
                                    isLoading={loadingPartners}
                                    onChange={(newValue) =>
                                      setPartners(newValue)
                                    }
                                    // onCreateOption={handleCreateIndustry}
                                    options={
                                      users?.results?.map((i) => {
                                        return {
                                          label: i.first_name,
                                          value: i.id,
                                        };
                                      }) ?? []
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-30">
                                <label className="font-sm mb-10">
                                  {t("profession")}
                                </label>
                                <div>
                                  <Select
                                    id="professions"
                                    inputId="profession"
                                    styles={styles}
                                    isSearchable
                                    // isMulti
                                    value={profession}
                                    onInputChange={(value) =>
                                      setSearchProfession(value)
                                    }
                                    isLoading={loadingPartners}
                                    onChange={(newValue) =>
                                      setProfession(newValue)
                                    }
                                    // onCreateOption={handleCreateIndustry}
                                    options={
                                      professions?.results?.map((i) => {
                                        return { label: i.name, value: i.id };
                                      }) ?? []
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            
                            {toEdit && (
                              <div className="col-lg-6 col-md-6">
                                {/* <div className="form-group mb-30">
                              <label className="font-sm mb-10">
                                {t("textStatus")}<span className="text-primary">*</span>
                              </label>
                              <select className="form-control"  {...register("status", {required: true})}>
                              <option value="" disabled>
                                  {t("textChoose")}
                                </option>
                                <option value="sent">
                                  {t("text_sent")}
                                </option>
                                <option value="in_progress">
                                  {t("text_in_progress")}
                                </option>
                                <option value="interview">
                                  {t("text_interview")}
                                </option>
                                <option value="test">
                                  {t("text_test")}
                                </option>
                                <option value="human_ressources">
                                  {t("text_human_ressources")}
                                </option>
                                <option value="rejected">
                                  {t("text_rejected")}
                                </option>
                                <option value="validated">
                                  {t("text_validated")}
                                </option>
                                <option value="canceled">
                                  {t("text_canceled")}
                                </option>
                              </select>
                            </div> */}
                                {/* <div className="login_footer form-group d-flex justify-content-between">
                              <label className="cb-container">
                                <input type="checkbox"  {...register("active")}/>
                                <span className="text-small">
                                  {t("texIsActive")}
                                </span>
                                <span className="checkmark"></span>
                              </label>
                            </div> */}
                              </div>
                            )}
                            {/* <div className="row"> */}
                            
                            {/* </div> */}
                            {/* <div className="col-lg-6 col-md-6">
                            {file && <div className="form-group mb-30 box-file-uploaded d-flex align-items-center">
                              <span>{file?.name}</span>
                              {/* <Link className="btn btn-delete">Delete</Link> *
                            </div>}
                          </div> */}
                            <div className="col-lg-12">
                              <div className="form-group mb-30">
                                <label className="font-sm mb-10">
                                  {t("jobSpecialNote")}
                                </label>
                                <textarea
                                  {...register("note")}
                                  className="form-control"
                                  rows={5}
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group mb-30">
                                <label className="font-sm mb-10">
                                  {t("jobDescription")}{" "}
                                  <span className="text-primary">*</span>
                                </label>
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={description}
                                  onReady={(editor) => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log(
                                      "Editor is ready to use!",
                                      editor
                                    );
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setDescription(data);
                                  }}
                                  onBlur={(event, editor) => {
                                    console.log("Blur.", editor);
                                  }}
                                  onFocus={(event, editor) => {
                                    console.log("Focus.", editor);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group mb-30">
                                <label className="font-sm mb-10">
                                  {t("jobResponsabilities")}{" "}
                                  <span className="text-primary">*</span>
                                </label>
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={responsabilities}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setResponsabilities(data);
                                  }}
                                  onBlur={(event, editor) => {
                                    console.log("Blur.", editor);
                                  }}
                                  onFocus={(event, editor) => {
                                    console.log("Focus.", editor);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group mb-30">
                                <div className="box-upload">
                                  <div
                                    className={`add-file-upload ${
                                      previewLink ? "job" : ""
                                    }`}
                                    style={
                                      previewLink
                                        ? {
                                            backgroundImage: `url(${previewLink})`,
                                          }
                                        : {}
                                    }
                                  >
                                    <input
                                      className="fileupload"
                                      type="file"
                                      name="file"
                                      id="file"
                                      accept="image/*"
                                      onChange={handleChangeImage}
                                    />
                                  </div>
                                  <label
                                    htmlFor="file"
                                    className="btn btn-default"
                                  >
                                    {t("textUploadImage")}
                                  </label>
                                  <label className="mt-5 font-3 text-mutted text-center" style={{fontSize:10}}>
                                    {t("textImageFormat")} (PNG, JPEG, JPG), {t("textImageSize")} 10Mo
                                  </label>
                                </div>
                              </div>
                            </div>
                            <ManageExtraData
                              questions={questions}
                              handleAdd={handleAddQuestion}
                              handleEdit={handleEditQuestion}
                              handleRemove={handleRemoveQuestion}
                            />
                            <div className="col-lg-12">
                              <div className="form-group mt-10">
                                <button
                                  disabled={loading}
                                  className="btn btn-default btn-brand icon-tick"
                                >
                                  {action === "create"
                                    ? t("textNewJob")
                                    : t("textEditJob")}{" "}
                                  {loading && (
                                    <span
                                      className="spinner-border spinner-border-sm ml-10"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <AuthFooter />
    </div>
  );
};

export default ManageJobOffer;
