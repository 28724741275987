import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import CompaniesList from "./components/companies/list";
import IndustriesList from "./components/industries/list";
import CollegeList from "./components/college/list";
import SkillsList from "./components/skills/list";
import DiplomsList from "./components/diploms/list";
import MessagesList from "./components/messages/list";
import ProfessionList from "./components/professions/list";

const ConfigList = () => {
  const { config } = useParams();
  const { t } = useTranslation();

  return (
    <div className="box-content">
      <div className="box-heading">
        <div className="box-title">
          <h3 className="mb-35">{t("txtConfig")}</h3>
        </div>
        <div className="box-breadcrumb">
          <div className="breadcrumbs">
            <ul>
              <li>
                {" "}
                <Link className="icon-home" to="/configurations">
                  {t("txtConfig")}
                </Link>
              </li>
              <li>
                <span>{t("text" + config)}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {config === "industry" && <IndustriesList />}
      {config === "companies" && <CompaniesList />}
      {config === "college" && <CollegeList />}
      {config === "skills" && <SkillsList />}
      {config === "diploms" && <DiplomsList />}
      {config === "messages" && <MessagesList />}
      {config === "professions" && <ProfessionList />}
    </div>
  );
};

export default ConfigList;
